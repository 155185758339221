const cookieNamePrefix = 'stage_';

export const env = {
	production: false,
	stagging: true,
	development: false,
	apiUrl: `https://tradalaxy-back.brights.io/api/v1/`,
	apiMarketplaceUrl: `https://tradalaxy-back.brights.io/api/v1/marketplace/`,
	marketplaceUrl: 'tradalaxy-marketplace.brights.io',
	marketplaceSubDomain: 'tradalaxy-marketplace',
	tradalaxyUrl: 'https://tradalaxy-platform.brights.io',
	helpCenterUrl: 'https://tradalaxy-help-center.brights.io',
	blogUrl: 'https://tradalaxy-blog.brights.io',
	landingUrl: 'https://tradalaxy-landing.brights.io',
	mainCookiesDomain: 'stage.tradalaxy.com',
	cookieTokenName: cookieNamePrefix + 'token',
	cookieLanguagesName: cookieNamePrefix + 'languages',
	cookieMainLangName:  cookieNamePrefix + 'main_lang',
};

import 'zone.js/dist/zone-error';  // Included with Angular CLI.