import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// UI Modules
import { UiKitSliderComponent } from "./uikit-slider.component";

import {
	HammerGestureConfig,
	HAMMER_GESTURE_CONFIG,
  } from '@angular/platform-browser';

  declare var Hammer: any;

  export class MyHammerConfig extends HammerGestureConfig  {
	buildHammer(element: HTMLElement) {
	  let mc = new Hammer(element, {
		touchAction: "pan-y"
	  });
	  return mc;
	}
  }

@NgModule({
	imports: [
		CommonModule
	],
    declarations: [
		UiKitSliderComponent
	],
	exports: [
		UiKitSliderComponent
	],
	providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig
        }
    ],
})

export class UiKitSliderModule { }