import { ActivatedRoute } from '@angular/router';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppService } from './app-service.service';
import { environment } from '@env/environment';


@Injectable({
    providedIn: 'root'
})
export class DomainHelperService {

    private fragment: BehaviorSubject<string>;
    private currentRouteSubscription: Subscription;

    constructor(
		private currentRoute: ActivatedRoute,
		private appService: AppService ) {

        this.fragment = new BehaviorSubject(null);
        this.currentRouteSubscription = this.currentRoute.fragment.subscribe((fragment: string) => {
            this.fragment.next(fragment);
		});

        let bs = window['bs_env'];
        if ( bs && bs.HOST ) {
			this.currentRouteSubscription.unsubscribe();
			if( bs.HOST.split('.')[0] === environment.marketplaceSubDomain ) {
				this.appService.getCompanyPreviewDomain().subscribe(res => {
					if (res) {
						this.fragment.next(res)
					}
				})
			}
			else {
				this.fragment.next( bs.HOST.split('.')[0] );
			}
        }
    }

    public getDomain(): BehaviorSubject<string> {
        return this.fragment;
    }
}