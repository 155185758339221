import { Action, Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getCurrencySymbol, CurrencySymbolItem } from '@store/common/common.state';
import { combineLatest } from 'rxjs';
import { saveDataLocalStorage, getDataLocalStorage } from "@app/helper/common";
import {
	ProductActive,
	getProductCurrentFeatures,
	getProductActiveData,
	ProductCompany,
	getProductCompanyData,
	getProductActiveQuantity
} from '@app/store/product/product.state';
import { getOrderData } from './fetch-order.state';


// helper
interface CurrentBulkPriceOption {
	quantity: number,
	data: {
		show_bulk_pricing: boolean,
		bulk_prices: Array<{
			price: number,
			quantity: number
		}>,
		price: number
	}
};

interface NextBulkPriceOption {
	quantity: number,
	show_bulk_pricing: boolean,
	bulk_prices: Array<{
		price: number,
		quantity: number
	}>
};

interface NextBulkPrice {
	price: number,
	quantity: number,
	isFirst: boolean
};

export interface OrderSubtotal {
	currency: string,
	priceTotal: number,
	oldPrice: number,
	productCount: number,
	hasOldPrice: boolean
}

export function getCurrentBulkPrice(option: CurrentBulkPriceOption): {currentPrice: number, oldPrice: number} {
	let {data, quantity} = {...option};
	let result = {
		currentPrice: Number(quantity) * Number(data.price),
		oldPrice: null
	}
	if (data.show_bulk_pricing && data.bulk_prices.length) {
		data.bulk_prices.filter(z => {
			return Number(quantity) >= Number(z.quantity);
		})
		.sort((a, b) => {
			return Number(a.quantity) > Number(b.quantity) ? -1 : 1;
		})
		.some(z => {
			result.currentPrice = Number(quantity) * Number(z.price),
			result.oldPrice = Number(quantity) * data.price
			return true;
		})
	}
	return result;
};

export function getNextBulkPrice(option: NextBulkPriceOption): NextBulkPrice | null {
	let {quantity, show_bulk_pricing, bulk_prices} = {...option};
	let result = null;
	if (show_bulk_pricing && bulk_prices.length) {
		bulk_prices.sort((a, b) => {
			return Number(a.quantity) > Number(b.quantity) ? 1 : -1;
		}).some((z, i) => {
			let isCorrect = Number(quantity) < Number(z.quantity);
			if (isCorrect) {
				result = {
					price: z.price,
					quantity: z.quantity,
					isFirst: i === 0
				};
				return true;
			}
			return false;
		});
	}
	return result;
};

export function geOrderSubtotal(data: OrderListData): OrderSubtotal | null {
	if (!data) {
		return {
			currency: '',
			priceTotal: 0,
			oldPrice: 0,
			productCount: 0,
			hasOldPrice: false
		}
	}
	let currency = data.currency.code;
	let totalPrice = 0;
	let oldPrice = 0;
	let hasOldPrice = false;
	data.products.forEach(z => {
		totalPrice += z.current_bulk_price.currentPrice;
		oldPrice += (z.current_bulk_price.oldPrice !== null) ? z.current_bulk_price.oldPrice : z.current_bulk_price.currentPrice;
		if (!hasOldPrice) {
			hasOldPrice = z.current_bulk_price.oldPrice !== null;
		}
	});
	return {
		currency: currency,
		priceTotal: totalPrice,
		oldPrice: oldPrice,
		productCount: data.products.length,
		hasOldPrice: hasOldPrice
	};
};


// Interface
export interface OrderProductItem {
	product_id: number,
	variation_id: number,
	quantity: number
}

export interface GuestOrder {
	user: {
		email: string,
		first_name: string,
		last_name: string,
		phone: string,
		time_zone: string
	},
	company: {
		name: string,
		country_id: number,
		business_type_id: number,
		industries: Array<number>
	},
	seller_company_id: number,
	delivery_type_id: number,
	incoterm_id: number,
	delivery_address: string,
	payment_type_id: number,
	prepayment_amount: number,
	prepayment_type: 'currency' | 'percent',
	payment_other: string,
	warehouse: {
		country_id: number,
		city_id: number,
		terminal_id: number,
		port_id: number
	},
	items: Array<OrderProductItem>
};

export interface UserOrder {
	seller_company_id: number,
	buyer_company_id: number,
	buyer_user_id: number,
	delivery_type_id: number,
	incoterm_id: number,
	warehouse_id: number,
	delivery_address: string,
	payment_type_id: number,
	prepayment_amount: number,
	prepayment_type: 'currency' | 'percent',
	payment_other: string,
  	items: Array<OrderProductItem>
};

export interface OrderCurrency {
	code: string,
	symbol: string
};

export interface OrderCompany {
	id: number,
	name: string,
	img: string,
	location: string,
	time_zone: string
};

export interface OrderListOptionProduct {
	id: number,
	product_id?: number,
	name: string,
	image: string,
	quantity_in_one_package: number,
	available_quantity: number,
	minimum_order: number,
	max_quantity: number,
	min_quantity: number,
	unit: string,
	slug: string,
	category: string,
	features: Array<{
		attribute_name: string,
		attribute_option_name: string
	}>
};

export interface OrderListDataProduct {
	id: number,
	product_id: number,
	variation_id: number,
	quantity: number,
	available_quantity: number,
	price: number,
	show_bulk_pricing: boolean,
	current_bulk_price: {
		currentPrice: number,
		oldPrice: number
	},
	bulk_prices: Array<{
		price: number,
		quantity: number
	}>
};

export interface OrderListOption {
	order_company: OrderCompany,
	order_currency: OrderCurrency,
	order_products: Array<OrderListOptionProduct>
};

export interface OrderListData {
	company_id: number,
	currency: OrderCurrency,
	products: Array<OrderListDataProduct>
};

export interface ActualOrderProductData {
	id: number,
	name: string,
	image: string,
	available_quantity: number,
	quantity_in_one_package: number,
	minimum_order: number,
	max_quantity: number,
	min_quantity: number,
	unit: string,
	price: number,
	show_bulk_pricing: boolean,
	slug: string,
	bulk_prices: Array<{
		price: number,
		quantity: number
	}>,
	features: Array<{
		attribute_name: string,
		attribute_option_name: string
	}>
};

export interface ConfirmOrder {
	order_id: number,
	chat_id: number,
	order_buyer: {
		id: number
	},
	order_data: ConfirmOrderData
};

export interface ConfirmOrderData {
	order_company: OrderCompany,
	order_currency: OrderCurrency,
	order_products: Array<{
		id: number,
		name: string,
		image: string,
		unit: string,
		quantity: number,
		quantity_in_one_package: number,
		currentPrice: number,
		features: Array<{
			attribute_name: string,
			attribute_option_name: string
		}>
	}>
};


// State
export interface OrderState {
	confirmOrder: ConfirmOrder,
	activeOrder: {
		company_id: number,
		order_currency: OrderCurrency
	},
	orderListData: Array<OrderListData>,
	orderListOption: Array<OrderListOption>,
	orderModalVisible: boolean
};

const INITIAL_STATE: OrderState = {
	confirmOrder: getDataLocalStorage('confirmOrder', 'object') || {
		order_id: null,
		chat_id: null,
		order_buyer: undefined,
		order_data: undefined
	},
	activeOrder: getDataLocalStorage('activeOrder', 'object') || null,
	orderListData: getDataLocalStorage('orderListData', 'object') || [],
	orderListOption: getDataLocalStorage('orderListOption', 'object') || [],
	orderModalVisible: false
};


// Action
export const OrderActions = {
	setActiveOrder: '[Order] Set_Active_Order',
	clearActiveOrder: '[Order] Clear_Active_Order',

	addOrderListItem: '[Order] Add_Order_List_Item',
	updateOrderListItem: '[Order] Update_Order_List_Item',
	deleteOrderListItem: '[Order] Delete_Order_List_Item',
	toggleModalVisible: '[Order] Toggle_Modal_Visible',

	confirmGuestOrder: '[Order] Confirm_Guest_Order',
	confirmUserOrder: '[Order] Confirm_User_Order',
	confirmOrderSuccess: '[Order] Confirm_Order_Success',
	confirmOrderError: '[Order] Confirm_Order_Error',

	actualOrderProduct: '[Order] Actual_Order_Product',
	actualOrderProductSuccess: '[Order] Actual_Order_Product_Success',
	actualOrderProductError: '[Order] Actual_Order_Product_Error'
};

export class SetActiveOrder implements Action {
	readonly type = OrderActions.setActiveOrder;
	constructor(public payload: {company_id: number, order_currency: OrderCurrency}) { }
};

export class ClearActiveOrder implements Action {
	readonly type = OrderActions.clearActiveOrder;
	constructor() { }
};

export class AddOrderListItem implements Action {
	readonly type = OrderActions.addOrderListItem;
	public productActive: ProductActive;
	public productQuantity: number;
	public productFeatures: Array<{
		attribute_name: string,
		attribute_option_name: string
	}>;
	public productCompany: ProductCompany;
	public currencySymbol: CurrencySymbolItem;
	constructor(private store: Store<AppStore.AppStoreState>) {
		combineLatest(
			this.store.select(getProductActiveData),
			this.store.select(getProductActiveQuantity),
			this.store.select(getProductCurrentFeatures),
			this.store.select(getProductCompanyData),
			this.store.select(getCurrencySymbol),
		).subscribe(val => {
			this.productActive = val[0];
			this.productQuantity = val[1];
			this.productFeatures = val[2];
			this.productCompany = val[3];
			this.currencySymbol = val[4].find(z => z.code === this.productActive.currency);
		}).unsubscribe();
	}
};

export class UpdateOrderListItem implements Action {
	readonly type = OrderActions.updateOrderListItem;
	constructor(public payload: {company_id: number, product_id: number, product_quantity: number}) { }
};

export class DeleteOrderListItem implements Action {
	readonly type = OrderActions.deleteOrderListItem;
	constructor(public payload: {company_id: number, product_id: number, currency: OrderCurrency}) { }
};

export class ToggleModalVisible implements Action {
	readonly type = OrderActions.toggleModalVisible;
	constructor(public payload: boolean) { }
};

export class ConfirmGuestOrder implements Action {
	readonly type = OrderActions.confirmGuestOrder;
	constructor(public payload: GuestOrder) { }
};

export class ConfirmUserOrder implements Action {
	readonly type = OrderActions.confirmUserOrder;
	constructor(public payload: UserOrder) { }
};

export class ConfirmOrderSuccess implements Action {
	readonly type = OrderActions.confirmOrderSuccess;
	constructor(public payload: {order_id: number, chat_id?: number}) { }
};

export class ConfirmOrderError implements Action {
	readonly type = OrderActions.confirmOrderError;
	constructor(public payload: any) { }
};

export class ActualOrderProduct implements Action {
	readonly type = OrderActions.actualOrderProduct;
	constructor(public payload: {items: Array<{product_id: number, variation_id: number}>}) { }
};

export class ActualOrderProductSuccess implements Action {
	readonly type = OrderActions.actualOrderProductSuccess;
	constructor(public payload: Array<ActualOrderProductData>) { }
};

export class ActualOrderProductError implements Action {
	readonly type = OrderActions.actualOrderProductError;
	constructor(public payload: any) { }
};

// Reducer
export function OrderReducer(state = INITIAL_STATE, action: Action) {
	switch (action.type) {
		case OrderActions.setActiveOrder: {
			let payload = (action as SetActiveOrder).payload;
			let {company_id, order_currency} = {...payload};
			let activeOrder = {
				...state.activeOrder,
				company_id: company_id,
				order_currency: order_currency
			};
			saveDataLocalStorage('activeOrder', activeOrder);
			return {
				...state,
				activeOrder: activeOrder
			};
		}; break;
		case OrderActions.clearActiveOrder: {
			if (state.activeOrder) {
				let companyId = state.activeOrder.company_id;
				let orderCurrency = state.activeOrder.order_currency;

				let orderListOption = state.orderListOption.filter(z => !(z.order_company.id === companyId && z.order_currency.code === orderCurrency.code))
				let orderListData = state.orderListData.filter(z => !(z.company_id === companyId && z.currency.code === orderCurrency.code));

				saveDataLocalStorage('activeOrder', null);
				saveDataLocalStorage('orderListData', orderListData);
				saveDataLocalStorage('orderListOption', orderListOption);
				return {
					...state,
					activeOrder: null,
					orderListOption: orderListOption,
					orderListData: orderListData
				};
			}
			else {
				return {
					...state
				}
			}
		}; break;

		case OrderActions.addOrderListItem: {
			let payload = (action as AddOrderListItem);
			let {productActive, productQuantity, productFeatures, productCompany, currencySymbol} = {...payload};

			// valid payload
			if (!productActive || !productQuantity || !productFeatures || !productCompany || !currencySymbol) {
				return {
					...state
				}
			}

			// valid product id
			let hasProduct = state.orderListData.some(z => {
				return z.products.some(x => x.id === productActive.id);
			});
			if (hasProduct) {
				return {
					...state
				}
			}

			// order option
			let companyOffice = (productCompany.office_address.length) ? productCompany.office_address[0] : null;
			let companyLocation = (companyOffice) ? `${(companyOffice.country) ? `${companyOffice.country.name}` : ``}${(companyOffice.city) ? `, ${companyOffice.city.name}` : ``}` : null;
			let orderItemImage = productActive.images.find(z => z.is_main);
			let productCategory = `${productActive.high_level_categories[0] ? `${productActive.high_level_categories[0].name}/` : '' }${productActive.categories[productActive.categories.length - 1] ? productActive.categories[productActive.categories.length - 1].full_name : ''}`;
			let order_company = {
				id: productCompany.id,
				name: productCompany.name,
				img: productCompany.logo,
				location: companyLocation,
				time_zone: productCompany.time_zone
			};
			let order_currency = {
				code: currencySymbol.code,
				symbol: currencySymbol.symbol
			};
			let order_product = {
				id: productActive.id,
				name: productActive.name,
				image: (orderItemImage) ? orderItemImage.url : '',
				quantity_in_one_package: productActive.quantity_in_one_package,
				available_quantity: productActive.available_quantity,
				minimum_order: productActive.minimum_order,
				max_quantity: productActive.max_quantity,
				min_quantity: productActive.min_quantity,
				unit: productActive.unit,
				features: productFeatures,
				slug: productActive.slug,
				category: productCategory
			};

			// order data
			let product = {
				id: productActive.id,
				product_id: productActive.product_id,
				variation_id: productActive.id,
				quantity: productQuantity,
				available_quantity: productActive.available_quantity,
				price: productActive.price,
				show_bulk_pricing: productActive.show_bulk_pricing,
				current_bulk_price: getCurrentBulkPrice({
					quantity: productQuantity,
					data: {
						show_bulk_pricing: productActive.show_bulk_pricing,
						bulk_prices: productActive.bulk_prices,
						price: productActive.price
					}
				}),
				bulk_prices: productActive.bulk_prices
			};

			// check has company has order list
			let orderListIndex = state.orderListOption.findIndex(z => {
				return z.order_company.id === order_company.id && z.order_currency.code === order_currency.code;
			});

			if (orderListIndex === -1) {
				let orderListOption = [
					...state.orderListOption,
					{
						order_company: order_company,
						order_currency: order_currency,
						order_products: [order_product]
					}
				];
				let orderListData = [
					...state.orderListData,
					{
						company_id: productCompany.id,
						currency: order_currency,
						products: [product]
					}
				];

				saveDataLocalStorage('orderListData', orderListData);
				saveDataLocalStorage('orderListOption', orderListOption);

				return {
					...state,
					orderListOption: orderListOption,
					orderListData: orderListData
				}
			}
			else {
				let orderListOption = state.orderListOption.map(z => {
					if (z.order_company.id === order_company.id && z.order_currency.code === order_currency.code) {
						return {
							...z,
							order_products: [
								...z.order_products,
								order_product
							]
						}
					}
					else {
						return z;
					}
				});

				let orderListData = state.orderListData.map((z, i) => {
					if (z.company_id === order_company.id && z.currency.code === order_currency.code) {
						return {
							...z,
							products: [
								...z.products,
								product
							]
						}
					}
					else {
						return z;
					}
				});

				saveDataLocalStorage('orderListData', orderListData);
				saveDataLocalStorage('orderListOption', orderListOption);

				return {
					...state,
					orderListOption: orderListOption,
					orderListData: orderListData
				}
			}
		}; break;
		case OrderActions.updateOrderListItem: {
			let payload = (action as UpdateOrderListItem).payload;
			let {company_id, product_id, product_quantity} = {...payload};

			let orderListData = state.orderListData.map(z => {
				if (z.company_id === company_id) {
					return {
						...z,
						products: z.products.map(x => {
							if (x.id === product_id) {
								return {
									...x,
									quantity: product_quantity,
									current_bulk_price: getCurrentBulkPrice({
										quantity: product_quantity,
										data: {
											show_bulk_pricing: x.show_bulk_pricing,
											bulk_prices: x.bulk_prices,
											price: x.price
										}
									})
								}
							}
							else {
								return x;
							}
						})
					}
				}
				else {
					return z;
				}
			});

			saveDataLocalStorage('orderListData', orderListData);

			return {
				...state,
				orderListData: orderListData
			}
		}; break;
		case OrderActions.deleteOrderListItem: {
			let payload = (action as DeleteOrderListItem).payload;
			let {company_id, product_id, currency} = {...payload};

			let orderListOption = state.orderListOption.map(z => {
				if (z.order_company.id === company_id) {
					return {
						...z,
						order_products: z.order_products.filter(x => x.id !== product_id)
					}
				}
				else {
					return z;
				}
			}).filter(z => z.order_products.length);

			let orderListData = state.orderListData.map(z => {
				if (z.company_id === company_id) {
					return {
						...z,
						products: z.products.filter(x => x.id !== product_id)
					}
				}
				else {
					return z;
				}
			}).filter(z => z.products.length);

			saveDataLocalStorage('orderListData', orderListData);
			saveDataLocalStorage('orderListOption', orderListOption);

			// check active order item
			let activeOrder = state.activeOrder;
			if (activeOrder) {
				let activeOrderHasProduct = orderListData.some(z => {
					if (z.company_id === activeOrder.company_id && z.currency.code === activeOrder.order_currency.code) {
						return true;
					}
					return false;
				});
				if (!activeOrderHasProduct) {
					saveDataLocalStorage('activeOrder', null);
					return {
						...state,
						orderListOption: orderListOption,
						orderListData: orderListData,
						activeOrder: null
					};
				}
			}
			return {
				...state,
				orderListOption: orderListOption,
				orderListData: orderListData
			};
		}; break;

		case OrderActions.toggleModalVisible: {
			let payload = (action as ToggleModalVisible).payload;
			return {
				...state,
				orderModalVisible: payload
			};
		}; break;

		case OrderActions.confirmUserOrder: {
			let payload = (action as ConfirmUserOrder).payload;
			return {
				...state,
				confirmOrder: {
					...state.confirmOrder,
					order_buyer: {
						id: payload.buyer_company_id
					}
				}
			};
		}; break;
		case OrderActions.confirmOrderSuccess: {
			let payload = (action as ConfirmOrderSuccess).payload;

			let activeOrderData = state.orderListData.find(z => z.company_id === state.activeOrder.company_id && z.currency.code === state.activeOrder.order_currency.code);
			let activeOrderOption = state.orderListOption.find(z => z.order_company.id === state.activeOrder.company_id && z.order_currency.code === state.activeOrder.order_currency.code);

			let order_products = activeOrderOption.order_products.map(z => {
				let activeOrderDataProduct = activeOrderData.products.find(x => x.id === z.id);
				return {
					id: z.id,
					name: z.name,
					image: z.image,
					unit: z.unit,
					quantity: activeOrderDataProduct.quantity,
					quantity_in_one_package: z.quantity_in_one_package,
					currentPrice: activeOrderDataProduct.current_bulk_price.currentPrice,
					features: z.features
				}
			});

			let confirmOrder = {
				...state.confirmOrder,
				chat_id: payload.chat_id || null,
				order_id: payload.order_id,
				order_data: {
					order_company: activeOrderOption.order_company,
					order_currency: activeOrderOption.order_currency,
					order_products: order_products
				}
			};

			saveDataLocalStorage('confirmOrder', confirmOrder);

			return {
				...state,
				confirmOrder: confirmOrder
			};
		}; break;

		case OrderActions.actualOrderProductSuccess: {
			let payload = (action as ActualOrderProductSuccess).payload;

			let orderListData = state.orderListData.map(z => {
				if (z.company_id === state.activeOrder.company_id && z.currency.code === state.activeOrder.order_currency.code) {

					let products = z.products.map(x => {
						let currentProduct = payload.find(c => c.id === x.id);
						if (currentProduct) {
							let quantity = (x.quantity < currentProduct.min_quantity) ? currentProduct.min_quantity : (x.quantity > currentProduct.max_quantity) ? currentProduct.max_quantity : x.quantity;
							return {
								...x,
								available_quantity: currentProduct.available_quantity,
								price: currentProduct.price,
								show_bulk_pricing: currentProduct.show_bulk_pricing,
								quantity: quantity,
								current_bulk_price: getCurrentBulkPrice({
									quantity: quantity,
									data: {
										show_bulk_pricing: currentProduct.show_bulk_pricing,
										bulk_prices: currentProduct.bulk_prices,
										price: currentProduct.price
									}
								}),
								bulk_prices: currentProduct.bulk_prices
							};
						}
						else {
							return null;
						}
					}).filter(x => x);
					return {
						...z,
						products: products
					};
				}
				else {
					return z;
				}
			}).filter(z => z.products.length);

			let orderListOption = state.orderListOption.map(z => {
				if (z.order_company.id === state.activeOrder.company_id && z.order_currency.code === state.activeOrder.order_currency.code) {
					let order_products = z.order_products.map(x => {
						let currentProduct = payload.find(c => c.id === x.id);
						if (currentProduct) {
							return {
								...x,
								slug: currentProduct.slug,
								name: currentProduct.name,
								image: currentProduct.image,
								quantity_in_one_package: currentProduct.quantity_in_one_package,
								available_quantity: currentProduct.available_quantity,
								minimum_order: currentProduct.minimum_order,
								max_quantity: currentProduct.max_quantity,
								min_quantity: currentProduct.min_quantity,
								unit: currentProduct.unit,
								features: currentProduct.features
							};
						}
						else {
							return null;
						}
					}).filter(x => x);
					return {
						...z,
						order_products: order_products
					};
				}
				else {
					return z;
				}
			}).filter(z => z.order_products.length);

			saveDataLocalStorage('orderListData', orderListData);
			saveDataLocalStorage('orderListOption', orderListOption);

			return {
				...state,
				orderListData: orderListData,
				orderListOption: orderListOption
			};
		}; break;

		default: {
			return state;
		}
	}
};


// Getter
export const getOrderState = createFeatureSelector<OrderState>('orderState');

export const getActiveOrderListOption = createSelector(
	getOrderState,
	(state: OrderState) => {
		let orderListOption = [];
		if (state.activeOrder) {
			state.orderListOption.some(z => {
				if (z.order_company.id === state.activeOrder.company_id && z.order_currency.code === state.activeOrder.order_currency.code) {
					orderListOption.push(z);
					return true;
				}
				return false;
			})
		}
		return orderListOption;
	}
);

export const getActiveOrderListDataItem = createSelector(
	getOrderState,
	(state: OrderState) => {
		let item: OrderListData;
		if (state.activeOrder) {
			state.orderListData.some(z => {
				if (z.company_id === state.activeOrder.company_id && z.currency.code === state.activeOrder.order_currency.code) {
					item = z;
					return true;
				}
				return false;
			})
		}
		return item;
	}
);

export const getOrderListOption = createSelector(
	getOrderState,
	(state: OrderState) => state.orderListOption
);

export const getOrderListDataItem = (company_id: number, order_currency: string) => createSelector(
	getOrderState,
	(state: OrderState) => {
		let item: OrderListData;
		state.orderListData.some(z => {
			if (z.company_id === company_id && z.currency.code === order_currency) {
				item = z;
				return true;
			}
			return false;
		})
		return item;
	}
);

export const getOrderListDataItemProduct = (company_id: number, product_id: number) => createSelector(
	getOrderState,
	(state: OrderState) => {
		let itemProduct: OrderListDataProduct;
		state.orderListData.some(z => {
			if (z.company_id === company_id) {

				return z.products.some(x => {
					if (x.id === product_id) {
						itemProduct = x;
						return true;
					}
					return false;
				})
			}
			return false;
		});

		return itemProduct;
	}
);

export const getOrderListCount = createSelector(
	getOrderState,
	(state: OrderState) => {
		let count = 0;
		state.orderListData.forEach(z => {
			count += z.products.length;
		});
		return count;
	}
);

export const getOrderModalVisible = createSelector(
	getOrderState,
	(state: OrderState) => state.orderModalVisible
);

export const getConfirmOrderId = createSelector(
	getOrderState,
	(state: OrderState) => state.confirmOrder.order_id
);

export const getConfirmOrderChatId = createSelector(
	getOrderState,
	(state: OrderState) => state.confirmOrder.chat_id
);

export const getConfirmOrderBuyer = createSelector(
	getOrderState,
	(state: OrderState) => state.confirmOrder.order_buyer
);

export const getConfirmOrderData = createSelector(
	getOrderState,
	(state: OrderState) => state.confirmOrder.order_data
);