import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, ContentChild,  TemplateRef, OnInit, AfterViewInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { BehaviorSubject } from 'rxjs';
import { BannerItem } from "@app/helper/model";

const BASE_CAROUSEL_CONFIG: NguCarouselConfig = {
	grid: {
		xs: 1,
		sm: 1,
		md: 1,
		lg: 1,
		all: 0
	},
	speed: 300,
	interval: {
		timing: 3000
	},
	slide: 1,
	touch: false,
	loop: false,
	animation: 'lazy'
};


@Component({
	selector: 'promo-banner-slider',
	templateUrl: './promo-banner-slider.component.html',
	styleUrls: ['./promo-banner-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class PromoBannerSliderComponent implements OnInit, AfterViewInit {

	// props
	@Input() public sliderList: Array<BannerItem>;
	@Input() public carouselConfig: NguCarouselConfig;

	// base config
	@ContentChild('sliderItemTmpl', {static: false}) private _sliderItemTmpl: TemplateRef<any>;
	private _carouselInputs$: BehaviorSubject<NguCarouselConfig>;

	constructor(private _changeDetectorRef: ChangeDetectorRef) {
		// init state
		this._carouselInputs$ = new BehaviorSubject(BASE_CAROUSEL_CONFIG);
		this.sliderList = [];
	}

	ngOnInit(): void {
		if (this.carouselConfig !== undefined) {
			try {
				let carouselInputs: NguCarouselConfig = {
					...BASE_CAROUSEL_CONFIG,
					...this.carouselConfig
				}
				this._carouselInputs$.next(carouselInputs);
			}
			catch(error) {
				console.warn("Warning --> PromoBannerSliderComponent --> ngOnInit", error);
			}
		}
	}

	ngAfterViewInit(): void {
		try {
			this._changeDetectorRef.detectChanges();
		}
		catch(error) {
			console.warn("Warning --> PromoBannerSliderComponent --> ngAfterViewInit", error)
		}
	}
}