import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NguCarouselModule } from "@ngu/carousel";
import { RouterModule } from '@angular/router';

// custom modules
import { UiKitModule } from "@sub/tradalaxy_uikit/src/app/uikit/ui-kit.module";
import { SharedModule } from "@app/shared/shared";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsComponentModule } from "../shared/breadcrumbs/breadcrumbs.module";

// component
import { ProductPageComponent } from "./product-page.component";
import { ProductWidgetComponent } from "./product-widget/product-widget.component"
import { LogisticTabComponent } from "./logistic-tab/logistic-tab.component";
import { CompanyTabComponent } from "./company-tab/company-tab.component";
import { ProductTabComponent } from "./product-tub/product-tab.component";
import { ProductFormComponent } from "./product-tub/product-form/product-form.component";
import { ProductSliderComponent } from "./product-tub/product-slider/product-slider.component";

// pipe
import { PipeModule } from "@app/pipe/pipe.module";

@NgModule({
	declarations: [
		LogisticTabComponent,
		CompanyTabComponent,
		ProductWidgetComponent,
		ProductPageComponent,
		ProductTabComponent,
		ProductFormComponent,
		ProductSliderComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NguCarouselModule,
		ReactiveFormsModule,
		RouterModule,
		UiKitModule,
		SharedModule,
		BreadcrumbsComponentModule,
		PipeModule
	],
	providers: [
	]
})

export class ProductPageModule { }