import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { orderValidator } from "@app/helper/validator";
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { UserData, getUserData } from '@app/store/user/user.state';
import {
	CompanyWarehouse,
	getCompanyWarehouseData,
	CheckoutEntities,
	getCheckoutIncoterms,
	getCheckoutEntities,
	PutWarehouse,
	PutWarehouseData,
	CompanyWarehouseItem,
	CheckoutActions
} from "@store/checkout/checkout.state";
import {
	getActiveOrderListDataItem,
	ConfirmUserOrder,
	OrderProductItem,
	UserOrder,
	OrderSubtotal
} from "@store/order/order.state";
import { SelectItem } from "@app/helper/model";
import { getPrePaymentList, wrapperScrollTop } from "@app/helper/common";


@Component({
	selector: 'authorization-order',
	templateUrl: './authorization-order.component.html',
	styleUrls: ['./authorization-order.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AuthorizationOrderComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() public orderSubtotal: Observable<OrderSubtotal>;

	private pageSubscription: Subscription;

	public preloader$: BehaviorSubject<boolean>;
	public isReady$: BehaviorSubject<boolean>;
	public stateWarehousePopup$: BehaviorSubject<boolean>;
	public addWarehouseVisible$: Observable<boolean>;
	public paymentTypeOther$: BehaviorSubject<boolean>;
	public paymentTypePartial$: BehaviorSubject<boolean>;

	public userData$: Observable<UserData>;
	public companyWarehouse$: Observable<Array<CompanyWarehouseItem>>;
	public companyList$: BehaviorSubject<Array<SelectItem<{id: number}>>>;
	public incotermsList$: BehaviorSubject<Array<SelectItem<{id: number}>>>;
	public deliveryTypesList$: BehaviorSubject<Array<SelectItem<{id: number}>>>;
	public paymentTypesList$: BehaviorSubject<Array<SelectItem<{id: number, slug: string}>>>;
	public prePaymentList$: BehaviorSubject<Array<SelectItem<{id: number, key: string}>>>;

	public orderForm: FormGroup;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private changeDetectorRef: ChangeDetectorRef,
		private actionsSubject: ActionsSubject) {

		// init state
		this.pageSubscription = new Subscription();
		this.preloader$ = new BehaviorSubject(false);
		this.isReady$ = new BehaviorSubject(false);
		this.stateWarehousePopup$ = new BehaviorSubject(false);
		this.paymentTypeOther$ = new BehaviorSubject(false);
		this.paymentTypePartial$ = new BehaviorSubject(false);

		this.userData$ = this.store.select(getUserData);
		this.companyWarehouse$ = this.store.select(getCompanyWarehouseData);
		this.companyList$ = new BehaviorSubject([]);
		this.incotermsList$ = new BehaviorSubject([]);
		this.deliveryTypesList$ = new BehaviorSubject([]);
		this.paymentTypesList$ = new BehaviorSubject([]);
		this.prePaymentList$ = new BehaviorSubject([]);

		this.addWarehouseVisible$ = this.companyWarehouse$.pipe(map(list => {
			if (!list || list.length < 5) {
				return true;
			}
			else {
				return false;
			}
		}));

		this.initOrderForm();
		this.addSubscription();
	}

	getFormField(controlName: string): AbstractControl {
		return this.orderForm.controls[controlName];
	}

	detectChanges(): void {
		try {
			this.changeDetectorRef.detectChanges();
		}
		catch(error) {
			console.warn('DetectChanges Error', error);
		}
	}

	initOrderForm(): void {
		this.orderForm = new FormGroup({
			company: new FormControl(''),
			first_name: new FormControl(''),
			last_name: new FormControl(''),
			delivery_address: new FormControl('', orderValidator.deliveryAddress),
			delivery_type: new FormControl('', Validators.required),
			incoterm: new FormControl(null, Validators.required),
			warehouse: new FormControl(null, Validators.required),
			payment_type: new FormControl(null, Validators.required),
			payment_other: new FormControl(''),
			prepayment_amount: new FormControl(null),
			prepayment_select: new FormControl(null)
		}, (form: FormGroup) => {
			if (form.controls.prepayment_amount.valid && form.controls.prepayment_select.valid) {
				if (!this.paymentTypeOther$.getValue()) {
					let prepaymentAmount = form.controls.prepayment_amount.value !== null ? form.controls.prepayment_amount.value : null;
					let prepaymentSelect = form.controls.prepayment_select.value !== null ? form.controls.prepayment_select.value.id : null;
					if ((prepaymentAmount === null || prepaymentSelect === null) || (prepaymentSelect === 2 && prepaymentAmount > 100)) {
						return { amountError: 'validations.prepayment_amount_error' };
					}
				}
				return null;
			}
			return null;
		});

		this.pageSubscription.add(
			this.getFormField('company').valueChanges.subscribe(val => {
				if (val) {
					if (this.isReady$.getValue()) {
						this.preloader$.next(true);
					}
					this.getFormField('warehouse').setValue(null);
					this.store.dispatch(new CompanyWarehouse({ companyId: val.id }));
				}
			})
		);
		this.pageSubscription.add(
			this.getFormField('incoterm').valueChanges.subscribe(val => {
				this.setDeliveryTypesList(val.id);
			})
		);

		this.pageSubscription.add(
			this.getFormField('payment_type').valueChanges.subscribe(val => {
				if (val.slug === 'other') {
					this.paymentTypePartial$.next(false);
					this.paymentTypeOther$.next(true);

					this.getFormField('payment_other').setValidators(orderValidator.paymentOther);
					this.getFormField('prepayment_amount').clearValidators();
					this.getFormField('prepayment_select').clearValidators();
					this.getFormField('prepayment_amount').setErrors(null);
					this.getFormField('prepayment_select').setErrors(null);
				}
				else if (val.slug === 'partial_pre_payment') {
					this.paymentTypeOther$.next(false);
					this.paymentTypePartial$.next(true);

					this.getFormField('prepayment_amount').setValidators(orderValidator.prepaymentAmount);
					this.getFormField('prepayment_select').setValidators(Validators.required);
					this.getFormField('payment_other').clearValidators();
					this.getFormField('payment_other').setErrors(null);
				}
				else {
					this.paymentTypeOther$.next(false);
					this.paymentTypePartial$.next(false);

					this.getFormField('payment_other').clearValidators();
					this.getFormField('prepayment_amount').clearValidators();
					this.getFormField('prepayment_select').clearValidators();
					this.getFormField('payment_other').setErrors(null);
					this.getFormField('prepayment_amount').setErrors(null);
					this.getFormField('prepayment_select').setErrors(null);
				}
			})
		);

		this.pageSubscription.add(
			this.getFormField('prepayment_select').valueChanges.subscribe(() => {
				this.getFormField('prepayment_amount').setValue('');
			})
		);
	}

	addSubscription(): void {
		this.pageSubscription.add(
			this.companyWarehouse$.subscribe(val => {
				if (val && val.length) {
					this.getFormField('warehouse').setValue(val[0].value);
				}
			})
		);
		this.pageSubscription.add(
			this.deliveryTypesList$.pipe(debounceTime(300)).subscribe(val => {
				if (val.length) {
					let currentDeliveryType = this.getFormField('delivery_type').value;
					let hasDeliveryType = val.find(z => z.value.id === currentDeliveryType.id);
					if (hasDeliveryType) {
						this.getFormField('delivery_type').setValue(hasDeliveryType.value);
					}
					else {
						this.getFormField('delivery_type').setValue(val[0].value);
					}
				}
				else {
					this.getFormField('delivery_type').setValue('');
				}
			})
		);
		this.pageSubscription.add(
			this.actionsSubject.subscribe(z => {
				if (z.type === CheckoutActions.companyWarehouseSuccess || z.type === CheckoutActions.companyWarehouseError) {
					this.preloader$.next(false);
				}
			})
		);
	}

	setDeliveryTypesList(incotermsId: number): void {
		this.store.select(getCheckoutIncoterms).subscribe(list => {
			list.some(z => {
				if (z.id === incotermsId) {
					let deliveryTypesList = z.delivery_types.map(x => {
						return {
							label: x.name,
							value: {
								id: x.id
							}
						}
					});
					this.deliveryTypesList$.next(deliveryTypesList);
					return true;
				}
			})
		}).unsubscribe();
	}

	setUserOrderForm(): void {
		this.userData$.subscribe(user => {
			let companies = user.companies.map(z => {
				return {
					label: z.name,
					value: {
						id: z.id
					}
				}
			});

			this.companyList$.next(companies);
			this.getFormField('company').setValue(companies[0].value);
			this.getFormField('first_name').setValue(user.first_name);
			this.getFormField('last_name').setValue(user.last_name);
		}).unsubscribe();
	}

	setPrePaymentList(): void {
		this.orderSubtotal.subscribe(val => {
			let prePaymentList = getPrePaymentList(val.currency);
			this.prePaymentList$.next(prePaymentList);
			this.getFormField('prepayment_select').setValue(prePaymentList[0].value);
		}).unsubscribe();
	}

	visibleWarehousePopup(): void {
		this.stateWarehousePopup$.next(true)
	}

	closeWarehousePopup(): void {
		this.stateWarehousePopup$.next(false)
	}

	addWerehouse(werehouseItem: PutWarehouseData): void {
		this.preloader$.next(true);
		this.store.dispatch(new PutWarehouse(werehouseItem));
	}

	orderFormSubmit(): void {
		this.orderForm.markAllAsTouched();
		this.detectChanges();
		if (this.orderForm.valid) {

			// User
			let user_id: number;
			this.userData$.subscribe(val => {
				user_id = val.id;
			}).unsubscribe();

			// Product
			let productItems: Array<OrderProductItem>;
			let company_id: number;
			this.store.select(getActiveOrderListDataItem).subscribe(val => {
				company_id = val.company_id;
				productItems = val.products.map(z => {
					return {
						product_id: z.product_id,
						variation_id: z.variation_id,
						quantity: z.quantity
					}
				})
			}).unsubscribe();

			let prepayment_amount = null;
			let prepayment_type = null;
			let payment_other = '';
			if (this.paymentTypeOther$.getValue()) {
				payment_other = this.getFormField('payment_other').value;
			}
			else if (this.paymentTypePartial$.getValue()) {
				prepayment_amount = this.getFormField('prepayment_amount').value;
				prepayment_type = this.getFormField('prepayment_select').value.key;
			}

			let userOrder: UserOrder = {
				seller_company_id: company_id,
				buyer_user_id: user_id,
				buyer_company_id: this.getFormField('company').value.id,
				delivery_type_id: this.getFormField('delivery_type').value.id,
				incoterm_id: this.getFormField('incoterm').value.id,
				warehouse_id: this.getFormField('warehouse').value.id,
				delivery_address: this.getFormField('delivery_address').value,
				payment_type_id: this.getFormField('payment_type').value.id,
				prepayment_amount: prepayment_amount,
				prepayment_type: prepayment_type,
				payment_other: payment_other,
				items: productItems
			};

			this.store.dispatch(new ConfirmUserOrder(userOrder));
		}
		else {
			let invalidControl = '';
			if (this.orderForm.hasError('amountError')) {
				invalidControl = 'prepayment_amount';
			}
			else {
				cycle: for (let key in this.orderForm.controls) {
					if ( this.orderForm.controls[key].invalid ) {
						invalidControl = key;
						break cycle;
					}
				}
			}

			// scroll
			try {
				let elem = document.querySelector(`[formcontrolName=${invalidControl}]`);
				let scrollElem = document.querySelector('.app-wrapper');
				if (elem && scrollElem) {
					let currentScroll = scrollElem.scrollTop;
					let elemTop = elem.getBoundingClientRect().top;
					let top = (currentScroll + elemTop) - 50;
					scrollElem.scrollTo({
						left: 0,
						top: top,
						behavior: 'smooth'
					  });
				}
			}
			catch(error) {
				console.warn("Warning --> scroll error", error);
			}
		}
	}

	ngOnInit(): void {
		window.location.hash = '#authorization_purchase'

		this.setUserOrderForm();
		this.setPrePaymentList();

		let initSub = false;
		this.pageSubscription.add(
			this.store.select(getCheckoutEntities).subscribe(val => {
				if (initSub) {
					return;
				}

				let incoterms = val.incoterms;
				let paymentTypes = val.paymentTypes;
				if (incoterms.length && paymentTypes.length) {
					let incotermsList = incoterms.map(z => {
						return {
							label: z.name,
							value: {
								id: z.id
							}
						}
					});
					let paymentTypesList = paymentTypes.map(z => {
						return {
							label: z.name,
							value: {
								id: z.id,
								slug: z.slug
							}
						}
					});

					initSub = true;
					this.incotermsList$ = new BehaviorSubject(incotermsList);
					this.paymentTypesList$ = new BehaviorSubject(paymentTypesList);
					this.getFormField('incoterm').setValue(incotermsList[0].value);
					this.getFormField('payment_type').setValue(paymentTypesList[0].value);
					setTimeout(() => {
						this.isReady$.next(true);
					}, 300);
				}
				else if (!val.requestState.isFetch) {
					this.store.dispatch(new CheckoutEntities());
				}
			})
		)
	}

	ngAfterViewInit(): void {
		wrapperScrollTop();
	}

	ngOnDestroy(): void {
		window.location.hash = ''
		this.pageSubscription.unsubscribe();
	}
}