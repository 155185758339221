import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ErrorService } from "@app/service/error-service.service";
import { UserService } from '@app/service/user-service.service';


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

	constructor(
		private router: Router,
		private userService: UserService,
		private errorService: ErrorService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
		let request: HttpRequest<any>;

        if ( req.url.includes('assets/i18n') ) {
			request = req;
		}
		else {
			// token
			if (this.userService.getToken()) {
				request = req.clone({
					headers: req.headers.append('Accept-Language', 'en').append('Authorization', `Bearer ${this.userService.getToken()}`),
					withCredentials: true
				});
			}
			else {
				request = req.clone({
					headers: req.headers.append('Accept-Language', 'en'),
					withCredentials: true
				});
			}
		}

		return next.handle(request).pipe(tap(
			(event: any) => {},
			(error: HttpErrorResponse) => {
				/**
				 * Request headers option
				 * option = {
				 * 		notErrorMessage: 'true', (without preview error)
				 * 		errorRedirect: 'true' (has redirect)
				 * }
				 * get(`URL`, {headers: option});
				**/

				// Push error
				try {
					if (!request.headers.has('notErrorMessage')) {
						let errorMessage: Array<string> = [];
						if (error.error.errors) {
							if (typeof error.error.errors === 'object') {
								Object.keys(error.error.errors).forEach(key => {
									errorMessage.push(error.error.errors[key]);
								});
							}
							else if (typeof error.error.errors === 'string') {
								errorMessage.push(error.error.errors);
							}
							else {
								errorMessage.push('Server error');
							}
						}
						else if (error.error.message) {
							errorMessage.push(error.error.message);
						}
						else {
							errorMessage.push(error.message || 'Server error');
						}
						this.errorService.pushError(errorMessage);
					}
				}
				catch(err) {
					console.warn("Warning --> AppHttpInterceptor push error", err);
				}

				// Redirect
				try {
					let redirectLink = error.error.redirect;
					if (request.headers.has('errorRedirect') && redirectLink === undefined) {
						switch(error.status) {
							case 404: {
								this.router.navigate(['404']);
							}; break;
							case 500: {
								this.router.navigate(['500']);
							}; break;
						}
					}
					return;
				}
				catch(err) {
					console.warn("Warning --> AppHttpInterceptor redirect", err);
					return;
				}
			}
		));
    }
}