import { Component, Input, Output, ChangeDetectionStrategy, OnInit, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import {
	OrderListOptionProduct,
	OrderListDataProduct,
	OrderCurrency,
	UpdateOrderListItem,
	getOrderListDataItemProduct,
	getNextBulkPrice
} from "@store/order/order.state";
import { ConfirmDeleteItem } from "../order-list/order-list.component";
import { FetchOrderListData, getFetchOrderData } from '@app/store/order/fetch-order.state';


@Component({
	selector: 'order-list-item',
	templateUrl: './order-list-item.component.html',
	styleUrls: ['./order-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expandDetails', [
			state('true', style({ height: "*" })),
			state('false', style({ height: 0 })),
			transition('true <=> false', animate('0.3s'))
		])
	]
})

export class OrderListItemComponent implements OnInit {

	@Input() public orderItem: OrderListOptionProduct;
	@Input() public orderCurrency: OrderCurrency;
	@Input() public orderCompanyId: number;
	@Output() public deleteItemHandler: EventEmitter<ConfirmDeleteItem>

	// state
	public isOpen$: BehaviorSubject<boolean>;
	public quantityControl: FormControl;

	public orderListDataProduct$: Observable<OrderListDataProduct>;
	public orderBulkPrice$: Observable<{nextPriceQuantity: number, savePrice: number}>;

	constructor(private store: Store<AppStore.AppStoreState>) {
		// init state
		this.isOpen$ = new BehaviorSubject(false);
		this.quantityControl = new FormControl(null);
		this.deleteItemHandler = new EventEmitter();
	}

	get productLink(): string {
		return this.orderItem.slug ? `product/${this.orderItem.slug}` : 'products';
	}

	get quantityMax(): number {
		return this.orderItem.max_quantity;
	}

	toggleOpen(): void {
		let nextState = !this.isOpen$.getValue();
		this.isOpen$.next(nextState);
	}

	deleteItem(): void {
		this.deleteItemHandler.emit({
			company_id: this.orderCompanyId,
			product_id: this.orderItem.id,
			product_name: this.orderItem.name,
			currency: this.orderCurrency,
		})
	}


	ngOnInit(): void {
		this.orderListDataProduct$ = this.store.select(getOrderListDataItemProduct(this.orderCompanyId, this.orderItem.id));

		this.orderListDataProduct$.subscribe(val => {
			if (val) {
				this.quantityControl.setValue(val.quantity);
			}
			else {
				this.quantityControl.setValue(this.orderItem.min_quantity);
			}
		}).unsubscribe();

		this.orderBulkPrice$ = this.orderListDataProduct$.pipe(map(data => {
			let result = null;
			let nextBulkPrice = getNextBulkPrice({
				quantity: data ? data.quantity : null,
				show_bulk_pricing: data ? data.show_bulk_pricing : null,
				bulk_prices: data ? data.bulk_prices : []
			});
			if (nextBulkPrice) {
				let nextPriceQuantity = Number(nextBulkPrice.quantity);
				let nextPrice = Number(nextBulkPrice.quantity) * Number(nextBulkPrice.price);
				let basePrice = Number(nextBulkPrice.quantity) * Number(data.price || 0);
				let savePrice = basePrice - nextPrice;
				if (data.available_quantity !== null && nextPriceQuantity > data.available_quantity) {
					if (nextBulkPrice.isFirst) {
						return null;
					}
					else {
						result = {
							nextPriceQuantity: null,
							savePrice: null,
							bestPrice: true
						};
					}
				}
				else {
					result = {
						nextPriceQuantity: nextPriceQuantity,
						savePrice: savePrice.toFixed(0),
						bestPrice: false
					};
				}
			}
			else if (data && data.show_bulk_pricing && data.bulk_prices.length) {
				result = {
					nextPriceQuantity: null,
					savePrice: null,
					bestPrice: true
				};
			}
			return result;
		}));

		this.quantityControl.valueChanges.subscribe(val => {
			if (this.orderItem) {
				let nextQuantity = Number(val);

				if (nextQuantity >= Number(this.orderItem.min_quantity) && nextQuantity <= this.quantityMax) {
					this.store.dispatch(new UpdateOrderListItem({
						company_id: this.orderCompanyId,
						product_id: this.orderItem.id,
						product_quantity: nextQuantity
					}))
				}
			}
		});
	}
}
