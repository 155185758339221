import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment, IEnv } from '@env/environment';


@Component({
	selector: 'service-page',
	templateUrl: './service-page.component.html',
	styleUrls: ['./service-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ServicePageComponent {

	public pageStatus: '404' | '500' | 'expired';
	public env: IEnv;

	constructor(private route: ActivatedRoute) {
		this.env = environment;
		// init config
		this.route.data.subscribe(val => {
			this.pageStatus = val.pageStatus;
		}).unsubscribe();
	}
}

