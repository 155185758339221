import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { BehaviorSubject, Subscription, Observable, combineLatest } from 'rxjs';
import { SelectItem, ProductItem, BannerItem } from "@app/helper/model";
import { BaseData } from '@app/store/company-page.model';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { FetchCompanyBase, FetchPopularProducts, getCompanyBaseData, getPopularProductsData } from '@app/store/company/company.state';
import { DomainHelperService } from '@app/service/domain-helper.service.';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from '@app/service/app-service.service';
import { MetaService } from '@ngx-meta/core';
import { Title } from '@angular/platform-browser';
import { BANNER_TEMP_ITEM } from '@app/helper/constant';
import { getWindowOption } from '@app/store/common/common.state';
import { map } from 'rxjs/operators';


@Component({
	selector: 'company-page',
	templateUrl: './company-page.component.html',
	styleUrls: ['./company-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.3s ease-out', style({ opacity: 1 })),
			])
		])
	]
})

export class CompanyPageComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild( 'stickyBanner', {static: false} ) stickyBanner: ElementRef;
	@ViewChild( 'topBanner', {static: false} ) topBanner: ElementRef;

	get website() {
		return this.baseDataValue &&
				this.baseDataValue.social_links.find(link => link.type === 'website') &&
				this.baseDataValue.social_links.find(link => link.type === 'website').url;
	}

	public baseData$: Observable<BaseData>;
	public baseDataValue: BaseData;
	public popularProductsList$: Observable<Array<ProductItem>>;
	public bannerList$: BehaviorSubject<Array<BannerItem>>;
	public supplierModalVisible$: BehaviorSubject<boolean>;
	public isMobile$: BehaviorSubject<boolean>;
	private pageSubscription: Subscription;
	public isProductPage: boolean;
	public adressSelectOptions$: BehaviorSubject<SelectItem<{country_id: number}>[]>;
	public orderCompanyTime$: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(
		private cdr: ChangeDetectorRef,
		public appService: AppService,
		private store: Store<AppStore.AppStoreState>,
		public domainHelperService: DomainHelperService,
		private router: Router,
		private titleService: Title,
		private metaService: MetaService) {
		this.isMobile$ = new BehaviorSubject(false);
		this.pageSubscription = new Subscription();
		this.baseData$ = new BehaviorSubject(null);
		this.baseDataValue = null;
		this.popularProductsList$ = new BehaviorSubject(null);
		this.isProductPage = false;
		this.adressSelectOptions$ = new BehaviorSubject([]);

		this.bannerList$ = new BehaviorSubject([BANNER_TEMP_ITEM, BANNER_TEMP_ITEM]);

		this.baseData$ = this.store.select(getCompanyBaseData);
		this.popularProductsList$ = combineLatest(
			this.store.select(getWindowOption),
			this.store.select(getPopularProductsData)
		).pipe( map( data => {
			let window = data[0];
			let products = data[1];

			if(window && products) {
				if( window.type === 'mobile' ) {
					return products.slice(0,4);
				} else {
					return products.slice(0,5);
				}
			} else {
				return [];
			}
		}));

		this.supplierModalVisible$ = new BehaviorSubject(false);
		this.store.select(getWindowOption).subscribe( val => {
			if (val && val.type === 'mobile') {
				this.isMobile$.next(true);
			} else {
				this.isMobile$.next(false);
			}
		})
	}

	// addMetaTag() {
	// 	let url = window.location.href;
	// 	this.metaService.setTag('og:url', url);
	// 	this.metaService.setTag('twitter:card', 'summary');

	// 	this.baseData$.subscribe(val => {
	// 		let title = val.name;
	// 		let image = val.logo;
	// 		let description = val.description;
	// 		this.metaService.setTag('og:title', title);
	// 		this.metaService.setTag('og:image', image);
	// 		this.metaService.setTag('og:description', description);

	// 		this.metaService.setTag('twitter:title', title);
	// 		this.metaService.setTag('twitter:image', image);
	// 		this.metaService.setTag('twitter:description', description);

	// 		this.titleService.setTitle(title);
	// 	}).unsubscribe();
	// }

	ngOnInit() {

		// preview company
		this.isProductPage = this.router.url.includes('products');
		this.pageSubscription.add(
			this.router.events.subscribe( event => {
				if ( event instanceof NavigationEnd ) {
					this.isProductPage = event.urlAfterRedirects.includes('products');

					let url = window.location.href;
					this.metaService.setTag('og:url', url);
				}
			})
		);

		this.pageSubscription.add( this.domainHelperService.getDomain().subscribe( data => {
			if( data ) {
				this.store.dispatch(new FetchCompanyBase(null));
				this.store.dispatch(new FetchPopularProducts({limit: 5}));
			}
		}));

		this.pageSubscription.add(
			this.baseData$.subscribe( data => {
				this.baseDataValue = data;
				if( data ) {
					// filter by office emails
					let newData = {...data};
					newData.office_address = newData.office_address.filter( (adress) => {
						return adress.contacts.some( contact => contact.type == 'email' && contact.value );
					});

					let officeAddress = newData.office_address.map(address => {
						return {
							label:  address.country ? address.country.name : 'County name is undefined',
							value: {
								country_id: address.country.id
							}
						};
					});

					// filter by unique country
					let filteredAddressArray: SelectItem<{country_id: number}>[] = [];
					officeAddress.forEach( adress => {
						if( !filteredAddressArray.find( item => item.value.country_id === adress.value.country_id ) ) filteredAddressArray.push(adress);
					});

					officeAddress = filteredAddressArray;
					this.adressSelectOptions$.next( officeAddress );
					this.getSuppliersLocalTime()
					//костыль
					setTimeout(() => {
						this.cdr.detectChanges();
					}, 500);

					// this.addMetaTag();
				}
			})
		)

	}

	ngAfterViewInit() {
		const appWrapper = document.querySelector('.app-wrapper');
		appWrapper.addEventListener('scroll', (e) => {
			if( this.stickyBanner && this.topBanner ) {
				const toTopBannerBottom = this.topBanner.nativeElement.getBoundingClientRect().bottom;
				const scrolledHeight = (e as any).target.scrollTop;
				if( scrolledHeight >= scrolledHeight + toTopBannerBottom ) this.stickyBanner.nativeElement.classList.add('active');
				else this.stickyBanner.nativeElement.classList.remove('active');
			}
		});
	}

	ngOnDestroy() {
		this.pageSubscription.unsubscribe();
	}

	visibleSupplierPopup() {
		if(!this.appService.getIsPreviewValue()) {
			this.supplierModalVisible$.next(true);
		}
	}

	closeSupplierPopup() {
		this.supplierModalVisible$.next(false);
	}

	getFirstLetter(name: string) {
		return name && name.match(/[a-zA-Z]/) ? name.match(/[a-zA-Z]/)[0] : '';
	}

	get linkQuery() {
		if (this.appService.getIsPreviewValue()) {
			return { '__preview': 'true', 'companyDomain': this.appService.getCompanyPreviewDomainValue() }
		}
		return {};
	}

	getSuppliersLocalTime() {
		setInterval(() => {
			let dateTime = new Date();
			let dateTimeOptions = {
				hour12: false,
				hour: 'numeric',
				minute: 'numeric',
				timeZone: this.baseDataValue && this.baseDataValue.time_zone
			};
			this.orderCompanyTime$.next(dateTime.toLocaleString('nu', dateTimeOptions));
		}, 1000);
	}

}