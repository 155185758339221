import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { trigger, style, animate, transition, state } from '@angular/animations';
import {
	OrderListOptionProduct,
	OrderCurrency,
} from "@store/order/order.state";


@Component({
	selector: 'order-success-item',
	templateUrl: './order-success-item.component.html',
	styleUrls: ['./order-success-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expandDetails', [
			state('true', style({ height: "*" })),
			state('false', style({ height: 0 })),
			transition('true <=> false', animate('0.3s'))
		])
	]
})

export class orderSuccessItemComponent {

	@Input() public orderItem: OrderListOptionProduct;
	@Input() public orderCurrency: OrderCurrency;

	// state
	public isOpen$: BehaviorSubject<boolean>;

	constructor() {
		// init state
		this.isOpen$ = new BehaviorSubject(false);
	}

	toggleOpen(): void {
		let nextState = !this.isOpen$.getValue();
		this.isOpen$.next(nextState);
	}
}
