import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from '@api/api.service';
import { UserService } from "@app/service/user-service.service";
import {
	UserActions,
	SignIn,
	SignInSuccess,
	SignInError,
	SignUp,
	SignUpSuccess,
	SignUpError,
	UserDataFetch,
	UserDataSuccess,
	UserDataError,
	UserCountry,
	UserCountrySuccess,
	UserCountryError,
	UserData,
	UserCompany,
	ForgotPassword,
	ForgotPasswordSuccess,
	ForgotPasswordError
} from "./user.state";
import { Notifications } from "@store/common/common.state";


// Helper
const getUserData = (user: any): UserData => {
	let userData: UserData = {
		id: user.id,
		email: user.email,
		phone: user.phone,
		avatar: user.avatar,
		country_id: user.country_id,
		time_zone: user.time_zone,
		first_name: user.first_name,
		last_name: user.last_name,
		companies: user.companies.map((z: any) => {
			let item: UserCompany = {
				id: z.id,
				name: z.name
			}
			return item;
		}).sort((a: UserCompany, b: UserCompany) => a.id > b.id ? 1 : -1)
	}
	return userData;
};


@Injectable()
export class UserEffects {

	constructor(
		private actions$: Actions,
		private apiService: ApiService,
		private userService: UserService
	) { }

	public userSignIn$ = createEffect(() => this.actions$.pipe(
		ofType(UserActions.signIn),
		mergeMap((data: SignIn) => this.apiService.userSignIn(data.payload)
			.pipe(
				switchMap((data: any) => {
					try {
						let token: string = data.access_token;
						let user = getUserData(data.user);
						this.userService.setToken(token);
						return [
							new SignInSuccess(user),
							new Notifications({user_id: user.id})
						];
					}
					catch(error) {
						console.error("Error --> userSignIn", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new SignInError(error.errors || error));
				})
			)))
	);

	public userSignUp$ = createEffect(() => this.actions$.pipe(
		ofType(UserActions.signUp),
		mergeMap((data: SignUp) => this.apiService.userSignUp(data.payload)
			.pipe(
				switchMap((data: any) => {
					try {
						let token: string = data.access_token;
						let user = getUserData(data.user);
						this.userService.setToken(token);
						return [
							new SignUpSuccess(user),
							new Notifications({user_id: user.id})
						];
					}
					catch(error) {
						console.error("Error --> userSignUp", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new SignUpError(error.errors || error));
				})
			)))
	);

	public userData$ = createEffect(() => this.actions$.pipe(
		ofType(UserActions.userData),
		mergeMap((data: UserDataFetch) => this.apiService.getUser(data.payload.id)
			.pipe(
				switchMap((data: any) => {
					try {
						let user = getUserData(data);
						return [
							new UserDataSuccess(user),
							new Notifications({user_id: user.id})
						];
					}
					catch(error) {
						console.error("Error --> userData", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new UserDataError(error.errors || error));
				})
			)))
	);

	public userCountry$ = createEffect(() => this.actions$.pipe(
		ofType(UserActions.userCountry),
		mergeMap(() => this.apiService.getUserCountry()
			.pipe(
				map((data: any) => {
					try {
						let userCountry: UserCountry = {
							id: data.country_info.id,
							code: data.country_info.code,
							time_zone: data.time_zone
						};
						return new UserCountrySuccess(userCountry);
					}
					catch(error) {
						console.error("Error --> userCountry", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new UserCountryError(error.errors || error));
				})
			)))
	);

	public forgotPassword$ = createEffect(() => this.actions$.pipe(
		ofType(UserActions.forgotPassword),
		mergeMap((data: ForgotPassword) => this.apiService.forgotPassword(data.payload)
			.pipe(
				map(() => {
					return new ForgotPasswordSuccess();
				}),
				catchError(error => {
					return of(new ForgotPasswordError(error.errors || error));
				})
			)))
	);
}