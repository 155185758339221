import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { ContentChildren, QueryList } from '@angular/core';
import { UiKitTabComponent } from '../uikit-tab/uikit-tab.component';


@Component({
  selector: 'uikit-tabs',
  templateUrl: './uikit-tabs.component.html',
  styleUrls: ['./uikit-tabs.component.scss']
})

export class UiKitTabsComponent implements AfterContentInit {

	@ContentChildren(UiKitTabComponent) tabs: QueryList<UiKitTabComponent>;

	@Input() public activeTab: number;
	@Output() public changeTabEmit: EventEmitter<number>;

	constructor() {
		this.changeTabEmit = new EventEmitter();
	}

	setActiveTap(tab: UiKitTabComponent, index: number): void {
		this.tabs.toArray().forEach(tab => tab.active$.next(false));
		tab.active$.next(true);
		this.changeTabEmit.emit(index);
	}

	setActiveTapIndex(index: number): void {
		let tab = this.tabs.toArray()[index];
		if (tab) {
			this.setActiveTap(tab, index);
		}
	}

	ngAfterContentInit(): void {
		this.tabs.toArray().forEach(tab => tab.active$.next(false));
		if (this.activeTab && typeof this.activeTab === 'number') {
			this.tabs.toArray()[this.activeTab].active$.next(true)
		}
		else {
			this.tabs.first.active$.next(true);
		}
	}
}
