import { env as local } from "./const.local_development";
import { env as dev_env } from "./const.development";
import { env as stage_env } from "./const.staging";
import { env as prod_env } from "./const.production";

export interface IEnv {
  production: boolean,
  stagging: boolean,
  local?: boolean,
  apiUrl: string,
  apiMarketplaceUrl: string,
  marketplaceUrl: string,
  marketplaceSubDomain: string,
  blogUrl: string,
  tradalaxyUrl: string,
  helpCenterUrl: string,
  landingUrl: string,
  mainCookiesDomain: string,
  cookieTokenName: string,
  cookieLanguagesName: string,
  cookieMainLangName: string,
}

let env;

function getBsEnv() {
	let bs = window['bs_env'];
	if (!bs || !bs.ENV_MODE) {
		return local;
	}

	switch (bs.ENV_MODE) {
		case 'Production':
			return prod_env;
		case 'Stage':
			return stage_env;
		default:
			return dev_env;
	}
}

env = getBsEnv();

export const environment: IEnv = { ...env };
