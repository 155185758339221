import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name: 'firstLetter',
	pure: true
})

export class FirstLetterPipe implements PipeTransform {
	transform(str: string): string {
		if (str) {
			return str.match(/[a-zA-Z]/) ? str.match(/[a-zA-Z]/)[0] : str.charAt(0);
		}
		else {
			return '';
		}
	}
}