import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, OnDestroy, forwardRef, ElementRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { trigger, style, animate, transition } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";

interface OptionItem {
	label: string,
	value: any,
	[key: string]: any
}


@Component({
	selector: 'uikit-autocomplete',
	templateUrl: './uikit-autocomplete.component.html',
	styleUrls: ['./uikit-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UiKitAutocompleteComponent),
    		multi: true
		}
	],
	animations: [
		trigger('openClose', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.1s', style({ opacity: 1 })),
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('0.1s', style({ opacity: 0 })),
			])
		])
	]
})

export class UiKitAutocompleteComponent implements ControlValueAccessor, OnChanges, OnDestroy {

	@Input() public uiClass: string;
	@Input() public uiTitle: string;
	@Input() public uiError: string;
	@Input() public uiPlaceholder: string;
	@Input() public uiEmptyText: string;
	@Input() public uiLoadingText: string;
	@Input() public uiTitleVisible: boolean;
	@Input() public uiTitleHideLabel: boolean;
	@Input() public uiErrorVisible: boolean;
	@Input() public uiDisabled: boolean;
	@Input() public uiRequired: boolean;
	@Input() public tabIndex: number;

	// option
	@Input() public options: Array<OptionItem>;
	@Input() public optionLabel: string;

	// event
	@Output() public autocompliteHandler: EventEmitter<string>;

	// state
	private autocompleteSubscription: Subscription;
	private autocompleteSearchText: string;
	public autocompleteSearch: FormControl;
	public autocompleteOptionLoad$: BehaviorSubject<boolean>;
	public isOpen$: BehaviorSubject<boolean>;

	// controlValueAccessor
	public onChange: Function;
	public onTouched: Function;

	// outside click
	public outsideClick: () => any;

	constructor(private elRef: ElementRef) {
		// default state
		this.uiClass = '';
		this.uiTitle = '';
		this.uiError = '';
		this.uiPlaceholder = '';
		this.uiEmptyText = '';
		this.uiLoadingText = '';
		this.uiTitleVisible = true;
		this.uiTitleHideLabel = false;
		this.uiErrorVisible = false;
		this.uiDisabled = false;
		this.uiRequired = false;
		this.tabIndex = 0;

		// options
		this.options = [];
		this.optionLabel = 'label';

		// event
		this.autocompliteHandler = new EventEmitter();

		// state
		this.autocompleteSubscription = new Subscription();
		this.autocompleteSearchText = '';
		this.autocompleteSearch = new FormControl();
		this.autocompleteOptionLoad$ = new BehaviorSubject(false);
		this.isOpen$ = new BehaviorSubject(false);

		// state subscription
		this.autocompleteSubscription.add(
			this.autocompleteSearch.valueChanges.pipe(debounceTime(500)).subscribe(val => {
				this.autocompleteSearchText = val;
				this.autocompliteHandler.emit(val);
			})
		);
		this.autocompleteSubscription.add(
			this.autocompleteSearch.valueChanges.subscribe(val => {
				this.autocompleteOptionLoad$.next(true);
			})
		);

		// controlValueAccessor
		this.onChange = () => {};
		this.onTouched = () => {};

		// ouside click
		this.outsideClick = ((event: MouseEvent) => {
			if ( !this.elRef.nativeElement.contains(event.target) ) {
				this.toggleDropdown(false);
			}
		}).bind(this);
	}

	get placeholderText(): string {
		return (this.uiPlaceholder) ? this.uiPlaceholder : 'Search';
	}

	get emptyText(): string {
		return (this.uiEmptyText) ? this.uiEmptyText : 'Empty search result';
	}

	get loadingText(): string {
		return (this.uiLoadingText) ? this.uiLoadingText : 'Loading ...';
	}

	_outsideClickListener(addListener: boolean): void {
		if (addListener) {
			document.body.addEventListener('click', this.outsideClick);
		}
		else {
			document.body.removeEventListener('click', this.outsideClick);
		}
	}

	toggleDropdown(val?: boolean): void {
		let nextState = (val !== undefined) ? val : !this.isOpen$.getValue();
		this.isOpen$.next(nextState);
		this._outsideClickListener(nextState);
	}

	selectItem(item: OptionItem): void {
		this.writeValue(item.value);
		this.onTouched();
		this.toggleDropdown(false);
		this.autocompleteSearch.setValue(item[this.optionLabel]);
	}

	autocompleteFocus(): void {
		this.toggleDropdown(true);
	}

	writeValue(value: any): void {
		if (!value) {
			this.autocompleteSearch.setValue('');
			return;
		}
		this.onChange(value);
	}

	registerOnChange(cb: Function): void {
		this.onChange = cb;
	}

	registerOnTouched(cb: Function): void {
		this.onTouched = cb;
	}

	ngOnChanges(): void {
		if (this.autocompleteSearchText === this.autocompleteSearch.value) {
			this.autocompleteOptionLoad$.next(false);
		}
	}

	ngOnDestroy(): void {
		this.autocompleteSubscription.unsubscribe();
	}
}
