import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';

// custom modules
import { SvgInlineModule } from "./svg-inline/svg-inline.module";
import { PromoProductModule } from "./promo-product/promo-product.module";
import { PromoBannerModule } from "./promo-banner/promo-banner.module";
import { CertificateItemModule } from "./certificate-item/certificate-item.module";
import { SocialSharedModule } from "./social-share/social-share.module";
import { AppMapModule } from "./app-map/app-map.module";
import { OrderListModule } from "./order-list/order-list.module";
import { ModalModule } from "./modals/modal.module";
import { AppMobileAccordionModule } from "./mobile-accordion/mobile-accordion.module";


@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		SvgInlineModule,
		PromoProductModule,
		PromoBannerModule,
		CertificateItemModule,
		ModalModule,
		SocialSharedModule,
		TranslateModule,
		AppMapModule,
		OrderListModule,
		AppMobileAccordionModule
	],
	exports: [
		SvgInlineModule,
		PromoProductModule,
		PromoBannerModule,
		CertificateItemModule,
		ModalModule,
		SocialSharedModule,
		TranslateModule,
		AppMapModule,
		OrderListModule,
		AppMobileAccordionModule
	]
})

export class SharedModule { }
