import { AbstractControl } from '@angular/forms';

// Interface
interface CheckError {
	check: boolean,
	errorName: string
};

interface HasError {
	errorName: string
};


// Helper function
function _hasError(valid: Array<CheckError>): HasError | null {
	let error = valid.find(z => z.check);
	return (error) ? { errorName: error.errorName } : null;
};

function _isRequired(val: string, errorKey: string): CheckError {
	return {
		check: val ? !Boolean(val.trim()) : true,
		errorName: errorKey
	};
};

function _forbiddenSymbols(val: string, reg: RegExp, errorKey: string): CheckError {
	return {
		check: !reg.test(val),
		errorName: errorKey
	};
};

function _dontHaveSymbols(val: string, reg: RegExp, errorKey: string): CheckError {
	return {
		check: reg.test(val),
		errorName: errorKey
	};
};

function _onlySpace(val: string, errorKey: string): CheckError {
	return {
		check: val !== null && val.length > 0 && /^\s+$/.test(val),
		errorName: errorKey
	};
};

function _hasSpace(val: string, errorKey: string): CheckError {
	return {
		check: val !== null && val.length > 0 && /\s/.test(val),
		errorName: errorKey
	};
};

function _minLength(val: string, leng: number, errorKey: string): CheckError {
	return {
		check: val !== null && val.length < leng,
		errorName: errorKey
	};
};

function _maxLength(val: string, leng: number, errorKey: string): CheckError {
	return {
		check: val !== null && val.length > leng,
		errorName: errorKey
	};
};

function _containCyrillic(val: string, errorKey: string): CheckError {
	return {
		check: /[а-яА-ЯёїіЁЇІєЄ]/.test(val),
		errorName: errorKey
	};
};

function _email(val: string, errorKey: string): CheckError {
	const nameRules = `^(([a-zA-Z\\d])[\\.\\-_+]?)*[a-zA-Z\\d]`;
	const domainRules = `[a-z\\d]+(\\-?[a-z]+)?`;
	const topLevelRules = `([a-z]{2,10}\\.)?[a-z]{2,10}`;
	const regex = new RegExp(`^((${nameRules})@(${domainRules})\\.(${topLevelRules}))$`);
	return {
		check: !regex.test(val),
		errorName: errorKey
	};
};


// Form validator
class CommonValidator {
	email(control: AbstractControl): HasError | null {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.email_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_maxLength(value, 255, 'validations.maximum_length_255_characters'),
			_email(value, 'validations.email_is_invalid')
		];
		let hasError = _hasError(valid)
		return hasError;
	}
}

export const commonValidator = new CommonValidator();


class OrderValidator {
	email(control: AbstractControl): HasError | null {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.email_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_maxLength(value, 255, 'validations.maximum_length_255_characters'),
			_email(value, 'validations.email_is_invalid')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	password(control: AbstractControl): HasError | null {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.pass_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_minLength(value, 6, 'validations.minimum_length_6_characters'),
			_maxLength(value, 255, 'validations.maximum_length_255_characters'),
			_dontHaveSymbols(value, /^[^0-9]*$/, 'validations.pass_num_required'),
			_dontHaveSymbols(value, /^[^a-z]*$/, 'validations.pass_lower_required'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_hasSpace(value, 'validations.pass_has_space')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	firstName(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.first_name_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_forbiddenSymbols(value, /^[a-zA-Z—\s–\'-]*$/, 'validations.name_letters_required'),
			_dontHaveSymbols(value, /^[^a-zA-Z]*$/, 'validations.first_name_consist_only_special_characters'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_maxLength(value, 255, 'validations.maximum_length_255_characters')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	lastName(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.last_name_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_forbiddenSymbols(value, /^[a-zA-Z—\s–\'-]*$/, 'validations.name_letters_required'),
			_dontHaveSymbols(value, /^[^a-zA-Z]*$/, 'validations.last_name_consist_only_special_characters'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_maxLength(value, 250, 'validations.maximum_length_255_characters')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	companyName(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.company_name_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_forbiddenSymbols(value, /^[a-zA-Z0-9—\s–\"-\.\,©\:\(\)\/\!\?\#\'\+\=\*\@\&]*$/, 'validations.company_name_consist_only_letters_numbers_spaces_symbols'),
			_minLength(value, 2, 'validations.minimum_length_2_characters'),
			_maxLength(value, 120, 'validations.maximum_length_120_characters'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_dontHaveSymbols(value, /^[0-9\s]*$/, 'validations.cannot_consist_only_of_numbers'),
			_dontHaveSymbols(value, /^[^0-9a-zA-Zа-яА-ЯёїіЁЇІєЄ]*$/, 'validations.company_name_consist_only_special_characters')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	deliveryAddress(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.delivery_address_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_forbiddenSymbols(value, /^[a-zA-Z0-9—\s–\"-\.\,\:\(\)\/\#\'\&]*$/, 'validations.delivery_address_consist_only_letters_numbers_spaces_symbols'),
			_dontHaveSymbols(value, /^[^0-9a-zA-Z]*$/, 'validations.delivery_address_consist_only_special_characters'),
			_maxLength(value, 255, 'authorization_user_signup.max_length_255'),
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	prepaymentAmount(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.prepayment_amount_required'),
			_forbiddenSymbols(value, /^([0-9]*(\.[0-9]+)?)$/, 'validations.float_numbers'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_maxLength(value, 20, 'validations.maximum_length_20_characters'),
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	paymentOther(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.payment_other_required'),
			_forbiddenSymbols(value, /^[a-zA-Z0-9—\s–\"-\.\,\!\?\+\=\*\@\:\(\)\/\#\'\&]*$/, 'validations.payment_other_only_letters_numbers_spaces_symbols'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_maxLength(value, 50, 'validations.maximum_length_50_characters'),
		];
		let hasError = _hasError(valid)
		return hasError;
	}
}

export const orderValidator = new OrderValidator();



//  supplier validator
class SupplierValidator {

	name(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.first_name_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_forbiddenSymbols(value, /^[a-zA-Z—\s–\'-]*$/, 'validations.name_letters_required'),
			_dontHaveSymbols(value, /^[^a-zA-Z]*$/, 'validations.first_name_consist_only_special_characters'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_maxLength(value, 255, 'validations.maximum_length_255_characters')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	companyName(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_isRequired(value, 'validations.company_name_required'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_forbiddenSymbols(value, /^[a-zA-Z0-9—\s–\"-\.\,©\:\(\)\/\!\?\#\'\+\=\*\@\&]*$/, 'validations.company_name_consist_only_letters_numbers_spaces_symbols'),
			_minLength(value, 2, 'validations.minimum_length_2_characters'),
			_maxLength(value, 120, 'validations.maximum_length_120_characters'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
			_dontHaveSymbols(value, /^[0-9\s]*$/, 'validations.cannot_consist_only_of_numbers'),
			_dontHaveSymbols(value, /^[^0-9a-zA-Zа-яА-ЯёїіЁЇІєЄ]*$/, 'validations.company_name_consist_only_special_characters')
		];
		let hasError = _hasError(valid)
		return hasError;
	}

	comment(control: AbstractControl) {
		let value = control.value;
		let valid = [
			_forbiddenSymbols(value, /^[a-z-A-Z0-9\-\—\ \'".,:()\/!?#+=*@&©\n]*$/, 'validations.company_name_consist_only_letters_numbers_spaces_symbols'),
			_containCyrillic(value, 'validations.forbid_cyrilic'),
			_isRequired(value, 'validations.supplier_comment_required'),
			_maxLength(value, 750, 'validations.maximum_length_750_characters'),
			_onlySpace(value, 'validations.cant_contain_only_spaces'),
		];
		let hasError = _hasError(valid)
		return hasError;
	}
}

export const supplierValidator = new SupplierValidator();