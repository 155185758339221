import { Component, ChangeDetectionStrategy, Input, Output, OnDestroy, EventEmitter} from "@angular/core";
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import {
	PortListSuccess,
	TerminalListSuccess,
	CityListSuccess,
	CityItem,
	getCityListData,
	CityList,
	PortList,
	PortItem,
	getPortListData,
	TerminalItem,
	TerminalList,
	getTerminalListData,
	PutWarehouseData
} from '@app/store/checkout/checkout.state';
import {
	CountryItem,
	getCountry
} from "@store/common/common.state";


@Component({
	selector: 'warehouse-modal',
	templateUrl: './warehouse-modal.component.html',
	styleUrls: ['./warehouse-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class WarehouseModalComponent implements OnDestroy {

	@Input() public companyId: number;
	@Output() public addWerehouse: EventEmitter<PutWarehouseData>;
	@Output() public close: EventEmitter<void>;

	private pageSubscription: Subscription;

	public countryList$: Observable<Array<CountryItem>>;
	public cityList$: Observable<Array<CityItem>>
	public portList$: Observable<Array<PortItem>>
	public terminalList$: Observable<Array<TerminalItem>>

	public werehouseForm: FormGroup;

	constructor(private store: Store<AppStore.AppStoreState>) {

		// init state
		this.addWerehouse = new EventEmitter();
		this.close = new EventEmitter();
		this.pageSubscription = new Subscription();

		this.countryList$ = this.store.select(getCountry);
		this.cityList$ = this.store.select(getCityListData);
		this.terminalList$ = this.store.select(getTerminalListData);
		this.portList$ = this.store.select(getPortListData);

		this.initWerehouseForm();
	}

	getFormField(controlName: string): AbstractControl {
		return this.werehouseForm.controls[controlName];
	}

	initWerehouseForm(): void {
		this.werehouseForm = new FormGroup({
			warehouse_country: new FormControl('', Validators.required),
			warehouse_city: new FormControl('', Validators.required),
			warehouse_terminal: new FormControl('', Validators.required),
			warehouse_port: new FormControl('', Validators.required)
		});

		this.pageSubscription.add(
			this.getFormField('warehouse_country').valueChanges.subscribe(() => {
				this.getFormField('warehouse_city').setValue('');
				this.getFormField('warehouse_terminal').setValue('');
				this.getFormField('warehouse_port').setValue('');
			})
		);
		this.pageSubscription.add(
			this.getFormField('warehouse_city').valueChanges.subscribe(val => {
				if (val) {
					let countryId = this.getFormField('warehouse_country').value.id;
					let cityId = val.id;
					this.getFormField('warehouse_terminal').setValue('');
					this.getFormField('warehouse_port').setValue('');
					this.store.dispatch(new PortList({countryId: countryId, cityId: cityId}));
					this.store.dispatch(new TerminalList({countryId: countryId, cityId: cityId}));
				}
				else {
					this.getFormField('warehouse_terminal').setValue('');
					this.getFormField('warehouse_port').setValue('');
					this.store.dispatch(new PortListSuccess([]));
					this.store.dispatch(new TerminalListSuccess([]));
				}
			})
		);
	}

	fetchCity(query: string): void {
		let countryId = this.getFormField('warehouse_country').value.id;
		if (countryId) {
			this.store.dispatch(new CityList({countryId: countryId, query: query}));
		}
		else {
			this.store.dispatch(new CityListSuccess([]));
		}
	}

	closeWarehouseModal(): void {
		this.werehouseForm.reset();
		this.close.emit();
	}

	submitForm(): void {
		this.werehouseForm.markAllAsTouched();
		if (this.werehouseForm.valid) {
			let newWerehouse = {
				city_id: this.getFormField('warehouse_city').value.id,
				country_id: this.getFormField('warehouse_country').value.id,
				terminal_id: this.getFormField('warehouse_terminal').value.id,
				port_id: this.getFormField('warehouse_port').value.id,
				company_id: this.companyId
			};

			this.addWerehouse.emit(newWerehouse);
			this.closeWarehouseModal();
		}
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}
}
