import { Component, ChangeDetectionStrategy, NgZone, OnDestroy, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { WorldMapDataItem } from '@app/helper/model';

export interface MapColorsTheme {
	hover: string,
	active: string,
	default: string,
	selected: string,
	tooltipFill: string,
	tooltipTextFill: string
}


@Component({
	selector: 'app-map',
	templateUrl: './app-map.component.html',
	styleUrls: ['./app-map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppMapComponent implements OnDestroy {

	@Input() public mapColorsTheme: MapColorsTheme;

	@Input()
	set worldMapData( value: Array<WorldMapDataItem> ) {
		if( value && value.length && value !== this.worldMapData ) {
			this._worldMapData = [...value];
			this.initMap();
		}
	};
	get worldMapData() {
		return this._worldMapData;
	}

	private chart: am4maps.MapChart;
	private _worldMapData: Array<WorldMapDataItem> = [];

	constructor(private zone: NgZone) {
		this.mapColorsTheme = {
			hover: "#4D846E",
			active: "#346753",
			default: "#EFF1F0",
			selected: "#D2B450",
			tooltipFill: "#ffffff",
			tooltipTextFill: "#000000",
		}
	}

	initMap(): void {
		this.zone.runOutsideAngular(() => {
			// Create map instance
			let chart = am4core.create("chartdiv", am4maps.MapChart);

			// Set map definition
			chart.geodata = am4geodata_worldLow;

			// Set projection
			chart.projection = new am4maps.projections.Miller();

			// Series for World map
			let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
			worldSeries.exclude = ["AQ"];
			worldSeries.useGeodata = true;
			worldSeries.data = this.worldMapData;

			let polygonTemplate = worldSeries.mapPolygons.template;
			polygonTemplate.tooltipHTML = (`
				<div class="trade-type-external-countries-item">
					{tooltipHtml}
				</div>`
			);
			polygonTemplate.fill = am4core.color(this.mapColorsTheme.default);
			polygonTemplate.nonScalingStroke = true;
			polygonTemplate.propertyFields.fill = "fill";

			worldSeries.tooltip.getFillFromObject = false;
			worldSeries.tooltip.background.fill = am4core.color(this.mapColorsTheme.tooltipFill);
			worldSeries.tooltip.label.fill = am4core.color(this.mapColorsTheme.tooltipTextFill);

			// Hover state
			let hs = polygonTemplate.states.create("hover");
			hs.properties.fill = am4core.color(this.mapColorsTheme.hover);
		})

		// deleting chart link
		const svg = document.querySelector('#chartdiv').querySelector('svg');
		const g = svg.querySelector('g');
		const g2 = g.querySelectorAll('g');

		for(let i = 0; i<g2.length; i++) {
			if( i>=52 && i<=56) g2[i].style.display = 'none';
		}
	}

	ngOnDestroy(): void {
		this.zone.runOutsideAngular(() => {
			if (this.chart) {
				this.chart.dispose();
			}
		});
	}
}
