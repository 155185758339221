
import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestState, initRequestState } from '@store/store.shared';


// Interface
export interface FetchCardData {
	product_id: number,
	quantity: number,
	variation_id?: number
}

export interface ResponseCardData {
	total_price: string,
	company: {
		name: string,
		logo: string,
		main_address: {
			id: number,
			zip_code: string,
			address: string,
			country: {
				id: number,
				name: string,
				code: string
			},
			city: {
				id: number,
				name: string
			},
			contacts: Array<{
				id: number,
				type: string,
				value: string,
				is_verified: boolean
			}>
		},
		marketplace_url: string
	},
	items: Array<{
		id: number,
		product_id: number,
		variation_id: number,
		quantity: number,
		item: {
			id: number,
			name: string,
			images: Array<{
				url: string,
				is_main: true
			}>,
			price: number,
			currency: string,
			available_quantity: number,
			minimum_order: number,
			bulk_prices: any[],
			show_bulk_pricing: boolean,
			unit: string,
			unit_in_package: string,
			availability: string,
			availability_date: string,
			availability_days: number,
			quantity_in_one_package: number,
			slug: string,
			features: Array<{
				attribute_name: string,
				attribute_option_name: string
			}>
		}
	}>
}



// State
export interface FetchOrderState {
	fetchOrderListData: {
		requestState: RequestState,
		data: Array<ResponseCardData>
	},
	getOrderListData: {
		requestState: RequestState,
		data: Array<ResponseCardData>
	}
};

const INITIAL_STATE: FetchOrderState = {
	fetchOrderListData: {
		requestState: initRequestState(),
		data: undefined
	},
	getOrderListData: {
		requestState: initRequestState(),
		data: undefined
	},
};


// Action
export const FetchOrderActions = {
	fetchOrderListData: '[Fetch_Order] Fetch_Order_List_Data',
	fetchOrderListDataSuccess: '[Fetch_Order] Fetch_Order_List_Data_Success',
	fetchOrderListDataError: '[Fetch_Order] Fetch_Order_List_Data_Error',

	getOrderListData: '[Fetch_Order] Get_Order_List_Data',
	getOrderListDataSuccess: '[Fetch_Order] Get_Order_List_Data_Success',
	getOrderListDataError: '[Fetch_Order] Get_Order_List_Data_Error',
};


export class FetchOrderListData implements Action {
	readonly type = FetchOrderActions.fetchOrderListData;
	constructor(public payload: FetchCardData) { }
};

export class FetchOrderListDataSuccess implements Action {
	readonly type = FetchOrderActions.fetchOrderListDataSuccess;
	constructor(public payload: Array<ResponseCardData>) { }
};

export class FetchOrderListDataError implements Action {
	readonly type = FetchOrderActions.fetchOrderListDataError;
	constructor(public payload: any) { }
};


export class GetOrderListData implements Action {
	readonly type = FetchOrderActions.getOrderListData;
	constructor() { }
};

export class GetOrderListDataSuccess implements Action {
	readonly type = FetchOrderActions.getOrderListDataSuccess;
	constructor(public payload: Array<ResponseCardData>) { }
};

export class GetOrderListDataError implements Action {
	readonly type = FetchOrderActions.getOrderListDataError;
	constructor(public payload: any) { }
};

// Reducer
export function FetchOrderReducer(state = INITIAL_STATE, action: Action) {
	switch (action.type) {

		case FetchOrderActions.fetchOrderListData: {
			return {
				...state,
				fetchOrderListData: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case FetchOrderActions.fetchOrderListDataSuccess: {
			let payload = (action as any).payload;
			return {
				...state,
				fetchOrderListData: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case FetchOrderActions.fetchOrderListDataError: {
			let payload = (action as any).payload;
			return {
				...state,
				fetchOrderListData: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;


		case FetchOrderActions.getOrderListData: {
			return {
				...state,
				getOrderListData: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case FetchOrderActions.getOrderListDataSuccess: {
			let payload = (action as any).payload;
			return {
				...state,
				getOrderListData: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case FetchOrderActions.getOrderListDataError: {
			let payload = (action as any).payload;
			return {
				...state,
				getOrderListData: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		default: {
			return state;
		}
	}
};


// Getter
export const getOrderState = createFeatureSelector<FetchOrderState>('fetchOrderState');

export const getFetchOrderData = createSelector(
	getOrderState,
	(state: FetchOrderState) => state.fetchOrderListData.data
);

export const getOrderData = createSelector(
	getOrderState,
	(state: FetchOrderState) => state.getOrderListData.data
);
