// interface
export interface RequestState {
	isFetch: boolean,
	isError: boolean,
	error: string
}


// Handler
export function initRequestState(): RequestState {
	return {
		isFetch: false,
		isError: false,
		error: ''
	}
};