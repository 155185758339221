import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { ProductItem } from "@app/helper/model";


@Component({
	selector: 'promo-product-item',
	templateUrl: './promo-product-item.component.html',
	styleUrls: ['./promo-product-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class PromoProductItemComponent {

	// props
	@Input() public productOption: ProductItem;
	@Input() public fullSize: boolean;

	constructor() {
		// init state
		this.productOption = {
			imgSrc: '',
			brand: '',
			title: '',
			oldPrice: '',
			currentPrice: '',
			currency: '',
			slug: ''
		}
		this.fullSize = false;
	}
}
