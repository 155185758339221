import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestState, initRequestState } from '@store/store.shared';


// Interface
export interface VariationItem {
	id: number,
	product_id: number,
	availability: 'in_stock' | 'out_of_stock' | 'preorder',
	availability_date: string,
	availability_days: number,
	available_quantity: number
	bulk_prices: Array<{
		price: number,
		quantity: number
	}>,
	created_date: string,
	edited_date: string,
	features: Array<{
		attribute_id: number
		attribute_name: string
		attribute_option_id: number
		attribute_option_name: string
	}>,
	images: Array<{
		url: string,
		is_main: boolean
	}>,
	minimum_order: number,
	name: string,
	price: number,
	sku: string,
	slug: string
};

export interface ProductActive {
	id: number,
	product_id: number,
	name: string,
	images: Array<{
		url: string,
		is_main: boolean
	}>,
	description: string,
	price: number,
	currency: string,
	available_quantity: number,
	minimum_order: number,
	bulk_prices: Array<{
		price: number,
		quantity: number
	}>,
	show_bulk_pricing: boolean,
	has_sample: boolean,
	sample_price: number,
	unit: string,
	unit_in_package: string,
	quantity_in_one_package: number,
	availability: 'in_stock' | 'out_of_stock' | 'preorder',
	availability_date: string,
	availability_days: number,
	max_quantity: number,
	min_quantity: number,
	slug: string,
	trademark: {
		id: number,
		brand_logo: string,
		brand_name: string,
		is_verified: boolean
	} | null,
	certifications: Array<{
		id: number,
		certificate_id: number,
		site_url: string,
		is_verified: boolean,
		document_verification: Array<string>,
		created_at: string,
		certificate: {
			id: number,
			name: string,
			website: string,
			type: string,
			description: string,
			logo: string
		} | null
	}>,
	features: Array<{
		attribute_id: number,
		name: string,
		options_list: Array<{
			id: number,
			attribute_id: number,
			name: string
		}>
	}>,
	categories: Array<{
		full_name: string
		id: number
		level: number,
		slug: string
	}>,
	variations: Array<VariationItem>,
	high_level_categories: Array<{
		id: number
		name: string
		slug: string
	}>
};

export interface ProductLogistic {
	id: number,
	sku: string,
	hs_classifier: string,
	place_of_origin: string,
	warehouse_locations: Array<{
		country: string,
		city: string,
		port: string,
		terminal: string
	}>,
	freight_size: {
		width: string,
		height: string,
		length: string,
		length_unit: string,
		weight: string,
		weight_unit: string
	} | null,
	sample_size: {
		width: string,
		height: string,
		length: string,
		length_unit: string,
		weight: string,
		weight_unit: string
	} | null
};

export interface ProductCompany {
	id: number,
	name: string,
	description: string,
	is_verified: boolean,
	logo: string,
	sub_domain: string,
	business_type: {
		id: number,
		type: string,
		slug: string,
		name: string,
	} | null,
	registration_number: string,
	annual_revenue: string,
	year_established: number,
	number_of_employees: string,
	trademarks: Array<{
		id: number,
		company_id: number,
		brand_logo: string,
		brand_name: string,
		verification_link: string,
		is_verified: boolean,
		created_at: string,
		updated_at: string,
		verification_documents: Array<string>
	}>,
	time_zone: string,
	certificates: Array<{
		id: number,
		certificate_id: number,
		site_url: string,
		is_verified: boolean,
		document_verification: Array<string>,
		created_at: string,
		certificate: {
			id: number,
			name: string,
			website: string,
			type: string,
			description: string,
			logo: string
		} | null
	}>,
	economic_empowerment: {
		women_owned_is_verified: boolean,
		refugee_owned_is_verified: boolean,
		small_business_is_verified: boolean
	},
	office_address: Array<{
		address: string,
		city: {
			id: number,
			name: string
		},
		contacts: Array<{
			id: number,
			is_verified: boolean,
			type: string,
			value: string
		}>,
		country: {
			id: number,
			name: string
		},
		id: number,
		zip_code: string
	}>
};

export interface ProductSlider {
	imgSrc: string,
	brand: string,
	title: string,
	oldPrice: number | string,
	currentPrice: number | string,
	currency: string,
	slug: string
};


// State
export interface ProductState {
	productActive: {
		requestState: RequestState,
		data: ProductActive,
		quantity: number,
		currentFeatures: Array<{
			attribute_name: string,
			attribute_option_name: string
		}>
	},
	productLogistic: {
		requestState: RequestState,
		data: ProductLogistic
	},
	productCompany: {
		requestState: RequestState,
		data: ProductCompany
	},
	productSliderSeller: {
		requestState: RequestState,
		data: Array<ProductSlider>
	},
	productSliderViewed: {
		requestState: RequestState,
		data: Array<ProductSlider>
	}
};

const INITIAL_STATE: ProductState = {
	productActive: {
		requestState: initRequestState(),
		data: undefined,
		quantity: null,
		currentFeatures: []
	},
	productLogistic: {
		requestState: initRequestState(),
		data: undefined
	},
	productCompany: {
		requestState: initRequestState(),
		data: undefined
	},
	productSliderSeller: {
		requestState: initRequestState(),
		data: undefined
	},
	productSliderViewed: {
		requestState: initRequestState(),
		data: undefined
	}
};


// Action
export const ProductActions = {
	updateProductActiveQuantity: '[Product] Update_Product_Active_Quantity',
	updateProductActiveCurrentFeatures: '[Product] Update_Product_Active_Current_Features',

	fetchProductActive: '[Product] Fetch_Product_Active',
	fetchProductActiveSuccess: '[Product] Fetch_Product_Active_Success',
	fetchProductActiveError: '[Product] Fetch_Product_Active_Error',

	fetchProductLogistic: '[Product] Fetch_Product_Logistic',
	fetchProductLogisticSuccess: '[Product] Fetch_Product_Logistic_Success',
	fetchProductLogisticError: '[Product] Fetch_Product_Logistic_Error',

	fetchProductCompany: '[Product] Fetch_Product_Company',
	fetchProductCompanySuccess: '[Product] Fetch_Product_Company_Success',
	fetchProductCompanyError: '[Product] Fetch_Product_Company_Error',

	fetchProductSliderSeller: '[Product] Fetch_Product_Slider_Seller',
	fetchProductSliderSellerSuccess: '[Product] Fetch_Product_Slider_Seller_Success',
	fetchProductSliderSellerError: '[Product] Fetch_Product_Slider_Seller_Error',

	fetchProductSliderViewed: '[Product] Fetch_Product_Slider_Viewed',
	fetchProductSliderViewedSuccess: '[Product] Fetch_Product_Slider_Viewed_Success',
	fetchProductSliderViewedError: '[Product] Fetch_Product_Slider_Viewed_Error',
};

export class UpdateProductActiveQuantity implements Action {
	readonly type = ProductActions.updateProductActiveQuantity;
	constructor(public payload: number) { }
};

export class UpdateProductActiveCurrentFeatures implements Action {
	readonly type = ProductActions.updateProductActiveCurrentFeatures;
	constructor(public payload: Array<{attribute_name: string, attribute_option_name: string}>) { }
};

export class FetchProductActive implements Action {
	readonly type = ProductActions.fetchProductActive;
	constructor(public payload: { productSlug: string }) { }
};

export class FetchProductActiveSuccess implements Action {
	readonly type = ProductActions.fetchProductActiveSuccess;
	constructor(public payload: ProductActive) { }
};

export class FetchProductActiveError implements Action {
	readonly type = ProductActions.fetchProductActiveError;
	constructor(public payload: any) { }
};

export class FetchProductLogistic implements Action {
	readonly type = ProductActions.fetchProductLogistic;
	constructor(public payload: { productSlug: string }) { }
};

export class FetchProductLogisticSuccess implements Action {
	readonly type = ProductActions.fetchProductLogisticSuccess;
	constructor(public payload: ProductLogistic) { }
};

export class FetchProductLogisticError implements Action {
	readonly type = ProductActions.fetchProductLogisticError;
	constructor(public payload: any) { }
};

export class FetchProductCompany implements Action {
	readonly type = ProductActions.fetchProductCompany;
	constructor(public payload: { productSlug: string }) { }
};

export class FetchProductCompanySuccess implements Action {
	readonly type = ProductActions.fetchProductCompanySuccess;
	constructor(public payload: ProductCompany) { }
};

export class FetchProductCompanyError implements Action {
	readonly type = ProductActions.fetchProductCompanyError;
	constructor(public payload: any) { }
};

export class FetchProductSliderSeller implements Action {
	readonly type = ProductActions.fetchProductSliderSeller;
	constructor(public payload: {id: number}) { }
};

export class FetchProductSliderSellerSuccess implements Action {
	readonly type = ProductActions.fetchProductSliderSellerSuccess;
	constructor(public payload: Array<ProductSlider>) { }
};

export class FetchProductSliderSellerError implements Action {
	readonly type = ProductActions.fetchProductSliderSellerError;
	constructor(public payload: any) { }
};

export class FetchProductSliderViewed implements Action {
	readonly type = ProductActions.fetchProductSliderViewed;
	constructor(public payload: {id: number}) { }
};

export class FetchProductSliderViewedSuccess implements Action {
	readonly type = ProductActions.fetchProductSliderViewedSuccess;
	constructor(public payload: Array<ProductSlider>) { }
};

export class FetchProductSliderViewedError implements Action {
	readonly type = ProductActions.fetchProductSliderViewedError;
	constructor(public payload: any) { }
};


// Reducer
export function ProductReducer(state = INITIAL_STATE, action: Action) {
	switch (action.type) {
		case ProductActions.updateProductActiveQuantity: {
			let payload = (action as UpdateProductActiveQuantity).payload;
			return {
				...state,
				productActive: {
					...state.productActive,
					quantity: payload
				}
			}
		}; break;

		case ProductActions.updateProductActiveCurrentFeatures: {
			let payload = (action as UpdateProductActiveCurrentFeatures).payload;
			return {
				...state,
				productActive: {
					...state.productActive,
					currentFeatures: payload
				}
			}
		}; break;

		case ProductActions.fetchProductActive: {
			return {
				...state,
				productActive: {
					...state.productActive,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined,
					quantity: null,
					currentFeatures: []
				}
			}
		}; break;
		case ProductActions.fetchProductActiveSuccess: {
			let payload = (action as FetchProductActiveSuccess).payload;
			return {
				...state,
				productActive: {
					...state.productActive,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload,
					quantity: payload.min_quantity
				}
			}
		}; break;
		case ProductActions.fetchProductActiveError: {
			let payload = (action as FetchProductActiveError).payload;
			return {
				...state,
				productActive: {
					...state.productActive,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		case ProductActions.fetchProductLogistic: {
			return {
				...state,
				productLogistic: {
					...state.productLogistic,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case ProductActions.fetchProductLogisticSuccess: {
			let payload = (action as FetchProductLogisticSuccess).payload;
			return {
				...state,
				productLogistic: {
					...state.productLogistic,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case ProductActions.fetchProductLogisticError: {
			let payload = (action as FetchProductLogisticError).payload;
			return {
				...state,
				productLogistic: {
					...state.productLogistic,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		case ProductActions.fetchProductCompany: {
			return {
				...state,
				productCompany: {
					...state.productCompany,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case ProductActions.fetchProductCompanySuccess: {
			let payload = (action as FetchProductCompanySuccess).payload;
			return {
				...state,
				productCompany: {
					...state.productCompany,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case ProductActions.fetchProductCompanyError: {
			let payload = (action as FetchProductCompanyError).payload;
			return {
				...state,
				productCompany: {
					...state.productCompany,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		case ProductActions.fetchProductSliderSeller: {
			return {
				...state,
				productSliderSeller: {
					...state.productSliderSeller,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case ProductActions.fetchProductSliderSellerSuccess: {
			let payload = (action as FetchProductSliderSellerSuccess).payload;
			return {
				...state,
				productSliderSeller: {
					...state.productSliderSeller,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case ProductActions.fetchProductSliderSellerError: {
			let payload = (action as FetchProductSliderSellerError).payload;
			return {
				...state,
				productSliderSeller: {
					...state.productSliderSeller,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		case ProductActions.fetchProductSliderViewed: {
			return {
				...state,
				productSliderViewed: {
					...state.productSliderViewed,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case ProductActions.fetchProductSliderViewedSuccess: {
			let payload = (action as FetchProductSliderViewedSuccess).payload;
			return {
				...state,
				productSliderViewed: {
					...state.productSliderViewed,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case ProductActions.fetchProductSliderViewedError: {
			let payload = (action as FetchProductSliderViewedError).payload;
			return {
				...state,
				productSliderViewed: {
					...state.productSliderViewed,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		default: {
			return state;
		}
	}
};


// Getter
export const getProductState = createFeatureSelector<ProductState>('productState');

export const getProductActiveQuantity = createSelector(
	getProductState,
	(state: ProductState) => state.productActive.quantity
);

export const getProductCurrentFeatures = createSelector(
	getProductState,
	(state: ProductState) => state.productActive.currentFeatures
);

export const getProductActiveData = createSelector(
	getProductState,
	(state: ProductState) => state.productActive.data
);

export const getProductActiveState = createSelector(
	getProductState,
	(state: ProductState) => state.productActive.requestState
);

export const getProductCompanyData = createSelector(
	getProductState,
	(state: ProductState) => state.productCompany.data
);

export const getProductLogisticData = createSelector(
	getProductState,
	(state: ProductState) => state.productLogistic.data
);

export const getProductSliderSellerData = createSelector(
	getProductState,
	(state: ProductState) =>  state.productSliderSeller.data
);

export const getProductSliderViewedData = createSelector(
	getProductState,
	(state: ProductState) => state.productSliderViewed.data
);