import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name: 'textPreview',
	pure: true
})

export class TextPreviewPipe implements PipeTransform {
	// The default string length is 250 characters.
	transform( str: string, leng?: number ): string {
		let _leng = leng || 250;
		if (str === null) {
			return '';
		}
		if ( str.length > _leng ) {
			let _end = _leng - 3;
			return str.slice(0, _end) + '...';
		}
		else {
			return str;
		}
	}
}