const cookieNamePrefix = 'dev_';

export const env = {
	production: false,
	stagging: false,
	local: true,
	apiUrl: `https://tradalaxy-dev-back.brights.io/api/v1/`,
	marketplaceUrl: 'tradalaxy-dev-marketplace.brights.io',
	marketplaceSubDomain: 'tradalaxy-dev-marketplace',
	apiMarketplaceUrl: `https://tradalaxy-dev-back.brights.io/api/v1/marketplace/`,
	backendUrl: `https://tradalaxy-dev-back.brights.io`,
	blogUrl: 'http://localhost:4201',
	landingUrl: 'http://159.65.57.74',
	tradalaxyUrl: 'http://localhost:8000',
	helpCenterUrl: 'http://localhost:4202',
	mainCookiesDomain: 'localhost',
	cookieTokenName: cookieNamePrefix + 'token',
	cookieLanguagesName: cookieNamePrefix + 'languages',
	cookieMainLangName:  cookieNamePrefix + 'main_lang',
};

import 'zone.js/dist/zone-error';  // Included with Angular CLI.