import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import * as AppStore from '@store/app.store';
import {
	OrderListOption,
	getOrderListDataItem,
	ToggleModalVisible,
	SetActiveOrder,
	geOrderSubtotal,
	getOrderListCount,
	OrderSubtotal,
	getOrderModalVisible,
	OrderCurrency,
	DeleteOrderListItem
} from "@store/order/order.state";


export interface ConfirmDeleteItem {
	company_id: number,
	product_id: number,
	product_name: string,
	currency: OrderCurrency
}


@Component({
	selector: 'order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['./order-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderListComponent {

	@Input() public orderListOption: Array<OrderListOption>;
	@Input() public totalVisible: boolean;

	@Input() public notificationTitle: boolean;

	public confirmModal$: BehaviorSubject<boolean>;
	private orderListCount$: Observable<number>;

	public confirmDeleteItem: ConfirmDeleteItem;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private router: Router) {
			// init state
			this.totalVisible = true;
			this.confirmModal$ = new BehaviorSubject(false);

			this.orderListCount$ = this.store.select(getOrderListCount);
		}

	getOrderListDataItem(orderOption: OrderListOption): Observable<OrderSubtotal> {
		return this.store.select(getOrderListDataItem(orderOption.order_company.id, orderOption.order_currency.code)).pipe(map(data => {
			return geOrderSubtotal(data);
		}));
	}

	continueShopping(): void {
		this.store.dispatch(new ToggleModalVisible(false));
	}

	makeOrder(orderOption: OrderListOption): void {
		this.store.dispatch(new ToggleModalVisible(false));
		this.store.dispatch(new SetActiveOrder({company_id: orderOption.order_company.id, order_currency: orderOption.order_currency}));
		this.router.navigate(['/order']);
	}

	showConfirmModal(item: ConfirmDeleteItem): void {
		this.confirmDeleteItem = item;
		this.confirmModal$.next(true);
	}

	confirmDelete(): void {
		this.confirmModal$.next(false);
		this.orderListCount$.subscribe(val => {
			if (val === 1) {
				this.store.select(getOrderModalVisible).subscribe(visible => {
					if (visible) {
						this.store.dispatch(new ToggleModalVisible(false));
					}
				}).unsubscribe();
			}
		}).unsubscribe();

		this.store.dispatch(new DeleteOrderListItem({
			company_id: this.confirmDeleteItem.company_id,
			product_id: this.confirmDeleteItem.product_id,
			currency: this.confirmDeleteItem.currency
		}));
		this.confirmDeleteItem = undefined;
	}

	unConfirmDelete(): void {
		this.confirmModal$.next(false);
		this.confirmDeleteItem = undefined;
	}
}