export function saveDataLocalStorage(key: string, data: any): void {
	let type = typeof data;
	switch(type) {
		case 'object': {
			window.localStorage.setItem(key, JSON.stringify(data));
		}; break;
		default: {
			window.localStorage.setItem(key, data);
		}
	}
};

export function getDataLocalStorage(key: string, type?: 'string' | 'number' | 'object' | 'boolean'): any {
	let data = window.localStorage.getItem(key);
	if (data === 'null') {
		return null;
	}
	else if (data === 'undefined') {
		return undefined;
	}
	else {
		let _type = (type !== undefined) ? type : 'string';
		switch(_type) {
			case 'string': {
				return data;
			}; break;
			case 'number': {
				return parseFloat(data);
			}; break;
			case 'object': {
				return JSON.parse(data);
			}; break;
			case 'boolean': {
				return Boolean(data);
			}; break;
			default: {
				return data;
			}
		}
	}
};

export function toNumber(val: any): number | null {
	return (val !== null) ? parseFloat(val) : null;
};

export function getPrePaymentList(currency: string): Array<{value: {id: number, key: string}, label: string}> {
	return [
		{
			value: {
				id: 1,
				key: 'currency'
			},
			label: currency
		},
		{
			value: {
				id: 2,
				key: 'percent',
			},
			label: '%'
		}
	];
};

export function isMainImage(imageArray: Array<{url: string, is_main: boolean}>) {

	if(imageArray.length !== 0) {
		let image = imageArray.filter(img => img.is_main === true);
		return image[0].url || null
	} else {
		return null;
	}

}

export function wrapperScrollTop() {
	const wrapper = document.querySelector('.app-wrapper');
	if (wrapper) {
		wrapper.scrollTo({
			top: 0,
			behavior: "auto"
		})
	}
}