import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ProductItem } from "@app/helper/model";
import { BehaviorSubject } from "rxjs";
import { CategoryForDropdown } from '@app/store/company-page.model';
import { TranslateService } from '@ngx-translate/core';
import { environment, IEnv } from '@env/environment';

type SelectedSort = { label: string; value: "price" | "name" };

@Component({
	selector: "promo-products-list",
	templateUrl: "./promo-products-list.component.html",
	styleUrls: ["./promo-products-list.component.scss"]
})

export class PromoProductsList implements OnInit {
	@Input() productsData: ProductItem[] = [];
	@Input() hasAdvertise: boolean = true;
	@Input() withTitleSelect: boolean = true;
	@Input()
	get categoryList () {
		return this._categoryList;
	}
	set categoryList ( value: CategoryForDropdown[] ) {
		this._categoryList = value;
		if( value && value.length ) this.categorySelected$ = new BehaviorSubject(value[0]);
	}
	@Input() withSortBy: boolean = false;
	@Input() title: string = null;
	@Output() emitCategory: EventEmitter<CategoryForDropdown>;
	@Output() emitSortItem: EventEmitter<SelectedSort>;

	private _categoryList: CategoryForDropdown[] = [];
	public categorySelectIsOpened$: BehaviorSubject<boolean>;
	public categorySelected$: BehaviorSubject<CategoryForDropdown>;

	public sortSelectIsOpened$: BehaviorSubject<boolean>;
	public selectedSort$: BehaviorSubject<SelectedSort>;
	public sortList: SelectedSort[];
	public environment: IEnv;
	constructor(private translateService: TranslateService) {
		this.emitCategory = new EventEmitter();
		this.emitSortItem = new EventEmitter();
		this.categorySelectIsOpened$ = new BehaviorSubject(false);
		this.selectedSort$ = new BehaviorSubject(null);
		this.translateService.get([
			"promo_products_list.alphabet",
			"promo_products_list.price"
		]).subscribe( data => {
			this.selectedSort$ = new BehaviorSubject({
				label: data["promo_products_list.alphabet"],
				value: "name"
			});
			this.sortSelectIsOpened$ = new BehaviorSubject(false);
			this.sortList = [
				{
					label: data["promo_products_list.alphabet"],
					value: "name"
				},
				{
					label: data["promo_products_list.price"],
					value: "price"
				}
			];
			this.categorySelected$ = new BehaviorSubject(null);
		});

		this.environment = environment;
	}

	ngOnInit(): void {}

	onSelectCategory(category: CategoryForDropdown) {
		this.categorySelectIsOpened$.next(false);
		this.categorySelected$.next(category);

		this.emitCategory.emit(category);
	}

	onSelectSort(sortItem: SelectedSort) {
		this.sortSelectIsOpened$.next(false);
		this.selectedSort$.next(sortItem);
		this.emitSortItem.emit(sortItem);
	}

	closeList() {
		this.categorySelectIsOpened$.next(false);
		this.sortSelectIsOpened$.next(false);
	}
}
