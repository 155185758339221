import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestState, initRequestState } from '@store/store.shared';


// Interface
export interface UserSignInData {
	email: string,
	password: string
};

export interface UserSignUpData {
	user: {
		email: string,
		first_name: string,
		password: string,
		last_name: string,
		agree_terms: boolean,
		agree_privacy: boolean
	},
	company: {
		name: string,
		country_id: string,
		phone: string,
		business_type_id: number,
		interested_in_id: number,
		high_level_categories: Array<number>
	}
};

export interface UserCompany {
    id: number,
    name: string
};

export interface UserData {
	id: number,
    email: string,
    phone: string,
    avatar: string,
    country_id: number,
    time_zone: string,
    first_name: string,
    last_name: string,
    companies: Array<UserCompany>
};

export interface UserCountry {
	id: number,
	code: string,
	time_zone: string
};


// State
export interface UserState {
	isGuest: boolean,
	isLogged: boolean,
	userData: UserData,
	userCountry: UserCountry,
	signInRequestState: RequestState,
	signUpRequestState: RequestState,
	userDataRequestState: RequestState,
	userCountryRequestState: RequestState
};

const INITIAL_STATE: UserState = {
	isGuest: false,
	isLogged: false,
	userData: undefined,
	userCountry: undefined,
	signInRequestState: initRequestState(),
	signUpRequestState: initRequestState(),
	userDataRequestState: initRequestState(),
	userCountryRequestState: initRequestState()
};


// Action
export const UserActions = {
	userIsGuest: '[User] User_Is_Guest',
	userLogout: '[User] User_Logout',

	signIn: '[User] Sign_In',
	signInSuccess: '[User] Sign_In_Succes',
	signInError: '[User] Sign_In_Error',

	signUp: '[User] Sign_Up',
	signUpSuccess: '[User] Sign_Up_Succes',
	signUpError: '[User] Sign_Up_Error',

	userData: '[User] User_Data',
	userDataSuccess: '[User] User_Data_Success',
	userDataError: '[User] User_Data_Error',

	userCountry: '[User] Country',
	userCountrySuccess: '[User] User_Country_Success',
	userCountryError: '[User] User_Country_Error',

	forgotPassword: '[User] Forgot_Password',
	forgotPasswordSuccess: '[User] Forgot_Password_Success',
	forgotPasswordError: '[User] Forgot_Password_Error'
};

export class UserIsGuest implements Action {
	readonly type = UserActions.userIsGuest;
	constructor(public payload: boolean) { }
};

export class UserLogout implements Action {
	readonly type = UserActions.userLogout;
	constructor() { }
};

export class SignIn implements Action {
	readonly type = UserActions.signIn;
	constructor(public payload: UserSignInData) { }
};

export class SignInSuccess implements Action {
	readonly type = UserActions.signInSuccess;
	constructor(public payload: UserData) { }
};

export class SignInError implements Action {
	readonly type = UserActions.signInError;
	constructor(public payload: any) { }
};

export class SignUp implements Action {
	readonly type = UserActions.signUp;
	constructor(public payload: UserSignUpData) { }
};

export class SignUpSuccess implements Action {
	readonly type = UserActions.signUpSuccess;
	constructor(public payload: UserData) { }
};

export class SignUpError implements Action {
	readonly type = UserActions.signUpError;
	constructor(public payload: any) { }
};

export class UserDataFetch implements Action {
	readonly type = UserActions.userData;
	constructor(public payload: {id: number}) { }
};

export class UserDataSuccess implements Action {
	readonly type = UserActions.userDataSuccess;
	constructor(public payload: UserData) { }
};

export class UserDataError implements Action {
	readonly type = UserActions.userDataError;
	constructor(public payload: any) { }
};

export class UserCountryFetch implements Action {
	readonly type = UserActions.userCountry;
	constructor() { }
};

export class UserCountrySuccess implements Action {
	readonly type = UserActions.userCountrySuccess;
	constructor(public payload: UserCountry) { }
};

export class UserCountryError implements Action {
	readonly type = UserActions.userCountryError;
	constructor(public payload: any) { }
};

export class ForgotPassword implements Action {
	readonly type = UserActions.forgotPassword;
	constructor(public payload: string) { }
};

export class ForgotPasswordSuccess implements Action {
	readonly type = UserActions.forgotPasswordSuccess;
	constructor() { }
};

export class ForgotPasswordError implements Action {
	readonly type = UserActions.forgotPasswordError;
	constructor(public payload: any) { }
};


// Reducer
export function UserReducer(state = INITIAL_STATE, action: Action) {
	switch (action.type) {

		case UserActions.userLogout: {
			return {
				...state,
				isLogged: false,
				userData: undefined
			}
		}; break;

		case UserActions.userIsGuest: {
			let payload = (action as UserIsGuest).payload;
			return {
				...state,
				isGuest: payload
			}
		}; break;

		case UserActions.signIn: {
			return {
				...state,
				signInRequestState: {
					isFetch: true,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.signInSuccess: {
			let payload = (action as SignInSuccess).payload;
			return {
				...state,
				userData: payload,
				isLogged: true,
				signInRequestState: {
					isFetch: false,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.signInError: {
			let payload = (action as SignInError).payload;
			return {
				...state,
				signInRequestState: {
					isFetch: false,
					isError: true,
					error: payload
				}
			}
		}; break;

		case UserActions.signUp: {
			return {
				...state,
				signUpRequestState: {
					isFetch: true,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.signUpSuccess: {
			let payload = (action as SignUpSuccess).payload;
			return {
				...state,
				userData: payload,
				isLogged: true,
				signUpRequestState: {
					isFetch: false,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.signUpError: {
			let payload = (action as SignUpError).payload;
			return {
				...state,
				signUpRequestState: {
					isFetch: false,
					isError: true,
					error: payload
				}
			}
		}; break;

		case UserActions.userData: {
			return {
				...state,
				userDataRequestState: {
					isFetch: true,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.userDataSuccess: {
			let payload = (action as UserDataSuccess).payload;
			return {
				...state,
				isLogged: payload ? true : false,
				userData: payload,
				userDataRequestState: {
					isFetch: false,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.signUpError: {
			let payload = (action as UserDataError).payload;
			return {
				...state,
				userDataRequestState: {
					isFetch: false,
					isError: true,
					error: payload
				}
			}
		}; break;

		case UserActions.userCountry: {
			return {
				...state,
				userCountryRequestState: {
					isFetch: true,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.userCountrySuccess: {
			let payload = (action as UserCountrySuccess).payload;
			return {
				...state,
				userCountry: payload,
				userCountryRequestState: {
					isFetch: false,
					isError: false,
					error: ''
				}
			}
		}; break;
		case UserActions.userCountryError: {
			let payload = (action as UserCountryError).payload;
			return {
				...state,
				userCountryRequestState: {
					isFetch: false,
					isError: true,
					error: payload
				}
			}
		}; break;

		default: {
			return state;
		}
	}
};


// Getter
export const getUserState = createFeatureSelector<UserState>('userState');

export const getUserIsGuest = createSelector(
	getUserState,
	(state: UserState) => state.isGuest
);

export const getUserIsLogged = createSelector(
	getUserState,
	(state: UserState) => state.isLogged
);

export const getUserData = createSelector(
	getUserState,
	(state: UserState) => state.userData
);

export const getUserCountry = createSelector(
	getUserState,
	(state: UserState) => state.userCountry
);

export const getUserCountryRequestState = createSelector(
	getUserState,
	(state: UserState) => state.userCountryRequestState
);