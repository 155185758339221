import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getTradeInfoData, FetchTradeInfoData, getCompanyBaseData } from '@app/store/company/company.state';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { WorldMapDataItem } from '@app/helper/model';
import * as am4core from "@amcharts/amcharts4/core";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { AppService } from '@app/service/app-service.service';
import { wrapperScrollTop } from '@app/helper/common';


type TradeType = 'sales' | 'purchasing' | 'both';

interface TabDataItem {
	flag_id: string,
	country_id: number,
	country_name: string,
	percentage: number
};

interface CapacityItem {
	country_id: number,
    percentage: string,
    country: {
        iso: string,
        iso3: string,
        name: string
    }
};

// helper
let toNumberHelper = (val: string | number): number => {
	if (val === null || val === '') {
		return 0;
	}
	else if (typeof val === 'number') {
		return val;
	}
	else if (typeof val === 'string') {
		let _val = val.replace(',', '.');
		return parseFloat(_val);
	}
	else {
		console.warn('Invalid property toNumberHelper');
		return val;
	}
}

@Component({
    selector: 'trade-info-tab',
    templateUrl: './trade-info-tab.component.html',
    styleUrls: ['./trade-info-tab.component.scss']
})

export class TradeInfoTab implements OnInit, AfterViewInit, OnDestroy {

    private pageSubscriptions: Subscription;
	public tradeType$: BehaviorSubject<TradeType>;
	public internalMarketPercent$: BehaviorSubject<number>;
	public externalMarketPercent$: BehaviorSubject<number>;
	public tabData$: BehaviorSubject<Array<TabDataItem>>;
    public mapData$: BehaviorSubject<Array<WorldMapDataItem>>;

    constructor(private store: Store<AppStore.AppStoreState>, public appService: AppService) {

		this.pageSubscriptions = new Subscription();
		this.tradeType$ = new BehaviorSubject(undefined);
		this.externalMarketPercent$ = new BehaviorSubject(0);
		this.internalMarketPercent$ = new BehaviorSubject(0);
		this.tabData$ = new BehaviorSubject([]);
		this.mapData$ = new BehaviorSubject([]);

		this.pageSubscriptions.add(
			combineLatest(
				this.store.select(getTradeInfoData),
				this.store.select(getCompanyBaseData)
			).subscribe(val => {
				if (!val[0] || !val[1]) {
					return;
				}

				let data = val[0];
				let external_market_percent = 0;
				let internal_market_percent = 0;
				let tradeType: TradeType;
				let capacity: Array<TabDataItem> = [];

				let getCapacityList = (listData: Array<CapacityItem>, type: TradeType): Array<TabDataItem> => {
					let typeCoeficient = (type === 'both') ? 2 : 1;
					let capacity: Array<TabDataItem> = [];
					listData.forEach(z => {
						let capacityIndex = capacity.findIndex(x => x.country_id === z.country_id);
						if (capacityIndex === -1) {
							capacity.push(
								{
									flag_id: z.country.iso.toLowerCase(),
									country_id: z.country_id,
									country_name: z.country.name,
									percentage: toNumberHelper(z.percentage) / typeCoeficient
								}
							)
						}
						else {
							capacity[capacityIndex].percentage += (toNumberHelper(z.percentage) / typeCoeficient);
						}
					});
					return capacity;
				};

				if (data.export_trade_capacity.export && data.export_trade_capacity.import) {
					external_market_percent = (toNumberHelper(data.export_trade_capacity.export.external_market_percent) + toNumberHelper(data.export_trade_capacity.import.external_market_percent)) / 2;
					internal_market_percent = (toNumberHelper(data.export_trade_capacity.export.internal_market_percent) + toNumberHelper(data.export_trade_capacity.import.internal_market_percent)) / 2;

					tradeType = 'both';
					capacity = getCapacityList([...data.export_trade_capacity.export.capacity, ...data.export_trade_capacity.import.capacity], tradeType);
				}
				else if (data.export_trade_capacity.export && !data.export_trade_capacity.import) {
					external_market_percent = toNumberHelper(data.export_trade_capacity.export.external_market_percent);
					internal_market_percent = toNumberHelper(data.export_trade_capacity.export.internal_market_percent);

					tradeType = 'purchasing';
					capacity = getCapacityList(data.export_trade_capacity.export.capacity, tradeType);
				}
				else if (!data.export_trade_capacity.export && data.export_trade_capacity.import) {
					external_market_percent = toNumberHelper(data.export_trade_capacity.import.external_market_percent);
					internal_market_percent = toNumberHelper(data.export_trade_capacity.import.internal_market_percent);

					tradeType = 'sales';
					capacity = getCapacityList(data.export_trade_capacity.import.capacity, tradeType);
				}

				this.externalMarketPercent$.next(external_market_percent);
				this.internalMarketPercent$.next(internal_market_percent);
				this.tradeType$.next(tradeType);
				this.tabData$.next(capacity);

				this.addCountriesToMap(capacity);
			})
        );
	}

	addCountriesToMap(data: Array<TabDataItem>) {
		let companyCountryId: string;
		try {
			this.store.select(getCompanyBaseData).subscribe(val => {
				if (val && val.office_address[0]) {
					companyCountryId = val.office_address[0].country.code.toLowerCase();
				}
			})
		}
		catch(error) {
			console.log("Error --> addCountriesToMap companyCountryId", error);
		}

		let countries = am4geodata_worldLow.features.map(item => {
			let properties = item.properties;
			let matched = data.find(z => z.flag_id === properties.id.toLocaleLowerCase());
			let companyCountry = companyCountryId === properties.id.toLocaleLowerCase();

			let country: WorldMapDataItem = {
				id: properties.id,
                flagId: properties.id.toLocaleLowerCase(),
                name: properties.name,
                fill: (matched) ? am4core.color('#346753') : (companyCountry) ? am4core.color('#CFB14F') : am4core.color('#EFF1F0'),
                percentage: (matched) ? matched.percentage.toString() : null,
                tooltipHtml: `
                    <div class="external-country-flag">
                        <span class="flag-icon flag-icon-${ properties.id.toLocaleLowerCase() }"></span>
                    </div>
                    <div class="external-country-name">${ properties.name }</div>
                    <div class="external-country-perventage">${(matched) ? `${matched.percentage}%` : ``}</div>
                `
			};
			return country;
		});

		this.mapData$.next(countries);
    }

	ngAfterViewInit() {
		wrapperScrollTop();
	}

    ngOnInit(): void {
        this.store.dispatch(new FetchTradeInfoData(null));
    }

    ngOnDestroy() {
        this.pageSubscriptions.unsubscribe();
    }
}
