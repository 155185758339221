const cookieNamePrefix = 'dev_';

export const env = {
	production: false,
	stagging: false,
	development: true,
	apiUrl: `https://tradalaxy-dev-back.brights.io/api/v1/`,
	marketplaceUrl: 'tradalaxy-dev-marketplace.brights.io',
	apiMarketplaceUrl: `https://tradalaxy-dev-back.brights.io/api/v1/marketplace/`,
	marketplaceSubDomain: 'tradalaxy-dev-marketplace',
	tradalaxyUrl: 'https://tradalaxy-dev-platform.brights.io',
	blogUrl: 'https://tradalaxy-dev-blog.brights.io',
	helpCenterUrl: 'https://tradalaxy-dev-help-center.brights.io/',
	landingUrl: 'https://tradalaxy-landing.brights.io',
	mainCookiesDomain: 'dev.tradalaxy.com',
	cookieTokenName: cookieNamePrefix + 'token',
	cookieLanguagesName: cookieNamePrefix + 'languages',
	cookieMainLangName:  cookieNamePrefix + 'main_lang',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
