import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Component
import { UiKitTabsComponent } from "./uikit-tabs/uikit-tabs.component";
import { UiKitTabComponent } from "./uikit-tab/uikit-tab.component";


@NgModule({
	imports: [
		CommonModule
	],
    declarations: [
		UiKitTabsComponent,
		UiKitTabComponent
	],
	exports: [
		UiKitTabsComponent,
		UiKitTabComponent
	]
})

export class UiKitTabModule { }
