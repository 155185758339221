import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";


declare let require: any;
const SVG_ICON = {
	'arrow_up_right': require('!!raw-loader?!../../../../assets/icon/arrow_up_right.svg'),
	'facebook_min': require('!!raw-loader?!../../../../assets/icon/facebook_min.svg'),
	'instagram_min': require('!!raw-loader?!../../../../assets/icon/instagram_min.svg'),
	'linkedin_min': require('!!raw-loader?!../../../../assets/icon/linkedin_min.svg'),
	'message': require('!!raw-loader?!../../../../assets/icon/message.svg'),
	'notification': require('!!raw-loader?!../../../../assets/icon/notification.svg'),
	'home': require('!!raw-loader?!../../../../assets/icon/home.svg'),
	'help': require('!!raw-loader?!../../../../assets/icon/help.svg'),
	'basket': require('!!raw-loader?!../../../../assets/icon/basket.svg'),
	'language': require('!!raw-loader?!../../../../assets/icon/language.svg'),
	'close': require('!!raw-loader?!../../../../assets/icon/close.svg'),
	'menu': require('!!raw-loader?!../../../../assets/icon/menu.svg'),
};

@Component({
	selector: 'svg-icon',
	templateUrl: './svg-icon.component.html',
	styleUrls: ['./svg-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SvgIconComponent {

	// props
	@Input() public svgKey: string;
	@Input() public svgIconClass: string;

	constructor(private _domSanitizer: DomSanitizer) { }

	get svgHtml(): SafeHtml {
		if (this.svgKey && SVG_ICON[this.svgKey]) {
			return this._domSanitizer.bypassSecurityTrustHtml(SVG_ICON[this.svgKey]);
		}
		else {
			return 'EMPTY_KEY'
		}
	}
}
