import { Component, Input, ChangeDetectionStrategy, forwardRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";


@Component({
	selector: 'uikit-quantity',
	templateUrl: './uikit-quantity.component.html',
	styleUrls: ['./uikit-quantity.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UiKitQuantityComponent),
			multi: true
		}
	]
})

export class UiKitQuantityComponent implements ControlValueAccessor, OnDestroy {

	@Input() public uiClass: string;
	@Input() public uiTitle: string;
	@Input() public uiError: string;
	@Input() public uiPlaceholder: string;
	@Input() public uiTitleVisible: boolean;
	@Input() public uiErrorVisible: boolean;
	@Input() public uiRequired: boolean;
	@Input() public uiTitleHideLabel: boolean;
	@Input() public quantityStep: number;
	@Input() public quantityMin: number;
	@Input() public quantityMax: number;
	@Input() public tabIndex: number;

	// controlValueAccessor
	public onChange: Function;
	public onTouched: Function;

	// state
	private stateSubscription: Subscription;
	private quantityControl: FormControl;

	// event
	public inputElem: any;
	public mousewheelEvent: () => any;

	constructor() {
		// init state
		this.uiClass = '';
		this.uiTitle = '';
		this.uiError = '';
		this.uiPlaceholder = '';
		this.uiTitleVisible = true;
		this.uiErrorVisible = false;
		this.uiRequired = false;
		this.uiTitleHideLabel = true;
		this.quantityStep = 1;
		this.quantityMin = 0;
		this.quantityMax = 999999;
		this.tabIndex = 0;

		// controlValueAccessor
		this.onChange = () => {};
		this.onTouched = () => {};

		// state
		this.stateSubscription = new Subscription();
		this.quantityControl = new FormControl('');

		this.stateSubscription.add(
			this.quantityControl.valueChanges.subscribe((val: number) => {
				this.onChange(val);
				this.onTouched();
			})
		);

		// event
		this.mousewheelEvent = ((event: MouseEvent) => {
			if (this.inputElem) {
				this.inputElem.blur();
			}
		}).bind(this);
	}

	checkValid(): boolean {
		if (this.quantityMax < this.quantityMin) {
			return false;
		}
		return true;
	}

	increment(): void {
		let currentValue = this.quantityControl.value;
		let _nextValue = Number(currentValue) + this.quantityStep;
		let nextValue = this.checkValid() ? (_nextValue < this.quantityMax) ? _nextValue : this.quantityMax : this.quantityMax;
		this.quantityControl.setValue(nextValue);
	}

	decrement(): void {
		let currentValue = this.quantityControl.value;
		let _nextValue = Number(currentValue) - this.quantityStep;
		let nextValue = this.checkValid() ? (_nextValue > this.quantityMin) ? _nextValue : this.quantityMin : this.quantityMax;
		this.quantityControl.setValue(nextValue);
	}

	focusHandler(event: MouseEvent): void {
		this.inputElem = event.target;
		document.addEventListener('mousewheel', this.mousewheelEvent);
	}

	validValue(event: any): void {
		// remove event listener
		document.removeEventListener('mousewheel', this.mousewheelEvent);
		this.inputElem = undefined;

		// check value
		let nextValue = +event.target.value;
		if (this.checkValid()) {
			if (nextValue > this.quantityMax) {
				this.quantityControl.setValue(this.quantityMax);
			}
			else if (nextValue < this.quantityMin) {
				this.quantityControl.setValue(this.quantityMin);
			}
		}
		else {
			this.quantityControl.setValue(this.quantityMax);
		}
	}

	writeValue(value: number): void {
		let nextValue = this.checkValid() ? value : this.quantityMax;
		this.quantityControl.setValue(nextValue);
	}

	registerOnChange(cb: Function): void {
		this.onChange = cb;
	}

	registerOnTouched(cb: Function): void {
		this.onTouched = cb;
	}

	ngOnDestroy(): void {
		this.stateSubscription.unsubscribe();
	}
}