import {
	Component,
	Input,
	AfterViewInit,
	ChangeDetectionStrategy,
	ElementRef,
	TemplateRef,
	ViewChild,
	ChangeDetectorRef,
	HostListener
} from '@angular/core';


import 'hammerjs';
import { BehaviorSubject } from 'rxjs';

export interface SliderOptions {
	dots: boolean,
	vertical: boolean,
	itemWidth: number,
}

@Component({
	selector: 'uikit-slider',
	templateUrl: './uikit-slider.component.html',
	styleUrls: ['./uikit-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiKitSliderComponent implements AfterViewInit {

	@ViewChild('sliderTrack', { static: false }) public sliderTrackElem: ElementRef;
	@Input() public items: Array<any>;
	@Input() public options: SliderOptions;

	@Input() public sliderItemTemplate: TemplateRef<any>;

	public sliderTrack: any;
	public fullWdth: number;
	public itemWdth: number;
	public activeItem: number;
	public currentTranslate: number;
	public currentDeltaX: number;
	public visebleItems: number
	public hiddenItems: number;
	public dotsArray$: BehaviorSubject<any[]>;
	public isMobile: boolean;

	@HostListener('window:resize', ['$event.target'])
	public onResize(windowEvent: Window) {
		this.renderSlider();
		this.activeItem = 0;
		this.currentTranslate = 0;
		this.sliderTrack.style.transform = `translateX(${this.currentTranslate}px)`;
		this.transformTransition();

		if (windowEvent.innerWidth < 991) {
			this.isMobile = true;
		}

	}

	constructor(private elRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {
		// default state
		this.items = new Array(10).fill('').map((z, i) => i);
		// this.items = [];

		this.options = {
			dots: true,
			vertical: false,
			itemWidth: 300,
		};
		this.activeItem = 0;
		this.currentTranslate = 0;


		this.visebleItems = 0;
		this.hiddenItems = 0;
		this.currentDeltaX = 0;

		this.dotsArray$ = new BehaviorSubject([]);

		this.isMobile = false;

	}


	get isHasControls() {
		return this.hiddenItems > 0 ? true : false;
	}

	get isactiveItem(): number {
		return this.activeItem;
	}

	get isLast(): boolean {
		return (this.activeItem === this.hiddenItems) ? true : false;
	}

	get isFirst(): boolean {
		return (this.activeItem !== 0) ? false : true;
	}

	transformTransition() {
		this.sliderTrack.style.transition = `transform .3s ease`;
		setTimeout(() => {
			this.sliderTrack.style.transition = ``;
		}, 300)
	}

	nextSlide() {
		if (this.activeItem < this.hiddenItems) {
			this.activeItem++;
			this.currentTranslate = -(this.itemWdth * this.activeItem);
			this.sliderTrack.style.transform = `translateX(${this.currentTranslate}px)`;
			this.transformTransition();
		}
	}

	prevSlide() {
		if (this.activeItem !== 0) {
			this.activeItem--;
			this.currentTranslate = (this.currentTranslate + this.itemWdth);
			this.sliderTrack.style.transform = `translateX(${this.currentTranslate}px)`;
			this.transformTransition();
		}
	}

	moveTo(item: number) {
		if(item < 0) {
			this.activeItem = 0
		} else if(item > this.hiddenItems) {
			this.activeItem = this.hiddenItems
		} else {
			this.activeItem = item;
		}

		this.currentTranslate = -(this.itemWdth * this.activeItem);
		this.sliderTrack.style.transform = `translateX(${this.currentTranslate}px)`;
		this.transformTransition();
	}

	ngAfterViewInit(): void {

		try {
			this.changeDetectorRef.detectChanges();
		}
		catch (err) {
			console.log(err);
		}

		this.onResize(window);
	}


	renderSlider() {
		this.sliderTrack = this.sliderTrackElem.nativeElement;
		this.fullWdth = this.sliderTrack.scrollWidth;
		this.itemWdth = this.sliderTrack.scrollWidth / this.items.length;

		this.visebleItems = Math.round(this.sliderTrack.offsetWidth / this.itemWdth);
		this.hiddenItems = Math.round((this.items.length) - this.visebleItems);

		if (this.hiddenItems > 0) {
			this.dotsArray$.next([...Array(this.hiddenItems + 1).keys()].slice(0))
		}

		this.sliderTrack.style.transform = `translateX(0px)`;
	}


	panLeftEvent(ev: any) {

		if (this.hiddenItems === 0 || !this.isMobile) {
			return;
		}

		let xTranslate = ev.deltaX + this.currentTranslate;
		let absDeltaX = Math.abs(ev.deltaX);


		if(Math.abs(ev.deltaY) < 250) {
			this.sliderTrack.style.transform = `translateX(${xTranslate}px)`;
		} else {
			this.sliderTrack.style.transform = `translateX(${this.currentTranslate}px)`;
		}

		if (ev.eventType === 4) {
			if (absDeltaX < this.itemWdth / 2) {
				this.moveTo(this.activeItem + 1);
			} else if (absDeltaX > this.itemWdth / 2) {
				if (Math.abs(xTranslate) < this.hiddenItems * this.itemWdth) {
					let currentTranslate = xTranslate;
					let nextActiveItem = Math.round(Math.abs(currentTranslate) / this.itemWdth);
					this.moveTo(nextActiveItem);
				} else {
					this.moveTo(this.hiddenItems);
				}
			}
		}

	}

	panRightEvent(ev: any) {

		if (this.hiddenItems === 0 || !this.isMobile) {
			return;
		}

		let xTranslate = ev.deltaX + this.currentTranslate;
		let absDeltaX = Math.abs(ev.deltaX);

		if(Math.abs(ev.deltaY) < 250) {
			this.sliderTrack.style.transform = `translateX(${xTranslate}px)`;
		} else {
			this.sliderTrack.style.transform = `translateX(${this.currentTranslate}px)`;
		}

		if (ev.eventType === 4) {

			if (absDeltaX < this.itemWdth / 2) {
				this.moveTo(this.activeItem - 1);

			} else if (absDeltaX > this.itemWdth / 2) {
				if (Math.abs(xTranslate) < this.hiddenItems * this.itemWdth) {
					let currentTranslate = xTranslate;
					let nextActiveItem = Math.round(Math.abs(currentTranslate) / this.itemWdth);
					this.moveTo(nextActiveItem);
				}

				if (xTranslate > 0) {
					this.moveTo(0);
				}
			}
		}
	}

}
