import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
	selector: 'company-summary',
	templateUrl: './company-summary.component.html',
	styleUrls: ['./company-summary.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CompanySummaryComponent {

	@Input() public description: string;
	@Input() public economic_empowerment: Array<any>;

	public isAllSummaryDescr$: BehaviorSubject<boolean>;

	constructor() {

		// init state
		this.description = '';
		this.isAllSummaryDescr$ = new BehaviorSubject(false);
		this.economic_empowerment = [];
	}

	toggleText(): void {
		let nextState = !this.isAllSummaryDescr$.getValue();
		this.isAllSummaryDescr$.next(nextState);
	}
}