import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiKitModule } from "@sub/tradalaxy_uikit/src/app/uikit/ui-kit.module";
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';

// component
import { AppModalLayoutComponent } from "./app-modal-layout/app-modal-layout.component";
import { SupplierModalComponent } from "./supplier-modal/supplier-modal.component";
import { AppModalSuccessComponent } from "./app-modal-success/app-modal-success.component";

import { TranslateModule } from '@ngx-translate/core';
import { OrderListModule } from "../order-list/order-list.module";
import { SvgInlineModule } from "../svg-inline/svg-inline.module";

@NgModule({
	imports: [
		CommonModule,
		UiKitModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		Ng2TelInputModule,
		SvgInlineModule,
		OrderListModule
	],
	declarations: [
		AppModalLayoutComponent,
		SupplierModalComponent,
		AppModalSuccessComponent
	],
	exports: [
		AppModalLayoutComponent,
		SupplierModalComponent,
		AppModalSuccessComponent
	]
})

export class ModalModule { }
