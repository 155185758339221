import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { IEnv, environment } from '@env/environment';
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AppService } from '@app/service/app-service.service';
import { BlogSubscriptionFetch, CommonActions } from '@app/store/common/common.state';
import { getUserIsLogged } from "@store/user/user.state";
import { commonValidator } from "@app/helper/validator";


@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html',
	styleUrls: ['./app-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppFooterComponent implements OnInit {

	private pageSubscription: Subscription;
	public isLoggedIn$: Observable<boolean>;
	public env: IEnv;
	public emailForm: FormControl;
	public stateEmailPopup$: BehaviorSubject<boolean>;
	public isPreview$: Observable<boolean>;
	public isProductFooter$: BehaviorSubject<boolean>;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		public appService: AppService,
		private actionsSubject: ActionsSubject) {

		this.env = environment;
		this.isLoggedIn$ = this.store.select(getUserIsLogged);
		this.emailForm = new FormControl('', commonValidator.email);
		this.stateEmailPopup$ = new BehaviorSubject(false);
		this.isPreview$ = this.appService.getIsPreview();

		this.isProductFooter$ = new BehaviorSubject(this.appService.getIsProductFooter());
	}

	collapsibleOnClick(event: any): void {
		let parrentElement = event.target.parentElement;
		let navBodyNode = event.target.parentElement.querySelector('.footer-block-list');
		if (navBodyNode.style.height) {
			navBodyNode.style.height = null;
			parrentElement.classList.remove("active");
		} else {
			navBodyNode.style.height = navBodyNode.scrollHeight + "px";
			parrentElement.classList.add("active");
		}
	}

	navLink(link: string, envKey?: string): void {
		event.preventDefault();
		if (envKey !== undefined) {
			window.open(this.env[envKey] + link, '_blank');
			return;
		}
	}

	submitEmail(): void {
		if (this.emailForm.valid && this.emailForm.value !== '') {
			this.store.dispatch(new BlogSubscriptionFetch({ email: this.emailForm.value }));
		}
		else {
			this.emailForm.markAsTouched();
		}
	}

	visibleEmailPopup(): void {
		this.stateEmailPopup$.next(true);
	}

	closeEmailPopup(): void {
		this.emailForm.setValue('');
		this.emailForm.setErrors(null);
		this.stateEmailPopup$.next(false);
	}

	ngOnInit(): void {
		this.pageSubscription = new Subscription();

		this.pageSubscription.add(this.actionsSubject.subscribe(z => {
			if (z.type === CommonActions.blogSubscriptionSuccess) {
				this.visibleEmailPopup();
			}
		}))

		this.pageSubscription.add(
			this.appService.isProductFooter$.subscribe(res => {
				this.isProductFooter$.next(res);
			})
		)

	}
}
