import { Component, Input, ChangeDetectionStrategy } from '@angular/core';


@Component({
	selector: 'uikit-textarea',
	templateUrl: './uikit-textarea.component.html',
	styleUrls: ['./uikit-textarea.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiKitTextareaComponent {

	@Input() public uiClass: string;
	@Input() public uiTitle: string;
	@Input() public uiError: string;
	@Input() public uiTitleVisible: boolean;
	@Input() public uiErrorVisible: boolean;
	@Input() public uiDisabled: boolean;
	@Input() public uiRequired: boolean;
	@Input() public uiTitleHideLabel: boolean;

	constructor() {
		// init state
		this.uiClass = '';
		this.uiTitle = '';
		this.uiError = '';
		this.uiTitleVisible = true;
		this.uiErrorVisible = false;
		this.uiDisabled = false;
		this.uiRequired = false;
		this.uiTitleHideLabel = false;
	}
}
