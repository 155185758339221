import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// component
import { AppMapComponent } from "./app-map.component";

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		AppMapComponent,
	],
	exports: [
		AppMapComponent,
	]
})

export class AppMapModule { }
