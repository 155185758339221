import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseData } from '@app/store/company-page.model';
import * as AppStore from '@store/app.store';
import { getCompanyBaseData } from '@app/store/company/company.state';
import { Store } from '@ngrx/store';
import { SelectItem } from '@app/helper/model';


@Component({
    selector: 'contacts-tab',
    templateUrl: './contacts-tab.component.html',
	styleUrls: ['./contacts-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactsTab {

	public baseData$: Observable<BaseData>;
	public officeAddress$: Observable<Array<SelectItem<{country_id: number}>>>;

    constructor(private store: Store<AppStore.AppStoreState>) {

		// init state
        this.baseData$ = this.store.select(getCompanyBaseData);

		this.officeAddress$ = this.baseData$.pipe(map(data => {
			let filteredAddress: Array<SelectItem<{country_id: number}>> = [];
			if (data && data.office_address) {
				data.office_address.filter(adress => {
					return adress.contacts.some(contact => contact.type === 'email' && contact.value);
				})
				.forEach(address => {
					let hasAddress = filteredAddress.find(z => z.value.country_id === address.country.id);
					if (!hasAddress) {
						filteredAddress.push({
							label:  address.country ? address.country.name : 'County name is undefined',
							value: {
								country_id: address.country.id
							}
						})
					}
				})
			}
			return filteredAddress;
		}));
    }
}
