import { Component, AfterViewInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, Observable, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { orderValidator } from "@app/helper/validator";
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import {
	UserActions,
	SignIn,
	SignUp,
	UserIsGuest,
	getUserCountry,
	UserCountryFetch,
	getUserCountryRequestState
} from "@store/user/user.state";
import {
	IndustryItem,
	TypeCompanyItem,
	CountryItem,
	getCountry,
	getCountryState
} from "@store/common/common.state";
import { ApiService } from "@api/api.service";
import { environment, IEnv } from '@env/environment';
import { wrapperScrollTop } from '@app/helper/common';
import { HttpClient } from '@angular/common/http';
import { IfStmt } from '@angular/compiler';


@Component({
	selector: 'authorization-user',
	templateUrl: './authorization-user.component.html',
	styleUrls: ['./authorization-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AuthorizationUserComponent implements AfterViewInit, OnDestroy {
	public intlTelObject: any;

	private pageSubscription: Subscription;
	private checkEmailPromise: Promise<void>;

	public preloader$: BehaviorSubject<boolean>;
	public forgotPasswordModal$: BehaviorSubject<boolean>;
	public guestModal$: BehaviorSubject<boolean>;

	public signInFormError$: BehaviorSubject<boolean>;
	public signUpFormError$: BehaviorSubject<boolean>;
	public typeCompany$: BehaviorSubject<Array<TypeCompanyItem>>;
	public industry$: BehaviorSubject<Array<IndustryItem>>;
	public industryArray$: BehaviorSubject<Array<any>>;
	public country$: Observable<Array<CountryItem>>;
	public env: IEnv;

	public signInForm: FormGroup;
	public signUpForm: FormGroup;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private actionsSubject: ActionsSubject,
		private apiService: ApiService,
		private http: HttpClient,
		private changeDetectorRef: ChangeDetectorRef) {
		
		// init state
		this.preloader$ = new BehaviorSubject(true);
		this.forgotPasswordModal$ = new BehaviorSubject(false);
		this.guestModal$ = new BehaviorSubject(false);

		this.signInFormError$ = new BehaviorSubject(false);
		this.signUpFormError$ = new BehaviorSubject(false);
		this.typeCompany$ = new BehaviorSubject([]);
		this.industry$ = new BehaviorSubject([]);
		this.industryArray$ = new BehaviorSubject([]);
		this.country$ = this.store.select(getCountry);
		this.env = environment;

		// add page subscription
		this.pageSubscription = new Subscription();
		this.pageSubscription.add(
			this.actionsSubject.subscribe(z => {
				if (z.type === UserActions.signIn || z.type === UserActions.signUp) {
					this.preloader$.next(true);
				}
				else if (z.type === UserActions.signInError) {
					this.preloader$.next(false);
					this.signInFormError$.next(true);
				}
				else if (z.type === UserActions.signUpError) {
					this.preloader$.next(false);
					this.signUpFormError$.next(true);
				}
			})
		);

		this.initSignInForm();
		this.initSignUpForm();
	}

	get signInFormControl(): {[key: string]: AbstractControl} {
		return this.signInForm.controls;
	}

	get signUpFormControl(): {[key: string]: AbstractControl} {
		return this.signUpForm.controls;
	}

	detectChanges(): void {
		try {
			this.changeDetectorRef.detectChanges();
		}
		catch(error) {
			console.warn('DetectChanges Error', error);
		}
	}

	preloaderState(): void {
		let initPreloaderState = false;
		this.pageSubscription.add(
			combineLatest(
				this.store.select(getCountryState),
				this.store.select(getUserCountry),
				this.store.select(getUserCountryRequestState)
			).subscribe(val => {
				if (!initPreloaderState) {
					let countryState = val[0];
					let userCountry = val[1];
					let userCountryState = val[2];

					let coutryReqFinish = countryState.data || countryState.requestState.isError;
					let userCountryReqFinish = userCountry || userCountryState.isError;
					if (coutryReqFinish && userCountryReqFinish) {
						initPreloaderState = true;
						this.preloader$.next(false);
					}
				}
			})
		)
	}

	setIntlTelObject(data: any): void {
		this.intlTelObject = data;
	}

	setIntlCountryChange(): void {
		this.validPhone();
	}

	initSignInForm(): void {
		this.signInForm = new FormGroup({
			email: new FormControl('', orderValidator.email),
			password: new FormControl('', orderValidator.password)
		});

		this.pageSubscription.add(
			this.signInForm.valueChanges.subscribe(() => {
				if (this.signInFormError$.getValue()) {
					this.signInFormError$.next(false);
				}
			})
		);
	}

	initSignUpForm(): void {
		this.signUpForm = new FormGroup({
			first_name: new FormControl('', orderValidator.firstName),
			last_name: new FormControl('', orderValidator.lastName),
			email: new FormControl('', orderValidator.email),
			password: new FormControl('', orderValidator.password),
			company_name: new FormControl('', orderValidator.companyName),
			country: new FormControl('', Validators.required),
			phone: new FormControl('', Validators.required),
			company_type: new FormControl('', Validators.required),
			industry: new FormControl('', Validators.required),
			term_of_use: new FormControl(false, [Validators.required, Validators.pattern('true')]),
			privacy_policy: new FormControl(false)
		}, (form: FormGroup) => {
			if (form.controls.password.valid) {
				let password = form.controls.password.value.toLowerCase();
				let email = form.controls.email.value.toLowerCase();
				let firstName = form.controls.first_name.value.toLowerCase();
				let lastName = form.controls.last_name.value.toLowerCase();

				if (email.length && password.includes(email)) {
					return { matchWithUserData: 'validations.password_forbid_email' }
				}
				if (firstName.length && password.includes(firstName)) {
					return { matchWithUserData: 'validations.password_forbid_name' }
				}
				if (lastName.length && password.includes(lastName)) {
					return { matchWithUserData: 'validations.password_forbid_last_name' }
				}
				return null;
			}
			return null;
		});

		this.pageSubscription.add(
			this.signUpForm.valueChanges.subscribe(() => {
				if (this.signUpFormError$.getValue()) {
					this.signUpFormError$.next(false);
				}
			})
		);
		this.pageSubscription.add(
			this.signUpFormControl.term_of_use.valueChanges.subscribe(() => {
				if (!this.signUpFormControl.term_of_use.touched) {
					this.signUpFormControl.term_of_use.markAsTouched()
				}
			})
		);
		this.pageSubscription.add(
			this.signUpFormControl.email.valueChanges.pipe(debounceTime(300)).subscribe((val) => {
				if (this.signUpFormControl.email.valid) {
					// Исключение код не дублировать (использование apiService через store effects)
					this.checkEmailPromise = this.apiService.checkSignUpEmail(this.signUpFormControl.email.value).toPromise()
						.then(emailValid => {
							if (val === this.signUpFormControl.email.value && emailValid.exist) {
								this.signUpFormControl.email.setErrors({errorName: 'validations.email_exists'});
								this.detectChanges();
							}
							return;
						})
						.catch(() => {
							this.signUpFormControl.email.setErrors({errorName: 'validations.server_error_email_exists'});
							this.detectChanges();
							return;
						})
				}
			})
		);
		this.pageSubscription.add(
			this.signUpFormControl.country.valueChanges.subscribe(() => {
				this.setCountryPhoneCode();
			})
		);
		this.pageSubscription.add(
			this.signUpFormControl.phone.valueChanges.subscribe(val => {
				if (!val.length) {
					this.signUpFormControl.phone.setErrors({ errorName: 'validations.telephone_required' });
				}
				else {
					this.validPhone();
				}
			})
		);
	}

	validPhone(): void {
		if (this.intlTelObject) {
			let isValid = this.intlTelObject.isValidNumber();
			if (!isValid) {
				this.signUpFormControl.phone.setErrors({ errorName: 'validations.telephone_error' });
			}
			else {
				this.signUpFormControl.phone.setErrors(null);
			}
		}
	}

	setCountryPhoneCode(): void {
		if (this.intlTelObject) {
			let countryCode = this.signUpFormControl.country.value.code;
			if (countryCode) {
				this.intlTelObject.setCountry(countryCode);

				let {dialCode} = this.intlTelObject.getSelectedCountryData();
				this.signUpFormControl.phone.setValue(`+${dialCode}`);
			}
		}
	}

	signInFormSubmit(): void {
		if (this.signInForm.valid) {
			let email = this.signInFormControl.email.value;
			let password = this.signInFormControl.password.value;
			this.store.dispatch(new SignIn({email: email, password: password}));
		}
	}

	async signUpFormSubmit() {
		await this.checkEmailPromise;
		this.signUpForm.markAllAsTouched();
		if (this.signUpForm.valid) {
			let phone = this.signUpFormControl.phone.value.replace(/[\s\+\-\(\)]/g, '');
			let data = {
				user: {
					email: this.signUpFormControl.email.value,
					first_name: this.signUpFormControl.first_name.value,
					password: this.signUpFormControl.password.value,
					last_name: this.signUpFormControl.last_name.value,
					agree_terms: this.signUpFormControl.term_of_use.value,
					agree_privacy: this.signUpFormControl.privacy_policy.value
				},
				company: {
					name: this.signUpFormControl.company_name.value,
					country_id: this.signUpFormControl.country.value.id,
					phone: phone,
					business_type_id: this.signUpFormControl.company_type.value.id,
					interested_in_id: 3,
					high_level_categories: [this.signUpFormControl.industry.value.id]
				}
			}

			this.store.dispatch(new SignUp(data));
		}
		else {
			let invalidControl = '';
			if (this.signUpForm.hasError('matchWithUserData')) {
				invalidControl = 'password';
			}
			else {
				cycle: for (let key in this.signUpForm.controls) {
					if ( this.signUpForm.controls[key].invalid ) {
						invalidControl = key;
						break cycle;
					}
				}
			}

			// scroll
			try {
				let elem;
				let scrollElem = document.querySelector('.app-wrapper');
				if (invalidControl === 'term_of_use') {
					elem = document.querySelector(`[formControlName=${invalidControl}]`).parentElement;
				}
				else {
					elem = document.querySelector(`[formControlName=${invalidControl}]`);
				}

				if (elem && scrollElem) {
					let currentScroll = scrollElem.scrollTop;
					let elemTop = elem.getBoundingClientRect().top;
					let top = (currentScroll + elemTop) - 50;
					scrollElem.scrollTo({
						left: 0,
						top: top,
						behavior: 'smooth'
					});
				}
			}
			catch(error) {
				console.warn("Warning --> scroll error", error);
			}
		}
	}

	continueAsGuest(): void {
		this.guestModal$.next(true);
	}

	forgotPasswordToggle(value: boolean): void {
		let nextState = (value !== undefined) ? value : !this.forgotPasswordModal$.getValue();
		this.forgotPasswordModal$.next(nextState);
	}

	modalConfirm(modalKey: string): void {
		switch(modalKey) {
			case 'guestModal': {
				this.guestModal$.next(false);
				this.store.dispatch(new UserIsGuest(true));
			}; break;
		}
	}

	modalClose(modalKey: string): void {
		switch(modalKey) {
			case 'guestModal': {
				this.guestModal$.next(false);
			}; break;
		}
	}

	fetchCountryState(): void {
		let reqCount = 0;
		this.pageSubscription.add(
			this.store.select(getCountryState).subscribe(country => {
				if (!country.requestState.isFetch && (!country.data || country.requestState.isError)) {
					if (reqCount === 0) {
						reqCount = 1;
						this.store.dispatch(new UserCountryFetch());
					}
				}
			})
		)
	}

	fetchUserCountry(): void {
		let countryInit = false;
		this.pageSubscription.add(
			combineLatest(
				this.country$,
				this.store.select(getUserCountry)
			).subscribe(data => {
				let country = data[0];
				let userCountry = data[1];
				if (country && userCountry && !countryInit && !this.signUpFormControl.country.value) {
					countryInit = true;
					setTimeout(() => {
						this.signUpFormControl.country.setValue({
							id: userCountry.id,
							code: userCountry.code
						});
					}, 0)
				}
			})
		)
	}

	fetchCompanyType() {
		this.http.get(`${this.env.apiUrl}companies/business-types/`).toPromise().then(res => {
			if(res) {
				let data = res as any[];
				let industriesArrays = data.map(item => {
					return {
						id: item.id,
						array: item.industries.map(elem => {
							return {
								value: {
									id: elem.id
								},
								label: elem.name
							}
						})
					}
				})
				this.industryArray$.next(industriesArrays);
				
				let companyType = data.map(item => {
					return {
						value: {
							id: item.id
						},
						label: item.name
					}
				})
				this.typeCompany$.next(companyType);
			}
		})
	}

	setIndustry(event): void {
		if(this.industryArray$.value.length !== 0) {
			let currentIndustryArray = this.industryArray$.value.find(item => event.id === item.id);
			this.industry$.next(currentIndustryArray.array);
			this.signUpForm.get('industry').setValue('');
		}
	}

	activeTab(e) {
		if(e === 0) {
			window.location.hash = '#signin'
		} else if(e === 1) {
			window.location.hash = '#signup'
		} 
	}

	ngAfterViewInit(): void {
		this.preloaderState();
		this.fetchUserCountry();
		this.fetchCountryState();
		this.fetchCompanyType();
		wrapperScrollTop();
	}

	ngOnInit() {
		window.location.hash = '#signin'
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}

	
}