import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import {
	UserLogout,
	UserDataSuccess,
	UserDataFetch
} from '@app/store/user/user.state';


export class AccessToken {
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    prv: string;
    sub: number;
}

@Injectable()
export class UserService {

	private token: string;

	constructor(
		private cookies: CookieService,
		private store: Store<AppStore.AppStoreState>) { }

	getToken(): string {
		return this.token;
	}

	setToken(token: string): void {
		this.token = token;
		let now = (new Date()).getTime();
		let expires_in = new Date(now + 24 * 3 * 3600 * 1000);
		this.cookies.set(environment.cookieTokenName, token, expires_in, "/", environment.mainCookiesDomain, false, 'Lax');
	}

	checkUser(): void {
		let token = this.cookies.get(environment.cookieTokenName);
		let tokenValid = this.isTokenValid(token);
		if (tokenValid) {
			this.token = token;
			let decodedAccessToken = this.parseJwtAccessToken(this.token);
			this.store.dispatch(new UserDataFetch({ id: decodedAccessToken.sub }));
		}
		else {
			this.store.dispatch(new UserDataSuccess(undefined));
		}
	}

	logout(): void {
		this.cookies.delete(environment.cookieTokenName, "/", environment.mainCookiesDomain);
		this.token = undefined;
		this.store.dispatch(new UserLogout());
	}

	private isTokenValid(token: string) {
		try {
			return !!this.parseJwtAccessToken(token);
		}
		catch {
			return false;
		}
	}

	private parseJwtAccessToken(token: string): AccessToken {
		if (!token) {
			return null;
		}

		let base64Url = token.split('.')[1];
		let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return <AccessToken>JSON.parse(jsonPayload);
	}
}
