import { Directive, HostListener, ElementRef, Output, EventEmitter } from "@angular/core";


@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {

    @Output() clickOutside: EventEmitter<boolean>;

    constructor(private elementRef: ElementRef){
        this.clickOutside = new EventEmitter();
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any): void {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
          this.clickOutside.emit(true);
        }
    }
}