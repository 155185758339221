import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { ApiService } from '@app/api/api.service';
import { Injectable } from '@angular/core';
import { CompanyActions, FetchCompanyBase, FetchPopularProducts, FetchCategoriesList, FetchCompanyTabBaseData, FetchBestsellersProductsList, FetchCategoryListForDropdown, FetchProductsListOfCategory, FetchNewInCategoryProducts, FetchAllProductsInCategory, FetchProductsListOfCategoryError, FetchTradeInfoData } from './company.state';
import { mergeMap, map } from 'rxjs/operators';
import { ProductItem } from '@app/helper/model';
import { ProductsListForProductItem } from '../company-page.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CompanyEffects {

    constructor(
        private actions$: Actions,
        private apiService: ApiService
    ) {}

    public fetchBaseInfo$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchCompanyBase),
        mergeMap( (actionData: FetchCompanyBase) => this.apiService.getBaseInfo()
        .pipe(
            map( data => {
                const newData = {...data};
				newData.office_address = newData.office_address.map( adress => {
					let newAdress = {...adress};
					const phones = newAdress.contacts.filter( contact => contact.type === 'phone');
					const emails = newAdress.contacts.filter( contact => contact.type === 'email');
					newAdress.contacts = [...phones, ...emails];
					return newAdress;
                });
                return {type: CompanyActions.fetchCompanyBaseSuccess, payload: newData};
            })
        ))
    ));

    public fetchPopularProducts$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchPopularProducts),
        mergeMap( (actionData: FetchPopularProducts) => this.apiService.getRecomendedProductsData(actionData.payload.limit)
        .pipe(
            map( data => {
                let newData: ProductItem[] = data.map( product => {
					let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;
					return {
						imgSrc: image,
						brand: product.category,
						title: product.name,
						oldPrice: null,
						currentPrice: product.price,
						currency: product.currency,
						slug: product.slug
					}
                });
                return {type: CompanyActions.fetchpoPularProductsSuccess, payload: newData}
            })
        ))
	));

	public fetchRecomendedProducts$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchRecomendedProducts),
        mergeMap( (actionData: FetchPopularProducts) => this.apiService.getRecomendedProductsData(actionData.payload.limit)
        .pipe(
            map( data => {
                let newData: ProductItem[] = data.map( product => {
					let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;
					return {
						imgSrc: image,
						brand: product.category,
						title: product.name,
						oldPrice: null,
						currentPrice: product.price,
						currency: product.currency,
						slug: product.slug
					}
                });
                return {type: CompanyActions.fetchRecomendedProductsSuccess, payload: newData}
            })
        ))
    ));

    public fetchCategoriesList$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchCategoriesList),
        mergeMap((actionData: FetchCategoriesList) => this.apiService.getCategories()
        .pipe(
            map(data => ({type: CompanyActions.fetchCategoriesListSuccess, payload: data}))
        )))
    );

    public fetchCompanyTabBaseData$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchCompanyTabBaseData),
        mergeMap((actionData: FetchCompanyTabBaseData) => this.apiService.getCompanyTabInfo()
        .pipe(
            map(data => ({type: CompanyActions.fetchCompanyTabBaseDataSuccess, payload: data}))
        )))
    );

    public fetchBestSellersProductsList$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchBestsellersProductsList),
        mergeMap( (actionData: FetchBestsellersProductsList) => this.apiService.getBestsellersProductsData(actionData.payload.limit)
        .pipe(
            map( data => {
                let newData: ProductItem[] = data.map( product => {
					let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;
					return {
						imgSrc: image,
						brand: product.category,
						title: product.name,
						oldPrice: null,
						currentPrice: product.price,
						currency: product.currency,
						slug: product.slug
					}
                });
                return {type: CompanyActions.fetchBestsellersProductsListSuccess, payload: newData}
            })
        ))
    ));

    public fetchCategoryListForDropdown$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchCategoryListForDropdown),
        mergeMap((actionData: FetchCategoryListForDropdown) => this.apiService.getCategoryListForDropdown()
        .pipe(
            map(data => {
                return ({type: CompanyActions.fetchCategoryListForDropdownSuccess, payload: data});
            })
        )))
    );

    public fetchProductsListOfCategory$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchProductListOfCategory),
        mergeMap( (actionData: FetchProductsListOfCategory) => this.apiService.getProductListOfCategory(actionData.payload.categoryId, actionData.payload.filter)
        .pipe(
            map( data => {
                let newData: ProductsListForProductItem  = {data: null, meta: {...data.meta, high_level_category: data.data[0] ? data.data[0].high_level_category : null}, links: data.links};
                newData.data = data.data.map( product => {
					let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;
					return {
						imgSrc: image,
						brand: product.category,
						title: product.name,
						oldPrice: null,
						currentPrice: product.price,
						currency: product.currency,
                        slug: product.slug,
                        id: product.id
					}
                });
                return {type: CompanyActions.fetchProductListOfCategorySuccess, payload: newData}
            })
        ))
    ));

    public fetchNewInCategoryProducts$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchNewInCategoryProducts),
        mergeMap( (actionData: FetchNewInCategoryProducts) => this.apiService.getProductsData(actionData.payload.slug, actionData.payload.newInFilter)
        .pipe(
            map( data => {
                let newData: ProductsListForProductItem = {links: data.links, meta: {...data.meta, high_level_category: data.data[0] ? data.data[0].high_level_category : null}, data: null};
                newData.data = data.data.map( product => {
					let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;
					return {
						imgSrc: image,
						brand: product.category,
						title: product.name,
						oldPrice: null,
						currentPrice: product.price,
						currency: product.currency,
                        slug: product.slug,
                        id: product.id
					}
                });
                return {type: CompanyActions.fetchNewInCategoryProductsSuccess, payload: newData}
            })
        ))
    ));

    public fetchAllProductsInCategory$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchAllProductsInCategory),
        mergeMap( (actionData: FetchAllProductsInCategory) => this.apiService.getProductsData(actionData.payload.slug, actionData.payload.newInFilter)
        .pipe(
            map( data => {
                let newData: ProductsListForProductItem = {links: data.links, meta: {...data.meta, high_level_category: data.data[0] ? data.data[0].high_level_category : null}, data: null};
                newData.data = data.data.map( product => {
					let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;
					return {
						imgSrc: image,
						brand: product.category,
						title: product.name,
						oldPrice: null,
						currentPrice: product.price,
						currency: product.currency,
                        slug: product.slug,
                        id: product.id
					}
                });
                return {type: CompanyActions.fetchAllProductsInCategorySuccess, payload: newData}
            })
        ))
    ));

    //костыль
    public clearAllProductsInCategory$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchAllProductsInCategoryError),
        mergeMap( (actionData: FetchAllProductsInCategory) => new BehaviorSubject({type: CompanyActions.fetchAllProductsInCategorySuccess, payload: null}))
    ));

    //костыль
    public cleanProductsListOfCategory$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchProductListOfCategoryError),
        mergeMap( (actionData: FetchProductsListOfCategoryError) => new BehaviorSubject({type: CompanyActions.fetchProductListOfCategorySuccess, payload: null}))
    ));

    public fetchTradeInfoData$ = createEffect( () => this.actions$.pipe(
        ofType(CompanyActions.fetchTradeInfoData),
        mergeMap( (actionData: FetchTradeInfoData) => this.apiService.getTradeInfoData()
        .pipe(
            map(data => {
                return ({type: CompanyActions.fetchTradeInfoDataSuccess, payload: data});
            })
        )))
    );

}
