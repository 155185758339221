import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FirstLetterPipe } from "./first-letter.pipe";
import { TextPreviewPipe } from "./text-preview.pipe";

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		FirstLetterPipe,
		TextPreviewPipe
	],
	exports: [
		FirstLetterPipe,
		TextPreviewPipe
	],
	providers: []
})

export class PipeModule { }