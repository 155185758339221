import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from "rxjs/operators";
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { ProductActive, getProductActiveData } from '@app/store/product/product.state';
import { CertificateItem, ProductSliderItem } from "@app/product-page/product-page.model";
import { AppService } from '@app/service/app-service.service';
import { wrapperScrollTop } from '@app/helper/common';


@Component({
	selector: 'product-tab',
	templateUrl: './product-tab.component.html',
	styleUrls: ['./product-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductTabComponent implements OnDestroy {

	// constant
	public certificatesQuantity: number;

	// state
	public isAllSummaryDescr$: BehaviorSubject<boolean>;
	public isAllCertificate$: BehaviorSubject<boolean>;

	// data
	public productData$: Observable<ProductActive>;
	public productImages$: Observable<Array<ProductSliderItem>>;
	public certificateArray$: Observable<Array<CertificateItem>>;

	constructor(private store: Store<AppStore.AppStoreState>, private appService: AppService) {

		// constant
		this.certificatesQuantity = 3;

		// state
		this.isAllSummaryDescr$ = new BehaviorSubject(false);
		this.isAllCertificate$ = new BehaviorSubject(false);

		// data
		this.productData$ = this.store.select(getProductActiveData);

		this.productImages$ = this.productData$.pipe(map(data => {
			if (data) {
				return data.images;
			}
			else {
				return [];
			}
		}));

		this.certificateArray$ = combineLatest(
			this.productData$,
			this.isAllCertificate$
		).pipe(map(result => {
			let productData = result[0];
			let isAllCertificate = result[1];
			if (isAllCertificate && productData && productData.certifications) {
				return productData.certifications;
			}
			else if (!isAllCertificate && productData && productData.certifications) {
				return productData.certifications.slice(0, this.certificatesQuantity);
			}
			else {
				return [];
			}
		}));

		this.appService.isProductFooter$.next(true);
	}

	ngOnDestroy(): void {
		wrapperScrollTop();
		this.appService.isProductFooter$.next(false);
	}

	toggleText() {
		let nextState = !this.isAllSummaryDescr$.getValue();
		this.isAllSummaryDescr$.next(nextState);
	}

	toggleCertificate(): void {
		let nextState = !this.isAllCertificate$.getValue();
		this.isAllCertificate$.next(nextState);
	}
}