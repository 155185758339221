import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Custom modules
import { UiKitIconModule } from "../uikit-icon/uikit-icon.module";

// Component
import { UiKitInputComponent } from "./uikit-input/uikit-input.component";
import { UiKitTextareaComponent } from "./uikit-textarea/uikit-textarea.component";
import { UiKitCheckboxComponent } from "./uikit-checkbox/uikit-checkbox.component";
import { UiKitQuantityComponent } from "./uikit-quantity/uikit-quantity.component";
import { UiKitDropdownComponent } from "./uikit-dropdown/uikit-dropdown.component";
import { UiKitAutocompleteComponent } from "./uikit-autocomplete/uikit-autocomplete.component";
import { UiKitDoubleInputComponent } from "./uikit-double-input/uikit-double-input.component";


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UiKitIconModule,
		BrowserAnimationsModule
	],
    declarations: [
		UiKitInputComponent,
		UiKitTextareaComponent,
		UiKitCheckboxComponent,
		UiKitQuantityComponent,
		UiKitDropdownComponent,
		UiKitAutocompleteComponent,
		UiKitDoubleInputComponent
	],
	exports: [
		UiKitInputComponent,
		UiKitTextareaComponent,
		UiKitCheckboxComponent,
		UiKitQuantityComponent,
		UiKitDropdownComponent,
		UiKitAutocompleteComponent,
		UiKitDoubleInputComponent
	]
})

export class UiKitInputModule { }
