import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// custom modules
import { UiKitModule } from "@sub/tradalaxy_uikit/src/app/uikit/ui-kit.module";
import { SharedModule } from "@app/shared/shared";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { BreadcrumbsComponentModule } from "../shared/breadcrumbs/breadcrumbs.module";

// component
import { OrderPageComponent } from "./order-page.component";
import { AuthorizationOrderComponent } from "./authorization-order/authorization-order.component";
import { AuthorizationUserComponent } from "./authorization-user/authorization-user.component";
import { GuestOrderComponent } from "./guest-order/guest-order.component";
import { OrderSuccessPageComponent } from "./order-success-page/order-success-page.component";
import { orderSuccessItemComponent } from "./order-success-item/order-success-item.component";
import { WarehouseModalComponent } from "./warehouse-modal/warehouse-modal.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";

// pipe
import { PipeModule } from "@app/pipe/pipe.module";

@NgModule({
	declarations: [
		OrderPageComponent,
		AuthorizationOrderComponent,
		AuthorizationUserComponent,
		GuestOrderComponent,
		OrderSuccessPageComponent,
		orderSuccessItemComponent,
		WarehouseModalComponent,
		ForgotPasswordComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		UiKitModule,
		SharedModule,
		BreadcrumbsComponentModule,
		Ng2TelInputModule,
		PipeModule
	],
	providers: [
	]
})

export class OrderPageModule { }