import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// custom modules
import { UiKitIconModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";

// component
import { SocialShareComponent } from "./social-share.component";


@NgModule({
	imports: [
		CommonModule,
		UiKitIconModule
	],
	declarations: [
		SocialShareComponent
	],
	exports: [
		SocialShareComponent
	]
})

export class SocialSharedModule { }
