import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { ProductLogistic, ProductActive, getProductActiveData, getProductLogisticData } from '@app/store/product/product.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { wrapperScrollTop } from '@app/helper/common';


@Component({
	selector: 'logistic-tab',
	templateUrl: './logistic-tab.component.html',
	styleUrls: ['./logistic-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LogisticTabComponent implements AfterViewInit {

	public logisticData$: Observable<ProductLogistic>;
	public productData$: Observable<ProductActive>;

	constructor(private store: Store<AppStore.AppStoreState>) {
		this.logisticData$ = this.store.select(getProductLogisticData);
		this.productData$ = this.store.select(getProductActiveData);
	}

	ngAfterViewInit() {
		(<any>window).hj('trigger', 'open_logistic_tab');
		wrapperScrollTop();
	}
}