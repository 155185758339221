import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OrderPageComponent } from './order-page.component';


@Injectable({
	providedIn: 'root',
})

export class CanDeactivateOrderGuard implements CanDeactivate<OrderPageComponent> {
	canDeactivate(
		component: OrderPageComponent,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> | boolean {
		return component.canDeactivate();
	}
}
