import { Component, OnInit, AfterViewInit, Input, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getWindowOption } from "@store/common/common.state";
import { ProductSliderItem } from "@app/product-page/product-page.model";
import { UiKitSliderComponent } from '@sub/tradalaxy_uikit/src/app/uikit/uikit-slider/uikit-slider.component';


const BASE_CAROUSEL_CONFIG: NguCarouselConfig = {
	grid: {
		xs: 5,
		sm: 5,
		md: 5,
		lg: 5,
		all: 0
	},
	slide: 1,
	point: {
		visible: false
	},
	touch: false,
	loop: false,
	animation: 'lazy',
	vertical: {
		enabled: true,
		height: 410
	}
};

@Component({
	selector: 'product-slider',
	templateUrl: './product-slider.component.html',
	styleUrls: ['./product-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductSliderComponent implements OnInit, AfterViewInit {

	// slider
	@ViewChild('uikitslider', {static: false}) private _uikitslider: UiKitSliderComponent;

	// props
	@Input() public productImages: Array<ProductSliderItem>;
	@Input() public carouselConfig: NguCarouselConfig;

	public productImages$: BehaviorSubject<Array<ProductSliderItem>>
	public isMobile$: BehaviorSubject<boolean>
	// state
	@ViewChild('myCarousel', { static: false }) private _carousel: NguCarousel<any>;
	public _carouselInputs$: BehaviorSubject<NguCarouselConfig>;
	public _activeSlide: number;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private _changeDetectorRef: ChangeDetectorRef) {
		this.isMobile$ = new BehaviorSubject(false)
		// sort image is main first

		// init state
		this.store.select(getWindowOption).subscribe(val => {
			if (val && val.type === 'mobile' || val.type === 'tablet') {
				this.isMobile$.next(true);
			}
			else {
				this.isMobile$.next(false);
				this._carouselInputs$ = new BehaviorSubject(BASE_CAROUSEL_CONFIG);
			}
		}).unsubscribe();
		this._activeSlide = 0;
	}

	get activeSlide(): number {
		return this._activeSlide;
	}

	get isLastSlide(): boolean {
		return (this._carousel) ? this._carousel.isLast : false;
	}

	get isFirstSlide(): boolean {
		return (this._carousel) ? this._carousel.isFirst : false;
	}

	get currentSlide(): number {
		return (this._carousel) ? this._carousel.currentSlide : 0;
	}

	prevSlide(): void {
		let slideStep = this._carousel.slideItems;
		let nextSlide = this.currentSlide - slideStep;
		nextSlide = (nextSlide > 0) ? nextSlide : 0;
		this._carousel.moveTo(nextSlide, false);
	}

	nextSlide(): void {
		let slideStep = this._carousel.slideItems;
		let nextSlide = this.currentSlide + slideStep;
		this._carousel.moveTo(nextSlide, false);
	}

	toCurrentSlide(index: number) {
		this._activeSlide = index;
		let moveToIndex = index !== 0 ? index - 1 : index;
		this._carousel.moveTo(moveToIndex, false);
	}

	ngOnInit(): void {
		// sort productImages = is_main only first
		this.productImages$ = new BehaviorSubject(this.productImages.sort((a, b) => (a.is_main === b.is_main)? 0 : a.is_main? -1 : 1));

		if (this.carouselConfig !== undefined) {
			try {
				let carouselInputs: NguCarouselConfig = {
					...BASE_CAROUSEL_CONFIG,
					...this.carouselConfig
				}
				this._carouselInputs$.next(carouselInputs);
			}
			catch (error) {
				console.warn("Warning --> PromoProductSliderComponent --> ngOnInit", error);
			}
		}


	}

	ngAfterViewInit(): void {
		try {

			setTimeout(() => {
				if ( this._uikitslider ) {
					this._uikitslider.renderSlider();
				}
			}, 0);

			this._changeDetectorRef.detectChanges();
		}
		catch (error) {
			console.warn("Warning --> PromoProductSliderComponent --> ngAfterViewInit", error)
		}
	}
}
