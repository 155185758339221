const cookieNamePrefix = '';

export const env = {
	production: true,
	stagging: false,
	apiUrl: `https://tr-back.tradalaxy.com/api/v1/`,
	marketplaceUrl: 'marketplace.tradalaxy.com',
	apiMarketplaceUrl: `https://tr-back.tradalaxy.com/api/v1/marketplace/`,
	marketplaceSubDomain: 'marketplace',
	tradalaxyUrl: 'https://my.tradalaxy.com',
	helpCenterUrl: 'https://support.tradalaxy.com',
	blogUrl: 'https://blog.tradalaxy.com',
	landingUrl: 'https://about.tradalaxy.com',
	mainCookiesDomain: 'tradalaxy.com',
	cookieTokenName: cookieNamePrefix + 'token',
	cookieLanguagesName: cookieNamePrefix + 'languages',
	cookieMainLangName:  cookieNamePrefix + 'main_lang',
};

import 'zone.js/dist/zone-error';  // Included with Angular CLI.
