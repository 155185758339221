import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map } from "rxjs/operators";
import { ProductActive, getProductActiveData, ProductCompany, getProductCompanyData, UpdateProductActiveQuantity, UpdateProductActiveCurrentFeatures } from '@app/store/product/product.state';
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import * as moment from 'moment';
import { FormControl, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { EmailSubscriptionFetch, CommonActions, getWindowOption } from '@app/store/common/common.state';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/service/app-service.service';
import { AddOrderListItem, ToggleModalVisible } from '@store/order/order.state';
import { getOrderListOption } from "@store/order/order.state";
import { commonValidator } from "@app/helper/validator";
import { ProductPrice, FeatureItem } from "@app/product-page/product-page.model";
import { UiKitSliderComponent } from '@sub/tradalaxy_uikit/src/app/uikit/uikit-slider/uikit-slider.component';
import { FetchOrderListData, getFetchOrderData } from '@app/store/order/fetch-order.state';


@Component({
	selector: 'product-form',
	templateUrl: './product-form.component.html',
	styleUrls: ['./product-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductFormComponent implements OnInit, OnDestroy, AfterViewInit {

	// base product data
	@Input() public productData: ProductActive;
	public productData$: Observable<ProductActive>;

	// modals
	public productPrice$: BehaviorSubject<ProductPrice>;
	public bulkPriceActive$: BehaviorSubject<number>;

	// slider
	@ViewChild('uikitslider', {static: false}) private _uikitslider: UiKitSliderComponent;

	@ViewChild('mobileBar', {static: false}) mobileBar;


	public companyData$: Observable<ProductCompany>;
	public companyVerify: boolean = false;
	public quantityInput: number;
	public bulkPriceArray = [];
	public productMainImg: string = '';
	public isMobile$: BehaviorSubject<boolean>;

	public availabilityDay: string;
	private pageSubscription: Subscription;
	public emailForm: FormControl;
	private quantityControl: FormControl;
	public featureSelect: FormControl;
	public featureForm: FormGroup;
	public emailRequest$: Observable<any>;
	public featureList: Array<FeatureItem> = [];
	public characteristicsList: Array<FeatureItem> = [];
	public characteristicsQuantity: number = 3;
	public hiddenCharacteristics: boolean = false;
	public currentVariation: Array<any>;

	public stateEmailPopup$: BehaviorSubject<boolean>;

	public hasOrderProduct$: Observable<boolean>;

	get isLastSlide(): boolean {
		return (this._uikitslider) ? this._uikitslider.isLast : false;
	}

	get isFirstSlide(): boolean {
		return (this._uikitslider) ? this._uikitslider.isFirst : false;
	}

	get hasControls(): boolean {
		return (this._uikitslider) ? this._uikitslider.isHasControls : false;
	}



	prevSlide(): void {
		this._uikitslider.prevSlide();
	}

	nextSlide(): void {
		this._uikitslider.nextSlide();
	}


	get unitTooltip(): string {
		if (this.productData.unit_in_package && this.productData.quantity_in_one_package) {
			return `${this.productData.quantity_in_one_package} ${this.productData.unit_in_package}`;
		} else {
			return null
		}
	}

	getFeature(i) {
		const features = this.featureForm.controls["items"] as FormArray;
		const group = features.controls[i] as FormArray;
		const feature = group.controls['feature'] as FormControl;
		return feature;
	}

	constructor(
		private fb: FormBuilder,
		private store: Store<AppStore.AppStoreState>,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public appService: AppService,
		private actionsSubject: ActionsSubject,
	) {


		// slider viewed
		this.productData$ = this.store.select(getProductActiveData);
		this.companyData$ = this.store.select(getProductCompanyData);

		this.quantityControl = new FormControl(0);

		this.bulkPriceActive$ = new BehaviorSubject(0);

		this.isMobile$ =  new BehaviorSubject(false);

		this.stateEmailPopup$ = new BehaviorSubject(false);

		this.productPrice$ = new BehaviorSubject({
			price: 0,
			oldPrice: 0,
		})

		this.featureForm = this.fb.group({
			items: this.fb.array([])
		});

		this.hasOrderProduct$ = combineLatest(
			this.productData$,
			this.store.select(getOrderListOption)
		).pipe(map(list => {
			let productData = list[0];
			let orderListOption = list[1];
			if (productData && orderListOption) {
				return orderListOption.some(z => {
					return z.order_products.some(x => x.id === productData.id);
				})
			}
			else {
				return false;
			}
		}));

		this.store.select(getWindowOption).subscribe(val => {
			if (val && val.type === 'mobile') {
				document.querySelector('.app-wrapper').addEventListener('scroll', this.onScroll.bind(this));
				this.isMobile$.next(true);
			}
			else {
				this.isMobile$.next(false);
			}
		}).unsubscribe();
	}

	ngOnInit(): void {

		this.emailForm = new FormControl('', commonValidator.email);
		this.pageSubscription = new Subscription();
		this.getAilabilityDay();
		this.dataLayerInfo();

		if (this.productData.variations.length !== 0) {
			this.pageSubscription.add(
				this.activatedRoute.params.subscribe(res => {
					this.currentVariation = this.productData.variations.filter(item => {
						return item.slug === res.slug
					});
					if (this.currentVariation && this.currentVariation.length) {
						let currentFeatures = this.currentVariation[0].features.map(z => {
							return {
								attribute_name: z.attribute_name,
								attribute_option_name: z.attribute_option_name
							}
						});
						this.store.dispatch(new UpdateProductActiveCurrentFeatures(currentFeatures));
					}
					this.searchVariationFeature();
				})
			)

		} else {
			this.featureList = this.generateProductCharacteristic();
			let currentFeatures = this.featureList.map(z => {
				return {
					attribute_name: z.name,
					attribute_option_name: z.options_list[0].label
				}
			});
			this.store.dispatch(new UpdateProductActiveCurrentFeatures(currentFeatures));
			this.mainImage();
			this.getAilabilityDay();
		}

		this.quantityControl.setValue(+this.productData.min_quantity);

		this.productPrice$.next({
			price: this.productData.price,
			oldPrice: this.productData.price,
		})


		if (this.productData.show_bulk_pricing && this.productData.bulk_prices.length !== 0) {
			this.generateBulkQuantity();
		}

		this.quantityControl.valueChanges.subscribe(res => {
			let quantity = +res;
			this.store.dispatch(new UpdateProductActiveQuantity(quantity));
			this.getBulkPriceActive(quantity);
		})

		this.pageSubscription.add(this.actionsSubject.subscribe(z => {
			if (z.type === CommonActions.emailSubscriptionSuccess) {
				this.visibleEmailPopup();
			}
		}))
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if ( this._uikitslider ) {
				this._uikitslider.renderSlider();
			}
		}, 0);
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
		document.querySelector('.app-wrapper').removeEventListener('scroll', this.onScroll.bind(this));
		this.mobileBar.nativeElement.classList.remove('show');
	}

	onScroll(event) {
		if(this.isMobile$.getValue()) {
			if(event.target.scrollTop > 50 ) {
				this.mobileBar.nativeElement.classList.add('show');
			} else {
				this.mobileBar.nativeElement.classList.remove('show');
			}
		}
	}

	searchVariationFeature() {
		if (this.currentVariation.length !== 0) {
			const featureArray = this.featureForm.get('items') as FormArray;
			let sortArray = this.currentVariation[0].features.sort((a, b) => {
				return a.attribute_id - b.attribute_id
			})

			sortArray.forEach((item) => {
				featureArray.push(this.fb.group({
					feature: new FormControl({ id: item.attribute_option_id })
				}));
			})

			this.featureList = this.generateProductFeatures(this.productData.variations)
		}
	}

	generateProductCharacteristic() {
		return this.productData.features.map(item => {
			return {
				name: item.name,
				options_list: [{
					value: {
						id: item.options_list[0].id
					},
					label: item.options_list[0].name
				}]
			}
		})
	}


	removeDuplicateObjects(array: any[]) {
		return [...new Set(array.map(s => JSON.stringify(s)))]
			.map(s => JSON.parse(s));
	}

	generateProductFeatures(array) {
		let result = [];
		let variations = [];

		array.forEach(item => {
			variations.push(item.features);
		})

		let keys = this.currentVariation[0].features.map(item => {
			return {
				key_id: item.attribute_id,
				selected_id: item.attribute_option_id,
				name: item.attribute_name,
			}
		})

		let selectedKeys = {};
		keys.forEach(z => {
			selectedKeys[z.key_id] = z.selected_id;
		});

		keys.forEach(z => {

			let options_list = [];

			variations.forEach(var_list => {
				let invalidVariations = var_list.some(item => {
					if (item.attribute_id !== z.key_id && selectedKeys[item.attribute_id] !== item.attribute_option_id) {
						return true;
					}
					return false;
				});

				if (!invalidVariations) {
					let variationItem = var_list.find(item => item.attribute_id === z.key_id);
					if(variationItem) {
						options_list.push({
							label: variationItem.attribute_option_name,
							value: {
								id: variationItem.attribute_option_id
							}
						});
					}
				}
			});

			result.push({
				name: z.name,
				options_list: options_list
			});
		})

		return result;

	}

	getBulkPriceActive(quantity) {

		if (this.productData.show_bulk_pricing && this.productData.bulk_prices.length !== 0 && this.bulkPriceArray.length !== 0) {
			let bulkPriceActiveArray = this.bulkPriceArray.filter(item => {
				return item.quantityMax >= (quantity - 0.99);
			})

			if (bulkPriceActiveArray.length !== 0) {
				this.bulkPriceActive$.next(this.bulkPriceArray.indexOf(bulkPriceActiveArray[0]));
				if(this.hasControls){
					this._uikitslider.moveTo(this.bulkPriceActive$.getValue())
				}
				this.productPrice$.next({
					...this.productPrice$.value,
					price: +bulkPriceActiveArray[0].price,
				})
			} else {
				this.bulkPriceActive$.next(this.bulkPriceArray.length - 1);
				if(this.hasControls){
					this._uikitslider.moveTo(this.bulkPriceActive$.getValue())
				}
				this.productPrice$.next({
					...this.productPrice$.value,
					price: +this.bulkPriceArray[this.bulkPriceArray.length - 1].price || this.productData.price,
				})
			}
		}
	}

	showAllCharacteristics() {
		if (this.productData.variations.length === 0) {
			this.featureList = this.generateProductCharacteristic();
		} else {
			this.featureList = this.generateProductFeatures(this.productData.variations);
		}
		this.hiddenCharacteristics = true;
		this.characteristicsQuantity = this.featureList.length + 1;
	}

	hideCharacteristics() {
		this.hiddenCharacteristics = false;
		this.characteristicsQuantity = 3;
		this.featureList.slice(0, this.characteristicsQuantity);
	}

	getSamplePrice() {
		if (this.productData.has_sample) {
			return this.productData.sample_price ? `${this.productData.sample_price} ${this.productData.currency}` : 'Free'
		} else {
			return null
		}
	}

	visibleEmailPopup() {
		if (!this.appService.getIsPreviewValue()) {
			this.stateEmailPopup$.next(true);
		}
	}

	closeEmailPopup() {
		this.emailForm.setValue('');
		this.emailForm.setErrors(null);
		this.stateEmailPopup$.next(false);
	}

	visibleOrderPopupTemp() {
		if (!this.appService.getIsPreviewValue()) {

			// add new item
			let option = {};
			if(this.currentVariation && this.currentVariation.length !== 0) {
				option = {
					"product_id": this.currentVariation[0].product_id,
					"variation_id": this.currentVariation[0].id,
					"quantity": this.quantityControl.value
				}
			} else {
				option = {
					"product_id": this.productData.id,
					"quantity": this.quantityControl.value
				}
			}


			this.store.dispatch(new FetchOrderListData(option as any));

			this.pageSubscription.add(
				this.store.select(getFetchOrderData).subscribe(res => {
					if(res) {
						this.store.dispatch(new AddOrderListItem(this.store));
						this.store.dispatch(new ToggleModalVisible(true));
					}
				})
			)
		}
	}

	visibleOrderPopup() {
		if (!this.appService.getIsPreviewValue()) {
			this.store.dispatch(new AddOrderListItem(this.store));
			this.store.dispatch(new ToggleModalVisible(true));
		}
	}

	mainImage() {
		const mainImgArr = this.productData.images.filter(img => img.is_main === true);
		this.productMainImg = mainImgArr.length !== 0 ? mainImgArr[0].url : '';
	}

	generateBulkQuantity() {
		const bulkPriceArray = [...this.productData.bulk_prices, { price: 0, quantity: this.productData.available_quantity }];

		// generate new Array
		bulkPriceArray.forEach((bulk, index, arr) => {
			if (index === 0) {
				this.bulkPriceArray.push({
					quantityMin: +this.productData.min_quantity,
					quantityMax: +bulk.quantity - 1,
					price: this.productData.price,
					currency: this.productData.currency
				})
			} else if (index === arr.length - 1) {
				this.bulkPriceArray.push({
					quantityMin: +arr[index - 1].quantity,
					quantityMax: this.productData.available_quantity || 'more',
					price: +arr[index - 1].price,
					currency: this.productData.currency
				})

			} else {
				this.bulkPriceArray.push({
					quantityMin: +arr[index - 1].quantity,
					quantityMax: +bulk.quantity - 1,
					price: +arr[index - 1].price,
					currency: this.productData.currency
				})
			}
		})

		if (this.productData.available_quantity !== null) {
			this.bulkPriceArray = this.bulkPriceArray.filter(data => data.quantityMin <= this.productData.available_quantity);
		}

		setTimeout(() => {
			if ( this._uikitslider ) {
				this._uikitslider.renderSlider();
			}
		}, 0);
	}

	getAilabilityDay() {
		if (this.productData && this.productData.availability_date) {
			this.availabilityDay = moment(this.productData.availability_date).format('LL');
			return
		} else if (this.productData && this.productData.availability_days) {
			const day = moment().add(this.productData.availability_days, 'days').calendar();
			this.availabilityDay = moment(day, "MM-DD-YYYY").format('LL');;
			return
		} else {
			this.availabilityDay = '';
			return
		}
	}

	submitEmail() {
		if (this.emailForm.valid && this.emailForm.value !== '') {
			this.store.dispatch(new EmailSubscriptionFetch({ productId: this.productData.slug, email: this.emailForm.value }));
		} else {
			this.emailForm.markAsTouched();
		}
	}

	filterEventsList(event, index) {
		this.navigateToVariation();
	}

	navigateToVariation() {
		let optionsIds = this.featureForm.value.items.map(res => res.feature.id);
		this.productData.variations.forEach(item => {
			let features = item.features;
			let featuresIds = [];
			if (features.length !== optionsIds.length) {
				return;
			}

			features.forEach((feature, index) => {
				featuresIds.push(feature.attribute_option_id);
			});

			// contains two array
			if (featuresIds.every(e => optionsIds.includes(e))) {
				this.router.navigate(['product/' + item.slug]);
			}

		})

	}

	dataLayerInfo() {
		this.pageSubscription.add(
			this.companyData$.subscribe(res => {
				if (res) {
					window['dataLayer'] = window['dataLayer'] || [];
					window['dataLayer'].push({
						'event': 'autoEvent',
						'eventCategory': 'eec',
						'eventAction': 'detail',
						'ecommerce': {
							'detail': {
								'actionField': { 'list': '' },
								'products': [{
									'name': this.productData.name,
									'id': this.productData.id,
									'price': this.productData.price,
									'brand': res.name,
									'category': this.productData.high_level_categories.length !== 0 ? this.productData.high_level_categories[0].name : ''
								}]
							}
						}
					});
				}
			}))
	}

	dataLayerOrderPopup() {
		this.pageSubscription.add(
			this.companyData$.subscribe(res => {
				if (res) {
					window['dataLayer'] = window['dataLayer'] || [];
					window['dataLayer'].push({
						'event': 'autoEvent',
						'eventCategory': 'eec',
						'eventAction': 'addToCart',
						'ecommerce': {
							'currencyCode': this.productData.currency,
							'add': {
								'products': [{
									'name': this.productData.name,
									'id': this.productData.id,
									'price': this.productData.price,
									'brand': res.name,
									'category': this.productData.high_level_categories.length !== 0 ? this.productData.high_level_categories[0].name : '',
									'quantity': this.quantityControl.value
								}]
							}
						}
					});
				}
			}))
	}


}
