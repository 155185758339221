import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, forwardRef, ElementRef, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

interface OptionItem {
	label: string,
	value: any,
	[key: string]: any
}


@Component({
	selector: 'uikit-dropdown',
	templateUrl: './uikit-dropdown.component.html',
	styleUrls: ['./uikit-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UiKitDropdownComponent),
    		multi: true
		}
	],
	animations: [
		trigger('openClose', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.1s', style({ opacity: 1 })),
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('0.1s', style({ opacity: 0 })),
			])
		])
	]
})

export class UiKitDropdownComponent implements ControlValueAccessor {

	@Input() public listItemTmpl: TemplateRef<any>;
	@Input() public selectedItemTmpl: TemplateRef<any>;
	@Input() public uiClass: string;
	@Input() public uiTitle: string;
	@Input() public uiError: string;
	@Input() public uiPlaceholder: string;
	@Input() public uiEmptyText: string;
	@Input() public uiTitleVisible: boolean;
	@Input() public uiErrorVisible: boolean;
	@Input() public uiDisabled: boolean;
	@Input() public uiRequired: boolean;
	@Input() public uiTitleHideLabel: boolean;
	@Input() public tabIndex: number;

	// option
	@Input() public options: Array<OptionItem>;
	@Input() public optionLabel: string;
	@Input() public filter: boolean;

	// event
	@Output() public onChangeEvent: EventEmitter<any>;

	// state
	public filterSearch: string;
	public selectedOption: OptionItem;
	public isOpen$: BehaviorSubject<boolean>;

	// controlValueAccessor
	public onChange: Function;
	public onTouched: Function;

	// outside click
	public outsideClick: () => any;

	constructor(private elRef: ElementRef) {
		// default state
		this.uiClass = '';
		this.uiTitle = '';
		this.uiError = '';
		this.uiPlaceholder = '';
		this.uiEmptyText = '';
		this.uiTitleVisible = true;
		this.uiErrorVisible = false;
		this.uiDisabled = false;
		this.uiRequired = false;
		this.uiTitleHideLabel = false;
		this.tabIndex = 0;

		// options
		this.options = [];
		this.optionLabel = 'label';
		this.filter = false;
		this.filterSearch = '';

		// event
		this.onChangeEvent = new EventEmitter();

		// state
		this.selectedOption = null;
		this.isOpen$ = new BehaviorSubject(false);

		// controlValueAccessor
		this.onChange = () => {};
		this.onTouched = () => {};

		// ouside click
		this.outsideClick = ((event: MouseEvent) => {
			if ( !this.elRef.nativeElement.contains(event.target) ) {
				this.toggleDropdown(false);
			}
		}).bind(this);
	}

	get selectedItemText(): string {
		return (this.selectedOption) ? this.selectedOption[this.optionLabel] : this.uiPlaceholder || 'Select';
	}

	get selectedItem(): OptionItem {
		return this.selectedOption;
	}

	get filterOptions(): Array<OptionItem> {
		if (!this.filter) {
			return this.options;
		}
		else {
			try {
				let filterOptions = this.options.filter(z => {
					let optionLabel = z[this.optionLabel].toLocaleLowerCase();
					let filterSearch = this.filterSearch.toLocaleLowerCase();
					return optionLabel.includes(filterSearch);
				});
				return filterOptions;
			}
			catch(error) {
				console.warn('Warning --> UiKitDropdownComponent filterOptions', error);
				return [];
			}
		}
	}

	get emptyText(): string {
		return (this.uiEmptyText) ? this.uiEmptyText : 'Empty search result';
	}

	_outsideClickListener(addListener: boolean): void {
		if (addListener) {
			document.body.addEventListener('click', this.outsideClick);
		}
		else {
			document.body.removeEventListener('click', this.outsideClick);
		}
	}

	toggleDropdown(val?: boolean): void {
		let nextState = (val !== undefined) ? val : !this.isOpen$.getValue();
		this.isOpen$.next(nextState);
		this._outsideClickListener(nextState);
		if (!nextState) {
			setTimeout(() => {
				this.filterSearch = '';
			}, 300);
		}
	}

	selectItem(item: OptionItem): void {
		this.writeValue(item.value);
		this.onTouched();
		this.toggleDropdown(false);
	}

	writeValue(value: any): void {
		if (!value) {
			this.selectedOption = null;
			return;
		}
		try {
			let selectedOption = this.options.find(z => {
				if (typeof z.value === 'object' && typeof value === 'object') {
					let sameKey = true;
					sameKeyCycle: for (let key in z.value) {
						if (sameKey) {
							sameKey = z.value[key] === value[key];
						}
						else {
							break sameKeyCycle;
						}
					}
					return sameKey;
				}
				else {
					return z.value === value;
				}
			});
			if (selectedOption) {
				this.selectedOption = selectedOption;
				this.onChange(selectedOption.value);
				this.onChangeEvent.emit(selectedOption.value);
			}
		}
		catch(error) {
			console.warn('Warning --> UiInputDropdownComponent writeValue', error);
			return;
		}
	}

	registerOnChange(cb: Function): void {
		this.onChange = cb;
	}

	registerOnTouched(cb: Function): void {
		this.onTouched = cb;
	}
}
