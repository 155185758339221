import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';


// Getter
export const getRouterState = createFeatureSelector<RouterReducerState>('routerState');

export const getOrderPage = createSelector(
    getRouterState,
    (state: RouterReducerState) => (state && state.state.url.includes('/order')) ? true : false
);