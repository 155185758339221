import { ActionReducerMap } from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';


// State module
import * as ProductState from './product/product.state';
import * as ComapnyState from './company/company.state';
import * as CommonState from './common/common.state';
import * as OrderState from "./order/order.state";
import * as FetchOrderState from "./order/fetch-order.state";
import * as CheckoutState from './checkout/checkout.state';
import * as UserState from './user/user.state';


// Store State model
export interface AppStoreState {
    routerState: RouterReducerState
    companyState: ComapnyState.CompanyState
	productState: ProductState.ProductState,
	commonState: CommonState.CommonState,
	orderState: OrderState.OrderState,
	fetchOrderState: FetchOrderState.FetchOrderState,
	checkoutState: CheckoutState.CheckoutState,
	userState: UserState.UserState
};


// Store reducer
export const AppStoreReducers: ActionReducerMap<AppStoreState> = {
	routerState: routerReducer,
    productState: ProductState.ProductReducer,
	companyState: ComapnyState.CompanyReducer,
	commonState: CommonState.CommonReducer,
	orderState: OrderState.OrderReducer,
	fetchOrderState: FetchOrderState.FetchOrderReducer,
	checkoutState: CheckoutState.CheckoutReducer,
	userState: UserState.UserReducer
};
