import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { BaseCompanyTabData, CategoriesList, BaseData } from '@app/store/company-page.model';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { FetchCategoriesList, getCategoriesListData, FetchCompanyTabBaseData, getCompanyTabBaseData, getCompanyBaseData } from '@app/store/company/company.state';
import { Router } from '@angular/router';
import { DomainHelperService } from '@app/service/domain-helper.service.';
import { AppService } from '@app/service/app-service.service';
import { NUMBER_OF_EMPLOYEES_ARRAY, ANNUAL_TURNOVER_ARRAY } from '@app/helper/constant';
import {  } from '@app/helper/constant';
import { getWindowOption } from '@app/store/common/common.state';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { utc } from 'moment-timezone'
import { UiKitSliderComponent } from "@sub/tradalaxy_uikit/src/app/uikit/uikit-slider/uikit-slider.component";
import { wrapperScrollTop } from "@app/helper/common";

type VideoOrPhoto = {
	type: "video" | "photo";
	data: string | {
		link: string;
		preview: string;
		service: string;
		url: string;
	};
};

export type VideosAndPhotos = {
	type: 'video' | 'photo';
	data: string | { link: string | null; preview: string; service: string; url: string };
}[];

type ArrayItem = { label: string; value: string };

@Component({
	selector: 'company-tab',
	templateUrl: './company-tab.component.html',
	styleUrls: ['./company-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CompanyTab implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('photosVideoSlider', {static: false}) private _photosVideoSlider: UiKitSliderComponent;
	@ViewChild('categoriesSlider', {static: false}) private _categoriesSlider: UiKitSliderComponent;

	get isLastCategorySlide(): boolean {
		return (this._categoriesSlider) ? this._categoriesSlider.isLast : false;
	}

	get isFirstCategorySlide(): boolean {
		return (this._categoriesSlider) ? this._categoriesSlider.isFirst : false;
	}

	get hasCategoriesControls(): boolean {
		return (this._categoriesSlider) ? this._categoriesSlider.isHasControls : false;
	}

	get isLastVideosAndPhotosSlide(): boolean {
		return (this._photosVideoSlider) ? this._photosVideoSlider.isLast : false;
	}

	get isFirstVideosAndPhotosSlide(): boolean {
		return (this._photosVideoSlider) ? this._photosVideoSlider.isFirst : false;
	}

	get hasVideosAndPhotosControls(): boolean {
		return (this._photosVideoSlider) ?  this._photosVideoSlider.isHasControls : false;
	}

	get productTypesList() {
		if (this.isAllTypes) return this.baseTabValue && this.baseTabValue.product_types ? this.baseTabValue.product_types : [];
		else return this.baseTabValue && this.baseTabValue.product_types ? this.baseTabValue.product_types.slice(0, 5) : [];
	}

	get trademarksList() {
		if (this.isAllTrademarks) return this.baseTabValue && this.baseTabValue.trademarks ? this.baseTabValue.trademarks : [];
		else return this.baseTabValue && this.baseTabValue.trademarks ? this.baseTabValue.trademarks.slice(0, 7) : [];
	}

	get certificatesList() {
		if (this.isAllSertificates) return this.baseTabValue && this.baseTabValue.certificates ? this.baseTabValue.certificates : [];
		else return this.baseTabValue && this.baseTabValue.certificates ? this.baseTabValue.certificates.slice(0, 4) : [];
	}

	get creationTime() {
		return this.baseDataValue && this.baseDataValue.created_date && utc(this.baseDataValue.created_date).local().format('DD-MM-YYYY');
	}

	@ViewChild('uikitslider', {static: false}) private _uikitslider: UiKitSliderComponent;


	public numberOfEmployeesArray: ArrayItem[];
	public annualTurnoverArray: ArrayItem[];


	public baseTabData$: Observable<BaseCompanyTabData>;
	public baseTabValue: BaseCompanyTabData;
	public photosAndVideosSliderList$: BehaviorSubject<VideosAndPhotos>;
	public categoriesList$: Observable<CategoriesList>;
	public categoriesListValue: CategoriesList;
	public isAllTypes: boolean;
	public isAllTrademarks: boolean;
	public isAllSertificates: boolean;
	public getAnnualTurnover$: BehaviorSubject<ArrayItem>;
	public getEmployees$: BehaviorSubject<ArrayItem>;
	public baseDataValue: BaseData;
	public baseData$: Observable<BaseData>;
	private pageSubscription: Subscription;
	public isDesktop$: BehaviorSubject<boolean>;

	constructor(
		private cdr: ChangeDetectorRef,
		private store: Store<AppStore.AppStoreState>,
		private router: Router,
		public appService: AppService,
		private domainHelperService: DomainHelperService) {

		this.baseDataValue = null;
		this.baseData$ = this.store.select(getCompanyBaseData);
		this.baseTabData$ = this.store.select(getCompanyTabBaseData);
		this.baseTabValue = null;
		this.photosAndVideosSliderList$ = new BehaviorSubject(null);
		this.isDesktop$ = new BehaviorSubject(false);

		this.store.select(getWindowOption).subscribe( val => {
			if (val && val.type === 'mobile') {
				this.isDesktop$.next(false);
			}
			else if (val && val.type !== 'mobile') {
				this.isDesktop$.next(true);
			}
		});

		this.categoriesList$ = this.store.select(getCategoriesListData);
		this.categoriesListValue = null;
		this.isAllTypes = false;
		this.isAllTrademarks = false;
		this.isAllSertificates = false;
		this.getAnnualTurnover$ = new BehaviorSubject(null);
		this.getEmployees$ = new BehaviorSubject(null);

		this.pageSubscription = new Subscription();

		this.numberOfEmployeesArray = NUMBER_OF_EMPLOYEES_ARRAY;

		this.annualTurnoverArray = ANNUAL_TURNOVER_ARRAY;

	}

	ngOnInit(): void {

		this.pageSubscription.add(
			this.domainHelperService.getDomain().subscribe(data => {
				this.store.dispatch(new FetchCategoriesList(null));
				this.store.dispatch(new FetchCompanyTabBaseData(null));
			})
		);
		this.pageSubscription.add(
			this.categoriesList$.subscribe(data => {
				this.categoriesListValue = data
			}),

		);
		this.pageSubscription.add(
			this.baseTabData$.subscribe(data => {
				if (!data) return;
				this.baseTabValue = data;
				this.getAnnualTurnover$.next(this.annualTurnoverArray.find(item => item.value === data.annual_turnover));
				this.getEmployees$.next(this.numberOfEmployeesArray.find(item => item.value === data.number_of_employees));
				setTimeout(() => {
					this.cdr.detectChanges();
				})
			})
		);

		combineLatest(
			this.store.select(getWindowOption),
			this.store.select(getCompanyTabBaseData)
		).pipe( map( data => {
			let window = data[0];
			let baseCompanyData = data[1];
			if(window && baseCompanyData) {
				let videosAndPhotos: VideoOrPhoto[] = [];
				baseCompanyData.images.forEach(img => videosAndPhotos.push({ type: 'photo', data: img }));
				baseCompanyData.videos.forEach(video => videosAndPhotos.push({ type: 'video', data: video }));
				this.photosAndVideosSliderList$.next(videosAndPhotos);
				return null;
			} else {
				return null;
			}
		})).subscribe( data => {});

		this.pageSubscription.add(
			this.baseData$.subscribe( data => {
				this.baseDataValue = data;
			})
		)

	}

	ngAfterViewInit() {
		wrapperScrollTop();

		setTimeout(() => {
			if ( this._uikitslider ) {
				this._uikitslider.renderSlider();
			}
		}, 0);
	}

	ngOnDestroy() {
		this.pageSubscription.unsubscribe();
	}

	openCategory(category: { count: number; id: number; name: string; slug: string }) {
		if (this.appService.getIsPreviewValue()) {
			this.router.navigate(['/products/' + category.slug], { queryParams: { '__preview': 'true', 'companyDomain': this.appService.getCompanyPreviewDomainValue() } });
		} else {
			this.router.navigate(['/products/' + category.slug]);
		}
	}

	prevCategoriesSlide(): void {
		this._categoriesSlider.prevSlide();
	}

	nextCategoriesSlide(): void {
		this._categoriesSlider.nextSlide();
	}

	prevVideosAndPhotosSlide(): void {
		this._photosVideoSlider.prevSlide();
	}

	nextVideosAndPhotosSlide(): void {
		this._photosVideoSlider.nextSlide();
	}
}
