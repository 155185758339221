import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
// custom modules
import { UiKitIconModule, UiKitSliderModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";
import { RouterModule } from '@angular/router';

// component
import { PromoProductItemComponent } from "./promo-product-item/promo-product-item.component";
import { PromoProductSliderComponent } from "./promo-product-slider/promo-product-slider.component";


@NgModule({
	imports: [
		CommonModule,
		UiKitIconModule,
		UiKitSliderModule,
		RouterModule,
		TranslateModule
	],
	declarations: [
		PromoProductItemComponent,
		PromoProductSliderComponent
	],
	exports: [
		PromoProductItemComponent,
		PromoProductSliderComponent
	]
})

export class PromoProductModule { }
