import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from '@api/api.service';
import { BehaviorSubject, combineLatest } from 'rxjs';

export interface CurrencyItem {
  code: string,
  symbol: string,
  name: string,
  is_default: boolean,
  id: number
}

@Component({
  selector: 'header-currency',
  templateUrl: './header-currency.component.html',
  styleUrls: ['./header-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush 
})
export class HeaderCurrencyComponent implements OnInit {

  public currencyArray$: BehaviorSubject<CurrencyItem[]> = new BehaviorSubject([]);
  public currentCurrency$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private apiService: ApiService
    ) { }

  ngOnInit() {
    combineLatest(
			this.apiService.getCurrency(),
      this.apiService.getSesion()
		).subscribe( data => {
      let currencyArray = data[0] as CurrencyItem[];
      let sesionData = data[1];
     
      if(sesionData && sesionData['currency']) {
        currencyArray = currencyArray.map(item => {
          if(item.id === sesionData['currency'].id){
            item.is_default = true
          } else {
            item.is_default = false
          }
          return item
        })
      }

      this.currentCurrency$.next(currencyArray.find(item => item.is_default === true).code)
      this.currencyArray$.next(currencyArray);
    })
  }

  toggleCurrency(id) {
    this.apiService.setSesionCurrency(id).then(res => {
      document.location.reload(true)
    })
  }

}
