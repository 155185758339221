import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { ProductItem, BannerItem } from '@app/helper/model';
import { CategoriesList, CategoryForDropdown, Filter, ProductsListForProductItem } from '@app/store/company-page.model';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { FetchRecomendedProducts,
		 getRecomendedProductsData,
		 getCategoriesListData,
		 FetchCategoriesList,
		 getBestsellersProductsListData,
		 FetchBestsellersProductsList,
		 FetchCategoryListForDropdown,
		 getCategoryListForDropdownData,
		 FetchProductsListOfCategory,
		 getProductsListOfCategoryData,
		 FetchProductsListOfCategoryError } from '@app/store/company/company.state';
import { Router } from '@angular/router';
import { DomainHelperService } from '@app/service/domain-helper.service.';
import { AppService } from '@app/service/app-service.service';
import { BANNER_TEMP_ITEM } from '@app/helper/constant';
import { getWindowOption } from "@store/common/common.state";
import { map } from 'rxjs/operators';
import { UiKitSliderComponent } from "@sub/tradalaxy_uikit/src/app/uikit/uikit-slider/uikit-slider.component";
import { wrapperScrollTop } from "@app/helper/common";

@Component({
    selector: 'product-tab',
    templateUrl: './product-tab.component.html',
    styleUrls: ['./product-tab.component.scss']
})

export class ProductTab implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('categoriesSlider', {static: false}) private _categoriesSlider: UiKitSliderComponent;

	public recommendetProductsData$: Observable<ProductItem[]>;
	public bestsellerProductsData$: Observable<ProductItem[]>;
	public newInProductsData$: Observable<ProductsListForProductItem>;
	public newInProductsDataForPagination$: BehaviorSubject<ProductsListForProductItem>;
	public categoriesList$: Observable<CategoriesList>;
	public categoriesListValue: CategoriesList;
	public categoriesListForDropdown$: Observable<CategoryForDropdown[]>;
    public doubleBannerData: BannerItem[];
	public oneBannerData: BannerItem;
	public categoryProductsfilter: Filter;
	private _isGettingNewCategory: boolean;
	public fetchingProducts$: BehaviorSubject<boolean>;
	private _currentCategory: CategoryForDropdown;
	public isCompanyMember$: BehaviorSubject<boolean> = new BehaviorSubject(false)
	public emptyPriductArray$: BehaviorSubject<Array<ProductItem>>

	private pageSubscription: Subscription;

    get isLastCategoriesSlide(): boolean {
		return (this._categoriesSlider) ? this._categoriesSlider.isLast : false;
	}

	get isFirstCategoriesSlide(): boolean {
		return (this._categoriesSlider) ? this._categoriesSlider.isFirst : false;
	}

	get hasCategoriesControls(): boolean {
		return (this._categoriesSlider) ? this._categoriesSlider.isHasControls : false;
	}

    constructor(
		private store: Store<AppStore.AppStoreState>,
		private router: Router,
		public appService: AppService,
		private domainHelperService: DomainHelperService ) {

		this.emptyPriductArray$ = new BehaviorSubject([]);

		this.categoriesList$ = combineLatest(
			this.store.select(getWindowOption),
			this.store.select(getCategoriesListData)
		).pipe( map( data => {
			let window = data[0];
			let categories = data[1];
			
			if(categories && categories['is_company_member']) {
				this.isCompanyMember$.next(false)
			} else {
				this.isCompanyMember$.next(true)
			}

			if(categories && categories.items.length === 0) {
				this.emptyPriductArray$.next([
					{
						imgSrc: "/assets/img/empty-1.jpg",
						brand: "Сategory name",
						title: "Product name",
						oldPrice: null,
						currentPrice: 0,
						currency: "USD",
						slug: '',
					},
					{
						imgSrc: "/assets/img/empty-2.jpg",
						brand: "Сategory name",
						title: "Product name",
						oldPrice: null,
						currentPrice: 0,
						currency: "USD",
						slug: '',
					},
					{
						imgSrc: "/assets/img/empty-3.jpg",
						brand: "Сategory name",
						title: "Product name",
						oldPrice: null,
						currentPrice: 0,
						currency: "USD",
						slug: '',
					}
				])
			} else {
				this.emptyPriductArray$.next([]);
			}

			if(window && categories) {
				if( window.type === 'mobile' ) {
					let mobileCategoriesList = categories.items;
					return {...categories, items: mobileCategoriesList};
				} else {
					return categories;
				}
			} else {
				return {items: [], total: 0};
			}
		}));
		this.categoriesListValue = null;
		this.categoriesListForDropdown$ = this.store.select(getCategoryListForDropdownData);
		this.recommendetProductsData$ = this.store.select(getRecomendedProductsData);
		this.bestsellerProductsData$ = this.store.select(getBestsellersProductsListData);
		this.newInProductsData$ = this.store.select(getProductsListOfCategoryData);
		this.newInProductsDataForPagination$ = new BehaviorSubject(null);
		this.pageSubscription = new Subscription();
		this._isGettingNewCategory = true;
		this.fetchingProducts$ = new BehaviorSubject(true);

        this.doubleBannerData = [ BANNER_TEMP_ITEM, BANNER_TEMP_ITEM ];
        this.oneBannerData = BANNER_TEMP_ITEM;

		this.categoryProductsfilter  = {
			order_by: 'name',
			direction: 'asc',
			per_page: 7,
			page: 1
		}

		//костыль
		this.store.dispatch( new FetchProductsListOfCategoryError(null) );

    }

    ngOnInit(): void {
		this.pageSubscription.add(
			this.domainHelperService.getDomain().subscribe( data => {
				window.scrollTo({
					top: 0,
					behavior: "auto"
				})
				this.store.dispatch( new FetchCategoriesList(null) );
				this.store.dispatch( new FetchRecomendedProducts({limit: 8}) );
				this.store.dispatch( new FetchBestsellersProductsList({limit: 8}) );
				this.store.dispatch( new FetchCategoryListForDropdown(null) );
			})
		);

		this.pageSubscription.add(
			this.categoriesList$.subscribe( data => {
				this.categoriesListValue = data;


			})
		)
		this.pageSubscription.add(
			this.categoriesListForDropdown$.subscribe( data => {
				if(!data || !(data && data.length) ) return;
				this.getNewInProductsList(data[0]);
				this._currentCategory = data[0];
			})
		);

		this.pageSubscription.add(
			this.newInProductsData$.subscribe( data => {
				if( data ) {
					if( this._isGettingNewCategory ) {
						this.newInProductsDataForPagination$.next(null);
						this._isGettingNewCategory = false;
					}
					if( this.newInProductsDataForPagination$.value ) {
						let newProductsList: ProductItem[] = [...this.newInProductsDataForPagination$.value.data, ...data.data];
						data.data = newProductsList;
					}
					this.fetchingProducts$.next(false);
					this.newInProductsDataForPagination$.next(data);
				}
			})
		);



	}

	ngAfterViewInit() {
		wrapperScrollTop();

		setTimeout(() => {
			if ( this._categoriesSlider ) {
				this._categoriesSlider.renderSlider();
			}
		}, 0);

	}

	ngOnDestroy() {
		this.pageSubscription.unsubscribe();
	}

    prevCategoriesSlide(): void {
		this._categoriesSlider.prevSlide();
	}

	nextCategoriesSlide(): void {
		this._categoriesSlider.nextSlide();
	}

	openCategory(category: { count: number; id: number; name: string; slug: string }) {
		if(this.appService.getIsPreviewValue()){
			this.router.navigate(['/products/' + category.slug], { queryParams: { '__preview': 'true', 'companyDomain': this.appService.getCompanyPreviewDomainValue() }});
		} else {
			this.router.navigate(['/products/' + category.slug]);
		}
	}

	getNewInProductsList(category: CategoryForDropdown) {
		this.fetchingProducts$.next(true);
		this.store.dispatch( new FetchProductsListOfCategory({categoryId: category.id, filter: this.categoryProductsfilter}) );
	}

	loadMoreGoods() {
		this.categoryProductsfilter.page = this.categoryProductsfilter.page + 1;
		this.getNewInProductsList(this._currentCategory);
	}

	changeProductCategory(category: CategoryForDropdown) {
		this.categoryProductsfilter.page = 1;
		this._isGettingNewCategory = true;
		this._currentCategory = category;
		this.getNewInProductsList(category);
	}

}
