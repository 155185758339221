import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, Subscription } from 'rxjs';
import { map } from "rxjs/operators";
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import {
	getConfirmOrderId,
	getConfirmOrderBuyer,
	getConfirmOrderData,
	getConfirmOrderChatId,
	ClearActiveOrder,
	getActiveOrderListDataItem,
	getActiveOrderListOption,
	OrderListOption,
	ConfirmOrderData
} from "@store/order/order.state";
import { getUserIsLogged } from "@store/user/user.state";
import { environment } from '@env/environment';
import { wrapperScrollTop } from '@app/helper/common';


@Component({
	selector: 'order-success-page',
	templateUrl: './order-success-page.component.html',
	styleUrls: ['./order-success-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderSuccessPageComponent implements OnInit, OnDestroy, AfterViewInit {

	public confirmOrderId$: Observable<number>;
	public confirmOrderData$: Observable<ConfirmOrderData>;
	public orderSubtotal$: Observable<{ currency: string, priceTotal: number, productCount: number }>;
	public orderCompanyTime$: BehaviorSubject<string>;
	public isLoggedIn$: Observable<boolean>;
	private pageSubscription: Subscription;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private router: Router) {
		this.pageSubscription = new Subscription();
		this.confirmOrderId$ = this.store.select(getConfirmOrderId);
		this.confirmOrderData$ = this.store.select(getConfirmOrderData);
		this.orderSubtotal$ = this.store.select(getConfirmOrderData).pipe(map(z => {
			if (z) {
				let priceTotal = 0;
				z.order_products.forEach(x => {
					priceTotal += x.currentPrice;
				});
				return {
					currency: z.order_currency.code,
					productCount: z.order_products.length,
					priceTotal: priceTotal
				}
			}
			return undefined;
		}));

		this.orderCompanyTime$ = new BehaviorSubject('');
		this.isLoggedIn$ = this.store.select(getUserIsLogged);
	}

	navMyOrders(): void {
		let userCompanyId: number;
		this.store.select(getConfirmOrderBuyer).subscribe(val => {
			userCompanyId = val.id;
		}).unsubscribe();
		window.open(`${environment.tradalaxyUrl}/companies/${userCompanyId}/purchases`, '_self');
	}

	navContinueShopping(): void {
		this.router.navigate(['/']);
	}

	navContact(): void {
		let userCompanyId: number;
		let chatId: number;
		this.store.select(getConfirmOrderBuyer).subscribe(val => {
			userCompanyId = val.id;
		}).unsubscribe();
		this.store.select(getConfirmOrderChatId).subscribe(val => {
			chatId = val;
		}).unsubscribe();
		window.open(`${environment.tradalaxyUrl}/companies/${userCompanyId}/purchases/${chatId}/chat`, '_self');
	}

	ngOnInit(): void {
		this.dataLayerOrderPopup();

		this.store.dispatch(new ClearActiveOrder());
		this.confirmOrderData$.subscribe(val => {
			if (!val) {
				this.router.navigate(['products'], { replaceUrl: true })
			}
		}).unsubscribe();

		// time locale
		let ua = window.navigator.userAgent;
		let msie = ua.indexOf('Trident/');
		if (msie === -1) {
			let timeZone = '';
			this.confirmOrderData$.subscribe(val => {
				if (val) {
					timeZone = val.order_company.time_zone;
					let dateTime = new Date();
					let dateTimeOptions = {
						hour12: false,
						hour: 'numeric',
						minute: 'numeric',
						timeZone: timeZone
					};
					let orderCompanyTime = dateTime.toLocaleString('nu', dateTimeOptions);
					this.orderCompanyTime$.next(orderCompanyTime);
				}
			}).unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		wrapperScrollTop();
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}

	dataLayerOrderPopup(): void {
		combineLatest(
			this.store.select(getActiveOrderListDataItem),
			this.store.select(getActiveOrderListOption),
			this.store.select(getConfirmOrderData),
			this.store.select(getConfirmOrderId)
		).subscribe(val => {
			let orderListDataItem = val[0];
			let orderListOption: Array<OrderListOption> = val[1];
			let confirmOrderData = val[2];
			let confirmOrderId = val[3];

			if (confirmOrderData && orderListDataItem && confirmOrderData.order_company.id === orderListDataItem.company_id && confirmOrderData.order_currency.code === orderListDataItem.currency.code) {
				let orderListOptionCompany = orderListOption[0].order_company;
				let orderListOptionProduct = orderListOption[0].order_products;
				let productArray = [];
				orderListDataItem.products.forEach(z => {
					let productOption = orderListOptionProduct.find(x => x.id === z.id);
					productArray.push({
						'name': productOption.name,
						'id': productOption.id,
						'price': z.price,
						'brand': orderListOptionCompany.name,
						'category': productOption.category,
						'quantity': z.quantity
					})
				});

				window['dataLayer'] = window['dataLayer'] || [];
				window['dataLayer'].push({
					'event': 'autoEvent',
					'eventCategory': 'eec',
					'eventAction': 'purchase',
					'ecommerce': {
						'purchase': {
							'actionField': {
								'id': confirmOrderId,
								'revenue': null,
								'tax': null,
								'shipping': null
							},
							'products': productArray
						}
					}
				});
			}
		}).unsubscribe();
	}
}