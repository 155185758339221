import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";


declare let require: any;
const SVG_IMAGE = {
	'logo': require('!!raw-loader?!../../../../assets/icon/logo.svg'),
	'logo-black': require('!!raw-loader?!../../../../assets/icon/logo-black.svg'),
	'clock': require('!!raw-loader?!../../../../assets/icon/clock.svg'),
	'clock-green': require('!!raw-loader?!../../../../assets/icon/clock-green.svg'),
	'eye': require('!!raw-loader?!../../../../assets/icon/eye.svg'),
	'star': require('!!raw-loader?!../../../../assets/icon/star.svg'),
	'star_active': require('!!raw-loader?!../../../../assets/icon/star_active.svg'),
	'location': require('!!raw-loader?!../../../../assets/icon/location.svg'),
	'check_round_green': require('!!raw-loader?!../../../../assets/icon/check_round_green.svg'),
	'preorder': require('!!raw-loader?!../../../../assets/icon/preorder.svg'),
	'check_round_yellow': require('!!raw-loader?!../../../../assets/icon/check_round_yellow.svg'),
	'check_double_green': require('!!raw-loader?!../../../../assets/icon/check_double_green.svg'),
	'check_double_yellow': require('!!raw-loader?!../../../../assets/icon/check_double_yellow.svg'),
	'pay_escrow': require('!!raw-loader?!../../../../assets/icon/pay_escrow.svg'),
	'pay_matercard': require('!!raw-loader?!../../../../assets/icon/pay_matercard.svg'),
	'pay_visa': require('!!raw-loader?!../../../../assets/icon/pay_visa.svg')
};


@Component({
	selector: 'svg-img',
	templateUrl: './svg-img.component.html',
	styleUrls: ['./svg-img.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SvgImgComponent {

	// props
	@Input() public svgKey: string;

	constructor(private _domSanitizer: DomSanitizer) { }

	get svgHtml(): SafeHtml {
		if (this.svgKey && SVG_IMAGE[this.svgKey]) {
			return this._domSanitizer.bypassSecurityTrustHtml(SVG_IMAGE[this.svgKey]);
		}
		else {
			return 'EMPTY_KEY'
		}
	}
}
