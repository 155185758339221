import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// component
import { SvgImgComponent } from "./svg-img/svg-img.component";
import { SvgIconComponent } from "./svg-icon/svg-icon.component";


@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		SvgImgComponent,
		SvgIconComponent
	],
	exports: [
		SvgImgComponent,
		SvgIconComponent
	]
})

export class SvgInlineModule { }
