import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@api/api.service';
import { of } from 'rxjs';
import {
	CommonActions,
	EmailSubscription,
	EmailSubscriptionFetch,
	EmailSubscriptionSuccess,
	EmailSubscriptionError,
	BlogSubscription,
	BlogSubscriptionFetch,
	BlogSubscriptionSuccess,
	BlogSubscriptionError,
	SendSupplierForm,
	SendSupplierFormFetch,
	SendSupplierFormSuccess,
	SendSupplierFormError,
	NotificationsItem,
	Notifications,
	NotificationsSuccess,
	NotificationsError,
	CurrencySymbolItem,
	CurrencySymbolSuccess,
	CurrencySymbolError,
	IndustryItem,
	IndustrySuccess,
	IndustryError,
	CountryItem,
	CountrySuccess,
	CountryError,
} from './common.state';


@Injectable()
export class CommonEffects {

	constructor(
		private actions$: Actions,
		private apiService: ApiService
	) { }

	public emailSubscription$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.emailSubscriptionFetch),
		mergeMap((actionData: EmailSubscriptionFetch) => this.apiService.emailSubscription(actionData.payload.productId, actionData.payload.email)
		.pipe(
			map((data: any) => {
				let emailSubscription: EmailSubscription = {
					success: (data) ? data.success : undefined
				};
				return new EmailSubscriptionSuccess(emailSubscription);
			}),
			catchError((error) => {
				return of( new EmailSubscriptionError(error.errors || error))
			})
		)))
	);

	public blogSubscription$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.blogSubscriptionFetch),
		mergeMap((actionData: BlogSubscriptionFetch) => this.apiService.blogSubscription(actionData.payload.email)
		.pipe(
			map((data: any) => {
				let blogSubscription: BlogSubscription = {
					success: (data) ? data.success : undefined
				};
				return new BlogSubscriptionSuccess(blogSubscription);
			}),
			catchError((error) => {
				return of( new BlogSubscriptionError(error.errors || error))
			})
		)))
	);

	public sendSupplierForm$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.sendSupplierFormFetch),
		mergeMap((actionData: SendSupplierFormFetch) => this.apiService.sentContactSupplierForm(actionData.payload.companyId, actionData.payload.data)
		.pipe(
			map((data: any) => {
				let sendSupplierForm: SendSupplierForm = {
					success: (data) ? data.success : undefined
				};
				return new SendSupplierFormSuccess(sendSupplierForm);
			}),
			catchError((error) => {
				return of( new SendSupplierFormError(error.errors || error))
			})
		)))
	);

	public notifications$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.notifications),
		mergeMap((actionData: Notifications) => this.apiService.getAllNotifications(actionData.payload.user_id)
		.pipe(
			map((data: any) => {
				try {
					let notificationList: Array<NotificationsItem> = data.data.map((z: any) => {
						let item: NotificationsItem = {
							id: z.id,
							title: z.title,
							notification_categories: (!z.notification_categories) ? [] : z.notification_categories.map((x: any) => {
								return {
									id: x.id,
									bg_color: x.bg_color,
									name: x.name,
									slug: x.slug
								}
							}),
							created_at: z.created_at
						};
						return item;
					})
					return new NotificationsSuccess(notificationList);
				}
				catch(error) {
					console.error("Error --> notifications", error);
					throw error;
				}
			}),
			catchError((error) => {
				return of( new NotificationsError(error.errors || error))
			})
		)))
	);

	public currencySymbol$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.currencySymbol),
		mergeMap(() => this.apiService.fetchCurrencySymbol()
		.pipe(
			map((data: any) => {
				try {
					let currencySymbolList: Array<CurrencySymbolItem> = data.map((z: any) => {
						let item: CurrencySymbolItem = {
							id: z.id,
							code: z.code,
							symbol: z.symbol,
							name: z.name
						};
						return item;
					})
					return new CurrencySymbolSuccess(currencySymbolList);
				}
				catch(error) {
					console.error("Error --> currencySymbol", error);
					throw error;
				}
			}),
			catchError((error) => {
				return of(new CurrencySymbolError(error.errors || error));
			})
		)))
	);

	public industry$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.industry),
		mergeMap(() => this.apiService.getIndustry()
		.pipe(
			map((data: any) => {
				try {
					let industryList: Array<IndustryItem> = data.map((z: any) => {
						let item: IndustryItem = {
							value: {
								id: z.id
							},
							label: z.name
						}
						return item;
					})
					return new IndustrySuccess(industryList);
				}
				catch(error) {
					console.error("Error --> industry", error);
					throw error;
				}
			}),
			catchError((error) => {
				return of(new IndustryError(error.errors || error));
			})
		)))
	);

	public country$ = createEffect(() => this.actions$.pipe(
		ofType(CommonActions.country),
		mergeMap(() => this.apiService.fetchCountryList()
		.pipe(
			map((data: any) => {
				try {
					let countryList: Array<CountryItem> = data.map((z: any) => {
						let item: CountryItem = {
							value: {
								id: z.id,
								code: z.code
							},
							label: z.name
						}
						return item;
					});
					return new CountrySuccess(countryList);
				}
				catch(error) {
					console.error("Error --> country", error);
					throw error;
				}
			}),
			catchError((error) => {
				return of(new CountryError(error));
			})
		)))
	);
}