import { Component, Input, ChangeDetectionStrategy } from '@angular/core';


@Component({
	selector: 'certificate-item',
	templateUrl: './certificate-item.component.html',
	styleUrls: ['./certificate-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CertificateItemComponent {

	@Input() public certificateImg: string;
	@Input() public certificateName: string;
	@Input() public certificateDesc: string;
	@Input() public certificateLink: string;
	@Input() public certificateDoc: Array<string>;
	@Input() public certificateUrl: string;

	constructor() {
		// init state
		this.certificateImg = '';
		this.certificateName = '';
		this.certificateDesc = '';
		this.certificateLink = '';
		this.certificateUrl = '';
		this.certificateDoc = [];
	}

	get getCertificateLink(): string {
		if (this.certificateLink && this.certificateLink.length !== 0 && this.certificateLink.indexOf('http://') === -1 && this.certificateLink.indexOf('https://') === -1) {
			return `//${this.certificateLink}`.trim()
		}
		else {
			return this.certificateLink
		}
	}

	get getCertificateUrl(): string {
		if (this.certificateUrl && this.certificateUrl.length !== 0 && this.certificateUrl.indexOf('http://') === -1 && this.certificateUrl.indexOf('https://') === -1) {
			return `//${this.certificateUrl}`.trim()
		}
		else {
			return this.certificateUrl
		}
	}
}