import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import {
	OrderListOption,
	ToggleModalVisible,
	getOrderListOption
} from "@store/order/order.state";


@Component({
	selector: 'app-order',
	templateUrl: './app-order.component.html',
	styleUrls: ['./app-order.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppOrderComponent implements OnInit{

	public orderListOption$: Observable<Array<OrderListOption>>;

	constructor(private store: Store<AppStore.AppStoreState>) {
		this.orderListOption$ = this.store.select(getOrderListOption);
	}

	closeOrderModal(): void {
		this.store.dispatch(new ToggleModalVisible(false));
	}


	ngOnInit(): void {
		(<any>window).hj('trigger', 'open_cart');
	}
}
