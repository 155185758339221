import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from "rxjs/operators"
import { AchievementsItem } from "@app/helper/model";
import { SelectItem } from "@app/helper/model";
import { AppService } from '@app/service/app-service.service';
import { NUMBER_OF_EMPLOYEES_ARRAY, ANNUAL_TURNOVER_ARRAY } from "@app/helper/constant";
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getProductCompanyData, ProductCompany } from '@store/product/product.state';
import { CertificateItem } from "@app/product-page/product-page.model";
import { wrapperScrollTop } from '@app/helper/common';


@Component({
	selector: 'company-tab',
	templateUrl: './company-tab.component.html',
	styleUrls: ['./company-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CompanyTabComponent implements AfterViewInit {

	// constant
	public certificatesQuantity: number;

	// state
	public supplierModalVisible$:  BehaviorSubject<boolean>;
	public isAllSummaryDescr$: BehaviorSubject<boolean>;
	public isAllCertificate$: BehaviorSubject<boolean>;
	public quantityTrademarks$: BehaviorSubject<number>;
	public isPreview$: Observable<boolean>;
	public companyPreviewDomain$: Observable<string>;
	public companyTime$: BehaviorSubject<string>;

	// data
	public companyData$: Observable<ProductCompany>;
	public officeAddress$: Observable<Array<SelectItem<{country_id: number}>>>;
	public annualRevenue$: Observable<{label: string, value: string}>;
	public employees$: Observable<{label: string, value: string}>;
	public certificateArray$: Observable<Array<CertificateItem>>;
	public achievementsList$: BehaviorSubject<Array<AchievementsItem>>;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		public appService: AppService) {

		// constant
		this.certificatesQuantity = 3;

		// init state
		this.supplierModalVisible$ = new BehaviorSubject(false);
		this.isAllSummaryDescr$ = new BehaviorSubject(false);
		this.isAllCertificate$ = new BehaviorSubject(false);
		this.companyTime$ = new BehaviorSubject(null)
		this.quantityTrademarks$ = new BehaviorSubject(9);
		this.isPreview$ = this.appService.getIsPreview();
		this.companyPreviewDomain$ = this.appService.getCompanyPreviewDomain();

		// data
		this.companyData$ = this.store.select(getProductCompanyData);

		this.officeAddress$ = this.companyData$.pipe(map(data => {
			let filteredAddress: Array<SelectItem<{country_id: number}>> = [];
			if (data && data.office_address) {
				data.office_address.filter(adress => {
					return adress.contacts.some(contact => contact.type === 'email' && contact.value);
				})
				.forEach(address => {
					let hasAddress = filteredAddress.find(z => z.value.country_id === address.country.id);
					if (!hasAddress) {
						filteredAddress.push({
							label:  address.country ? address.country.name : 'County name is undefined',
							value: {
								country_id: address.country.id
							}
						})
					}
				})
			}
			return filteredAddress;
		}));

		this.annualRevenue$ = this.companyData$.pipe(map(data => {
			if (data) {
				return ANNUAL_TURNOVER_ARRAY.find(z => z.value === data.annual_revenue);
			}
			return undefined;
		}));

		this.employees$ = this.companyData$.pipe(map(data => {
			if (data) {
				return NUMBER_OF_EMPLOYEES_ARRAY.find(z => z.value === data.number_of_employees);
			}
			return undefined;
		}));

		this.certificateArray$ = combineLatest(
			this.companyData$,
			this.isAllCertificate$
		).pipe(map(result => {
			let companyData = result[0];
			let isAllCertificate = result[1];
			this.getTime(companyData);
			if (isAllCertificate && companyData && companyData.certificates) {
				return companyData.certificates;
			}
			else if (!isAllCertificate && companyData && companyData.certificates) {
				return companyData.certificates.slice(0, this.certificatesQuantity);
			}
			else {
				return [];
			}
		}));

		// @todo temp
		let achievementsList: Array<AchievementsItem> = new Array(10).fill('').map(z => {
			return {
				img: '/assets/content/achievements_item.png',
				text: 'Sustainable cerification'
			}
		})
		this.achievementsList$ = new BehaviorSubject(achievementsList);
		// --- //
	}

	get linkQuery(): {[key: string]: string} {
		if (this.appService.getIsPreviewValue()) {
			return { '__preview': 'true', 'companyDomain': this.appService.getCompanyPreviewDomainValue() }
		}
		return {};
	}

	ngAfterViewInit() {
		(<any>window).hj('trigger', 'open_company_tab');
		wrapperScrollTop();
	}

	visibleSupplierPopup(): void {
		if (!this.appService.getIsPreviewValue()) {
			this.supplierModalVisible$.next(true);
		}
	}

	closeSupplierPopup(): void {
		this.supplierModalVisible$.next(false);
	}

	toggleText(): void {
		let nextState = !this.isAllSummaryDescr$.getValue();
		this.isAllSummaryDescr$.next(nextState);
	}

	toggleCertificate(): void {
		let nextState = !this.isAllCertificate$.getValue();
		this.isAllCertificate$.next(nextState);
	}

	showAllTrademarks(): void {
		this.quantityTrademarks$.next(Infinity);
	}

	getTime(company: ProductCompany) {
		let timeZone = company.time_zone;
		let dateTime = new Date();
		let dateTimeOptions = {
			hour12: false,
			hour: 'numeric',
			minute: 'numeric',
			timeZone: timeZone
		};
		let companyTime = `${timeZone} ${dateTime.toLocaleString('nu', dateTimeOptions)}`;
		this.companyTime$.next(companyTime);
	}
}