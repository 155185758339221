import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NguCarouselModule } from "@ngu/carousel";

// component
import { PromoBannerItemComponent } from './promo-banner-item/promo-banner-item.component';
import { PromoBannerSliderComponent } from "./promo-banner-slider/promo-banner-slider.component";


@NgModule({
	imports: [
		CommonModule,
		NguCarouselModule
	],
	declarations: [
		PromoBannerItemComponent,
		PromoBannerSliderComponent
	],
	exports: [
		PromoBannerItemComponent,
		PromoBannerSliderComponent
	]
})

export class PromoBannerModule { }
