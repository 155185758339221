import { Component, ChangeDetectionStrategy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ErrorItem, ErrorService } from "@app/service/error-service.service";
import { Observable } from 'rxjs';


@Component({
	selector: 'app-errors',
	templateUrl: './app-errors.component.html',
	styleUrls: ['./app-errors.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.3s', style({ opacity: 1 }))
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('0.3s', style({ opacity: 0 }))
			])
		])
	]
})

export class AppErrorsComponent {

	public errorList$: Observable<Array<ErrorItem>>;

	constructor(private errorService: ErrorService) {
		this.errorList$ = this.errorService.getErrors();
	}
}
