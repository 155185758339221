import { NgModule } from '@angular/core';

// UI Modules
import { UiKitIconModule } from "./uikit-icon/uikit-icon.module";
import { UiKitInputModule } from "./uikit-input/uikit-input.module";
import { UiKitTabModule } from "./uikit-tab/uikit-tab.module";
import { UiKitSliderModule } from "./uikit-slider/uikit-slider.module";

@NgModule({
    imports: [
		UiKitIconModule,
		UiKitInputModule,
		UiKitTabModule,
		UiKitSliderModule
	],
	exports: [
		UiKitIconModule,
		UiKitInputModule,
		UiKitTabModule,
		UiKitSliderModule
	]
})

export class UiKitModule { }