import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BreadcrumbsItem } from '@app/helper/model';


@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class BreadcrumbsComponent {

	@Input() public breadcrumbsList: Array<BreadcrumbsItem>;

	constructor() {
		this.breadcrumbsList = [];
	}
}
