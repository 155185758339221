import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@api/api.service';
import { of } from 'rxjs';
import { toNumber } from "@app/helper/common";
import { MAX_QUANTITY } from "@app/helper/constant";
import {
	OrderActions,
	ConfirmGuestOrder,
	ConfirmUserOrder,
	ConfirmOrderSuccess,
	ConfirmOrderError,
	ActualOrderProductData,
	ActualOrderProduct,
	ActualOrderProductSuccess,
	ActualOrderProductError
} from "./order.state";


@Injectable()
export class OrderEffects {

	constructor(
		private actions$: Actions,
		private apiService: ApiService
	) { }

	public confirmGuestOrder$ = createEffect(() => this.actions$.pipe(
		ofType(OrderActions.confirmGuestOrder),
		mergeMap((data: ConfirmGuestOrder) => this.apiService.confirmGuestOrder(data.payload)
			.pipe(
				map((data: any) => {
					try {
						let order_id: number = data.order_id;
						return new ConfirmOrderSuccess({order_id: order_id});
					}
					catch(error) {
						console.error("Error --> confirmGuestOrder", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new ConfirmOrderError(error.errors || error));
				})
			)))
	);

	public confirmUserOrder$ = createEffect(() => this.actions$.pipe(
		ofType(OrderActions.confirmUserOrder),
		mergeMap((data: ConfirmUserOrder) => this.apiService.confirmUserOrder(data.payload)
			.pipe(
				map((data: any) => {
					try {
						let order_id: number = data.order_id;
						let chat_id: number = data.id;
						return new ConfirmOrderSuccess({order_id: order_id, chat_id: chat_id});
					}
					catch(error) {
						console.error("Error --> confirmUserOrder", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new ConfirmOrderError(error.errors || error));
				})
			)))
	);

	public actualOrderProduct$ = createEffect(() => this.actions$.pipe(
		ofType(OrderActions.actualOrderProduct),
		mergeMap((actionData: ActualOrderProduct) => this.apiService.actualOrderProduct(actionData.payload.items)
			.pipe(
				map((data: any) => {
					try {
						let productData = actionData.payload.items.map(z => {
							return {
								product_id: z.product_id,
								variation_id: z.variation_id,
								data: data.find((x: any) => x.id === z.product_id)
							}
						}).filter(z => z.data);

						let actualProductData: Array<ActualOrderProductData> = productData.map(z => {

							let commonData: any;
							let currentFeatures = [];
							let currentSlug = '';
							let data = z.data;

							if (data.variations.length) {
								let currentVariation = data.variations.find((x: any) => x.id === z.variation_id);
								commonData = currentVariation;
								currentFeatures = currentVariation.features.map((x: any) => {
									return {
										attribute_name: x.attribute_name,
										attribute_option_name: x.attribute_option_name
									}
								});
								currentSlug = currentVariation.slug;
							}
							else {
								commonData = data;
								currentFeatures = data.features.map((x: any) => {
									return {
										attribute_name: x.name,
										attribute_option_name: x.options_list[0].name
									}
								});
								currentSlug = data.slug;
							}

							let imageData = commonData.images.find((x: any) => x.is_main) || commonData.images[0];
							let productData: ActualOrderProductData = {
								id: commonData.id,
								image: (imageData) ? imageData.url : '',
								available_quantity: toNumber(commonData.available_quantity),
								minimum_order: toNumber(commonData.minimum_order),
								max_quantity: (toNumber(commonData.available_quantity) !== null && toNumber(commonData.available_quantity) < MAX_QUANTITY) ? toNumber(commonData.available_quantity) : MAX_QUANTITY,
								min_quantity: (toNumber(commonData.minimum_order) !== null && toNumber(commonData.minimum_order) > 0) ? toNumber(commonData.minimum_order) : 1,
								price: toNumber(commonData.price),
								bulk_prices: commonData.bulk_prices.map((x: any) => {
									return {
										price: toNumber(x.price),
										quantity: toNumber(x.quantity)
									}
								}),
								features: currentFeatures,
								slug: currentSlug,
								name: data.name,
								quantity_in_one_package: toNumber(data.quantity_in_one_package),
								unit: data.unit,
								show_bulk_pricing: data.show_bulk_pricing,
							}

							return productData;
						})
						return new ActualOrderProductSuccess(actualProductData);
					}
					catch(error) {
						console.error("Error --> actualOrderProduct", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new ActualOrderProductError(error.errors || error));
				})
			)))
	);
}