import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DomainHelperService } from '@app/service/domain-helper.service.';
import { Observable } from 'rxjs';
import { BaseData, BaseCompanyTabData, CategoriesList, Filter, ProductsListData, ProductItemFromApi, CategoryForDropdown, TradeInfoData } from '@app/store/company-page.model';
import { environment } from '@env/environment';
import { UserService } from '@app/service/user-service.service';
import { UserSignInData, UserSignUpData } from "@store/user/user.state";
import { GuestOrder, UserOrder } from "@app/store/order/order.state";
import { PutWarehouseData } from "@store/checkout/checkout.state";
import { SendSupplierFormData } from "@store/common/common.state";
import { FetchCardData } from '@app/store/order/fetch-order.state';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	private previewParams: HttpParams;
	private domain: string;
	private apiUrl: string;
	private marketplaceUrl: string;

	constructor(private http: HttpClient,
		private domainHelperService: DomainHelperService,
		private userService: UserService) {

		this.domainHelperService.getDomain().subscribe(data => {
			this.domain = data;
		});

		this.apiUrl = environment.apiUrl;
		this.marketplaceUrl = environment.apiMarketplaceUrl;

		this.previewParams = new HttpParams();
		if (window.location.hostname === environment.marketplaceUrl) {
			this.previewParams = this.previewParams.append("__preview", 'true');
		}
		else {
			this.previewParams = null;
		}
	}

	private getAuthorizationHeader() {
		return {
			'Authorization': `Bearer ${this.userService.getToken()}`
		}
	}

	// Companies
	getBaseInfo(): Observable<BaseData> {
		return this.http.get<BaseData>(`${this.marketplaceUrl}companies/${this.domain}/base`);
	}

	getCompanyTabInfo(): Observable<BaseCompanyTabData> {
		return this.http.get<BaseCompanyTabData>(`${this.marketplaceUrl}companies/${this.domain}/summary`);
	}

	getCategories(): Observable<CategoriesList> {
		return this.http.get<CategoriesList>(`${this.marketplaceUrl}companies/${this.domain}/products/main-categories`);
	}

	getProductsData(slug: string, filter: Filter): Observable<ProductsListData> {
		let params = new HttpParams();
		params = params.append('direction', filter.direction);
		params = params.append('order_by', filter.order_by);
		params = params.append('per_page', filter.per_page.toString());
		params = params.append('page', filter.page.toString());
		return this.http.get<ProductsListData>(`${this.marketplaceUrl}companies/${this.domain}/products/high-category/${slug}`, { params });
	}

	getRecomendedProductsData(limit: number): Observable<ProductItemFromApi[]> {
		return this.http.get<ProductItemFromApi[]>(`${this.marketplaceUrl}companies/${this.domain}/products/recommend?limit=${limit}`);
	}

	getBestsellersProductsData(limit: number): Observable<ProductItemFromApi[]> {
		return this.http.get<ProductItemFromApi[]>(`${this.marketplaceUrl}companies/${this.domain}/products/bestsellers?limit=${limit}`);
	}

	getPopularProductsData(limit: number): Observable<{ data: ProductItemFromApi[] }> {
		return this.http.get<{ data: ProductItemFromApi[] }>(`${this.marketplaceUrl}companies/${this.domain}/products/popular?limit=${limit}`);
	}

	getCategoryListForDropdown(): Observable<{ data: CategoryForDropdown[] }> {
		return this.http.get<{ data: CategoryForDropdown[] }>(`${this.marketplaceUrl}companies/${this.domain}/category-list`);
	}

	getProductListOfCategory(category_id: number, filter: Filter): Observable<ProductsListData> {
		let params = new HttpParams();
		params = params.append('per_page', filter.per_page.toString());
		params = params.append('page', filter.page.toString());
		return this.http.get<ProductsListData>(`${this.marketplaceUrl}companies/${this.domain}/category/${category_id}`, { params });
	}

	getTradeInfoData(): Observable<TradeInfoData> {
		return this.http.get<TradeInfoData>(`${this.marketplaceUrl}companies/${this.domain}/trade-info`);
	}
	// --- //











	// Common
	sentContactSupplierForm(companyId: number, data: SendSupplierFormData) {
		return this.http.post(`${this.marketplaceUrl}companies/${companyId}/contact`, data);
	}

	getAllNotifications(user_id: number) {
		let params = new HttpParams();
		params = params.append('page', '1');
		params = params.append('per_page', '3');
		return this.http.get(`${this.apiUrl}users/${user_id}/notifications/`, {
			params: params,
		});
	}

	fetchCurrencySymbol() {
		return this.http.get(`${this.apiUrl}currencies`)
	}

	getIndustry() {
		return this.http.get(`${this.apiUrl}high-level-category`);
	}

	fetchCountryList() {
		return this.http.get(`${this.apiUrl}locations/countries`)
	}
	// --- //

	// Subscription
	emailSubscription(slug: string, email: string) {
		return this.http.post(`${this.marketplaceUrl}products/${slug}/notify-when-available`, {
			email
		})
	}

	blogSubscription(email: string) {
		return this.http.post(`${this.apiUrl}blog/subscribe`, {
			email
		})
	}
	// --- //

	// Product
	fetchProductActive(productSlug: string | number) {
		return this.http.get(`${this.marketplaceUrl}products/${productSlug}`, { params: this.previewParams, headers: { errorRedirect: 'true', notErrorMessage: 'true' } })
	}

	fetchProductCompany(productSlug: string | number) {
		return this.http.get(`${this.marketplaceUrl}products/${productSlug}/company`, { params: this.previewParams, headers: { notErrorMessage: 'true' } })
	}

	fetchProductLogistic(productSlug: string | number) {
		return this.http.get(`${this.marketplaceUrl}products/${productSlug}/logistic`, { params: this.previewParams, headers: { notErrorMessage: 'true' } })
	}

	fetchProductSliderSeller(id: number) {
		return this.http.get(`${this.marketplaceUrl}products/${id}/more-from-seller`)
	}

	fetchProductSliderViewed(id: number) {
		return this.http.get(`${this.marketplaceUrl}products/${id}/also-viewed`)
	}
	// --- //

	// User
	getUser(id: any) {
		return this.http.get(`${this.apiUrl}users/${id}`)
	}

	userSignIn(option: UserSignInData) {
		let headersOption = {
			notErrorMessage: 'true'
		};
		return this.http.post(`${this.apiUrl}auth?_embed=users,companies`, { ...option }, { headers: headersOption });
	}

	userSignUp(option: UserSignUpData) {
		let headersOption = {
			notErrorMessage: 'true'
		};
		return this.http.post(`${this.marketplaceUrl}auth/sign_up`, { ...option }, { headers: headersOption });
	}

	checkSignUpEmail(email: string) {
		let headersOption = {
			notErrorMessage: 'true'
		};
		return this.http.post<{ exist: boolean }>(`${this.apiUrl}users/email-exists`, { email: email }, { headers: headersOption });
	}

	getUserCountry() {
		return this.http.get(`${this.apiUrl}auth/country-info`);
	}

	forgotPassword(email: string) {
		let headersOption = {
			notErrorMessage: 'true'
		};
		return this.http.post(`${this.apiUrl}auth/password/forgot`, { email: email }, { headers: headersOption });
	}
	// --- //

	// Checkout
	fetchCompanyWarehouse(companyId: number) {
		return this.http.get(`${this.apiUrl}companies/${companyId}/warehouse`);
	}

	putWarehouse(option: PutWarehouseData) {
		const { company_id } = { ...option };
		return this.http.put(`${this.apiUrl}companies/${company_id}/warehouse`, { ...option })
	}

	fetchCheckoutEntities() {
		return this.http.get(`${this.apiUrl}checkout/entities`);
	}

	fetchCityList(countryId: number, query: string) {
		return this.http.get(`${this.apiUrl}locations/countries/${countryId}/cities?limit=20&q=${query}`);
	}

	fetchPortList(countryId: number, cityId: number) {
		return this.http.get(`${this.apiUrl}locations/countries/${countryId}/cities/${cityId}/ports?limit=20`);
	}

	fetchTerminalList(countryId: number, cityId: number) {
		return this.http.get(`${this.apiUrl}locations/countries/${countryId}/cities/${cityId}/terminals?limit=20`);
	}
	// --- //

	// Order
	confirmGuestOrder(option: GuestOrder) {
		return this.http.post(`${this.marketplaceUrl}guest/order`, { ...option });
	}

	confirmUserOrder(option: UserOrder) {
		return this.http.post(`${this.marketplaceUrl}order`, { ...option });
	}

	actualOrderProduct(items: Array<{ product_id: number, variation_id: number }>) {
		let headersOption = {
			notErrorMessage: 'true'
		};
		return this.http.post(`${this.marketplaceUrl}products/actual`, { items: items }, { headers: headersOption });
	}

	fetchOrderData(option: FetchCardData) {
		const requestOptions = {
			headers: new HttpHeaders({
				notErrorMessage: 'true'
			})
		};

		return this.http.post(`${this.apiUrl}cart`, option, requestOptions);
	}

	getOrderData() {
		const requestOptions = {
			headers: new HttpHeaders({
				notErrorMessage: 'true'
			})
		};

		return this.http.get(`${this.apiUrl}cart`, requestOptions);
	}
	// --- //



	getSesion() {
		const requestOptions = {
			headers: new HttpHeaders({
				notErrorMessage: 'true'
			})
		};
		return this.http.get(`${this.apiUrl}session/`, requestOptions).toPromise()
	}

	setSesionCurrency(id) {
		return this.http.post(`${this.apiUrl}session/currency`, {
			currency_id: id
		}).toPromise()
	}

	getCurrency() {
		return this.http.get(`${this.apiUrl}currencies/switcher/`).toPromise()
	}
}