import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { orderValidator } from "@app/helper/validator";
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getUserCountry } from "@store/user/user.state";
import { HttpClient } from '@angular/common/http';
import {
	IndustryItem,
	TypeCompanyItem,
	CountryItem,
	getTypeCompany,
	getIndustry,
	getCountry
} from "@store/common/common.state";
import {
	CityItem,
	TerminalItem,
	PortItem,
	getCityListData,
	getTerminalListData,
	getPortListData,
	CityList,
	PortList,
	PortListSuccess,
	TerminalList,
	TerminalListSuccess,
	CheckoutEntities,
	getCheckoutIncoterms,
	CityListSuccess,
	getCheckoutEntities
} from "@store/checkout/checkout.state";
import {
	GuestOrder,
	ConfirmGuestOrder,
	OrderProductItem,
	getActiveOrderListDataItem,
	OrderSubtotal
} from "@store/order/order.state";
import { SelectItem } from "@app/helper/model";
import { getPrePaymentList } from "@app/helper/common";
import { environment, IEnv } from '@env/environment';


@Component({
	selector: 'guest-order',
	templateUrl: './guest-order.component.html',
	styleUrls: ['./guest-order.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class GuestOrderComponent implements OnInit, OnDestroy {

	@Input() public orderSubtotal: Observable<OrderSubtotal>;

	private pageSubscription: Subscription;
	public intlTelObject: any;

	public preloader$: BehaviorSubject<boolean>;
	public isReady$: BehaviorSubject<boolean>;
	public paymentTypeOther$: BehaviorSubject<boolean>;
	public paymentTypePartial$: BehaviorSubject<boolean>;
	public typeCompanyList$: BehaviorSubject<Array<TypeCompanyItem>>;
	public industryList$: BehaviorSubject<Array<any>>;
	public industryArray$: BehaviorSubject<Array<any>>;
	public countryList$: Observable<Array<CountryItem>>;
	public cityList$: Observable<Array<CityItem>>;
	public terminalList$: Observable<Array<TerminalItem>>;
	public portList$: Observable<Array<PortItem>>;
	public paymentTypesList$: BehaviorSubject<Array<SelectItem<{id: number, slug: string}>>>;
	public incotermsList$: BehaviorSubject<Array<SelectItem<{id: number}>>>;
	public deliveryTypesList$: BehaviorSubject<Array<SelectItem<{id: number}>>>;
	public prePaymentList$: BehaviorSubject<Array<SelectItem<{id: number, key: string}>>>;
	public env: IEnv;

	public orderForm: FormGroup;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private http: HttpClient,
		private changeDetectorRef: ChangeDetectorRef) {

		// init state
		this.pageSubscription = new Subscription();
		this.preloader$ = new BehaviorSubject(false);
		this.isReady$ = new BehaviorSubject(false);
		this.paymentTypeOther$ = new BehaviorSubject(false);
		this.paymentTypePartial$ = new BehaviorSubject(false);

		this.typeCompanyList$ = new BehaviorSubject([]);
		this.industryList$ = new BehaviorSubject([]);
		this.industryArray$ = new BehaviorSubject([]);
		this.countryList$ = this.store.select(getCountry);
		this.cityList$ = this.store.select(getCityListData);
		this.terminalList$ = this.store.select(getTerminalListData);
		this.portList$ = this.store.select(getPortListData);

		this.incotermsList$ = new BehaviorSubject([]);
		this.deliveryTypesList$ = new BehaviorSubject([]);
		this.paymentTypesList$ = new BehaviorSubject([]);
		this.prePaymentList$ = new BehaviorSubject([]);
		this.env = environment;

		this.initOrderForm();
		this.addSubscription();


	}

	get orderFormControl(): {[key: string]: AbstractControl} {
		return this.orderForm.controls;
	}

	detectChanges(): void {
		try {
			this.changeDetectorRef.detectChanges();
		}
		catch(error) {
			console.warn('DetectChanges Error', error);
		}
	}

	initOrderForm(): void {
		this.orderForm = new FormGroup({
			email: new FormControl('', orderValidator.email),
			phone: new FormControl('', Validators.required),
			first_name: new FormControl('', orderValidator.firstName),
			last_name: new FormControl('', orderValidator.lastName),
			company_name: new FormControl('', orderValidator.companyName),
			country: new FormControl('', Validators.required),
			company_type: new FormControl('', Validators.required),
			industry: new FormControl('', Validators.required),
			incoterm: new FormControl('', Validators.required),
			delivery_type: new FormControl('', Validators.required),
			delivery_address: new FormControl('', orderValidator.deliveryAddress),
			warehouse_country: new FormControl('', Validators.required),
			warehouse_city: new FormControl('', Validators.required),
			warehouse_terminal: new FormControl('', Validators.required),
			warehouse_port: new FormControl('', Validators.required),
			payment_type: new FormControl('', Validators.required),
			payment_other: new FormControl(''),
			prepayment_amount: new FormControl(null),
			prepayment_select: new FormControl(null),
			term_of_use: new FormControl(false, [Validators.required, Validators.pattern('true')]),
			privacy_policy: new FormControl(false)
		}, (form: FormGroup) => {
			if (form.controls.prepayment_amount.valid && form.controls.prepayment_select.valid) {
				if (!this.paymentTypeOther$.getValue()) {
					let prepaymentAmount = form.controls.prepayment_amount.value !== null ? form.controls.prepayment_amount.value : null;
					let prepaymentSelect = form.controls.prepayment_select.value !== null ? form.controls.prepayment_select.value.id : null;
					if ((prepaymentAmount === null || prepaymentSelect === null) || (prepaymentSelect === 2 && prepaymentAmount > 100)) {
						return { amountError: 'validations.prepayment_amount_error' };
					}
				}
				return null;
			}
			return null;
		});

		this.pageSubscription.add(
			this.orderFormControl.phone.valueChanges.subscribe(val => {
				if (!val.length) {
					this.orderFormControl.phone.setErrors({ errorName: 'validations.telephone_required' });
				}
				else {
					this.validPhone();
				}
			})
		);
		this.pageSubscription.add(
			this.orderFormControl.incoterm.valueChanges.subscribe(val => {
				this.setDeliveryTypesList(val.id);
			})
		);
		this.pageSubscription.add(
			this.orderFormControl.warehouse_country.valueChanges.subscribe(() => {
				this.orderFormControl.warehouse_city.setValue('');
				this.orderFormControl.warehouse_terminal.setValue('');
				this.orderFormControl.warehouse_port.setValue('');
			})
		);
		this.pageSubscription.add(
			this.orderFormControl.warehouse_city.valueChanges.subscribe(val => {
				if (val) {
					let countryId = this.orderFormControl.warehouse_country.value.id;
					let cityId = val.id;
					this.orderFormControl.warehouse_terminal.setValue('');
					this.orderFormControl.warehouse_port.setValue('');
					this.store.dispatch(new PortList({countryId: countryId, cityId: cityId}));
					this.store.dispatch(new TerminalList({countryId: countryId, cityId: cityId}));
				}
				else {
					this.orderFormControl.warehouse_terminal.setValue('');
					this.orderFormControl.warehouse_port.setValue('');
					this.store.dispatch(new PortListSuccess([]));
					this.store.dispatch(new TerminalListSuccess([]));
				}
			})
		);
		this.pageSubscription.add(
			this.orderFormControl.term_of_use.valueChanges.subscribe(() => {
				if (!this.orderFormControl.term_of_use.touched) {
					this.orderFormControl.term_of_use.markAsTouched()
				}
			})
		);
		this.pageSubscription.add(
			this.orderFormControl.payment_type.valueChanges.subscribe(val => {
				if (val.slug === 'other') {
					this.paymentTypePartial$.next(false);
					this.paymentTypeOther$.next(true);

					this.orderFormControl.payment_other.setValidators(orderValidator.paymentOther);
					this.orderFormControl.prepayment_amount.clearValidators();
					this.orderFormControl.prepayment_select.clearValidators();
					this.orderFormControl.prepayment_amount.setErrors(null);
					this.orderFormControl.prepayment_select.setErrors(null);
				}
				else if (val.slug === 'partial_pre_payment') {
					this.paymentTypeOther$.next(false);
					this.paymentTypePartial$.next(true);

					this.orderFormControl.prepayment_amount.setValidators(orderValidator.prepaymentAmount);
					this.orderFormControl.prepayment_select.setValidators(Validators.required);
					this.orderFormControl.payment_other.clearValidators();
					this.orderFormControl.payment_other.setErrors(null);
				}
				else {
					this.paymentTypeOther$.next(false);
					this.paymentTypePartial$.next(false);

					this.orderFormControl.payment_other.clearValidators();
					this.orderFormControl.prepayment_amount.clearValidators();
					this.orderFormControl.prepayment_select.clearValidators();
					this.orderFormControl.payment_other.setErrors(null);
					this.orderFormControl.prepayment_amount.setErrors(null);
					this.orderFormControl.prepayment_select.setErrors(null);
				}
			})
		);
		this.pageSubscription.add(
			this.orderFormControl.prepayment_select.valueChanges.subscribe(() => {
				this.orderFormControl.prepayment_amount.setValue('');
			})
		);
	}

	addSubscription(): void {
		this.pageSubscription.add(
			this.deliveryTypesList$.pipe(debounceTime(300)).subscribe(val => {
				if (val.length) {
					let currentDeliveryType = this.orderFormControl.delivery_type.value;
					let hasDeliveryType = val.find(z => z.value.id === currentDeliveryType.id);
					if (hasDeliveryType) {
						this.orderFormControl.delivery_type.setValue(hasDeliveryType.value);
					}
					else {
						this.orderFormControl.delivery_type.setValue(val[0].value);
					}
				}
				else {
					this.orderFormControl.delivery_type.setValue('');
				}
			})
		);
	}

	setIntlTelObject(data: any): void {
		this.intlTelObject = data;
	}

	setIntlCountryChange(): void {
		this.validPhone();
	}

	validPhone(): void {
		if (this.intlTelObject) {
			let isValid = this.intlTelObject.isValidNumber();
			if (!isValid) {
				this.orderFormControl.phone.setErrors({ errorName: 'validations.telephone_error' });
			}
			else {
				this.orderFormControl.phone.setErrors(null);
			}
		}
	}

	setDeliveryTypesList(incotermsId: number): void {
		this.store.select(getCheckoutIncoterms).subscribe(list => {
			list.some(z => {
				if (z.id === incotermsId) {
					let deliveryTypesList = z.delivery_types.map(x => {
						return {
							label: x.name,
							value: {
								id: x.id
							}
						}
					});
					this.deliveryTypesList$.next(deliveryTypesList);
					return true;
				}
			})
		}).unsubscribe();
	}

	setUserCountry(): void {
		this.store.select(getUserCountry).subscribe(userCountry => {
			if (userCountry) {
				this.orderFormControl.country.setValue({
					id: userCountry.id,
					code: userCountry.code
				});
			}
		}).unsubscribe();
	}

	setPrePaymentList(): void {
		this.orderSubtotal.subscribe(val => {
			let prePaymentList = getPrePaymentList(val.currency);
			this.prePaymentList$.next(prePaymentList);
			this.orderFormControl.prepayment_select.setValue(prePaymentList[0].value);
		}).unsubscribe();
	}

	setTypeCompany(): void {
		this.typeCompanyList$.subscribe(list => {
			if (list && list.length) {
				this.orderFormControl.company_type.setValue(list[0].value);
			}
		}).unsubscribe();
	}

	fetchCity(query: string): void {
		let countryId = this.orderFormControl.warehouse_country.value.id;
		if (countryId) {
			this.store.dispatch(new CityList({countryId: countryId, query: query}));
		}
		else {
			this.store.dispatch(new CityListSuccess([]));
		}
	}

	orderFormSubmit(): void {
		this.orderForm.markAllAsTouched();
		this.detectChanges();
		if (this.orderForm.valid) {

			// User
			let user_time_zone: string;
			this.store.select(getUserCountry).subscribe(userCountry => {
				user_time_zone = userCountry.time_zone;
			}).unsubscribe();

			// Product
			let productItems: Array<OrderProductItem>;
			let company_id: number;
			this.store.select(getActiveOrderListDataItem).subscribe(val => {
				company_id = val.company_id;
				productItems = val.products.map(z => {
					return {
						product_id: z.product_id,
						variation_id: z.variation_id,
						quantity: z.quantity
					}
				})
			}).unsubscribe();

			let phone = this.orderFormControl.phone.value.replace(/[\s\+\-\(\)]/g, '');
			let prepayment_amount = null;
			let prepayment_type = null;
			let payment_other = '';
			if (this.paymentTypeOther$.getValue()) {
				payment_other = this.orderFormControl.payment_other.value;
			}
			else if (this.paymentTypePartial$.getValue()) {
				prepayment_amount = this.orderFormControl.prepayment_amount.value;
				prepayment_type = this.orderFormControl.prepayment_select.value.key;
			}

			let guestOrder: GuestOrder = {
				user: {
					email: this.orderFormControl.email.value,
					first_name: this.orderFormControl.first_name.value,
					last_name: this.orderFormControl.last_name.value,
					phone: phone,
					time_zone: user_time_zone
				},
				company: {
					name: this.orderFormControl.company_name.value,
					country_id: this.orderFormControl.country.value.id,
					business_type_id: this.orderFormControl.company_type.value.id,
					industries: [this.orderFormControl.industry.value.id]
				},
				seller_company_id: company_id,
				delivery_type_id: this.orderFormControl.delivery_type.value.id,
				incoterm_id: this.orderFormControl.incoterm.value.id,
				delivery_address: this.orderFormControl.delivery_address.value,
				payment_type_id: this.orderFormControl.payment_type.value.id,
				prepayment_amount: prepayment_amount,
				prepayment_type: prepayment_type,
				payment_other: payment_other,
				warehouse: {
					country_id: this.orderFormControl.warehouse_country.value.id,
					city_id: this.orderFormControl.warehouse_city.value.id,
					terminal_id: this.orderFormControl.warehouse_terminal.value.id,
					port_id: this.orderFormControl.warehouse_port.value.id
				},
				items: productItems
			};

			this.store.dispatch(new ConfirmGuestOrder(guestOrder));
		}
		else {
			let invalidControl = '';
			if (this.orderForm.hasError('amountError')) {
				invalidControl = 'prepayment_amount';
			}
			else {
				cycle: for (let key in this.orderForm.controls) {
					if ( this.orderForm.controls[key].invalid ) {
						invalidControl = key;
						break cycle;
					}
				}
			}

			// scroll
			try {
				let elem;
				let scrollElem = document.querySelector('.app-wrapper');

				if (invalidControl === 'term_of_use') {
					elem = document.querySelector(`[formControlName=${invalidControl}]`).parentElement;
				}
				else {
					elem = document.querySelector(`[formControlName=${invalidControl}]`);
				}

				if (elem && scrollElem) {
					let currentScroll = scrollElem.scrollTop;
					let elemTop = elem.getBoundingClientRect().top;
					let top = (currentScroll + elemTop) - 50;
					scrollElem.scrollTo({
						left: 0,
						top: top,
						behavior: 'smooth'
					});
				}
			}
			catch(error) {
				console.warn("Warning --> scroll error", error);
			}
		}
	}

	fetchCompanyType() {
		this.http.get(`${this.env.apiUrl}companies/business-types/`).toPromise().then(res => {
			if(res) {
				let data = res as any[];
				let industriesArrays = data.map(item => {
					return {
						id: item.id,
						array: item.industries.map(elem => {
							return {
								value: {
									id: elem.id
								},
								label: elem.name
							}
						})
					}
				})
				this.industryArray$.next(industriesArrays);
				
				let companyType = data.map(item => {
					return {
						value: {
							id: item.id
						},
						label: item.name
					}
				})
				this.typeCompanyList$.next(companyType);
			}
		})
	}

	setIndustry(event): void {
		if(this.industryArray$.value.length !== 0) {
			let currentIndustryArray = this.industryArray$.value.find(item => event.id === item.id);
			this.industryList$.next(currentIndustryArray.array);
			this.orderForm.get('industry').setValue('');
		}
		this.detectChanges();
	}

	ngOnInit(): void {
		window.location.hash = '#guest_purchase'

		this.setUserCountry();
		this.setPrePaymentList();
		this.fetchCompanyType();

		let initSub = false;
		this.pageSubscription.add(
			this.store.select(getCheckoutEntities).subscribe(val => {
				if (initSub) {
					return;
				}

				let incoterms = val.incoterms;
				let paymentTypes = val.paymentTypes;
				if (incoterms.length && paymentTypes.length) {
					let incotermsList = incoterms.map(z => {
						return {
							label: z.name,
							value: {
								id: z.id
							}
						}
					});
					let paymentTypesList = paymentTypes.map(z => {
						return {
							label: z.name,
							value: {
								id: z.id,
								slug: z.slug
							}
						}
					});

					initSub = true;
					this.incotermsList$ = new BehaviorSubject(incotermsList);
					this.paymentTypesList$ = new BehaviorSubject(paymentTypesList);
					this.orderFormControl.incoterm.setValue(incotermsList[0].value);
					this.orderFormControl.payment_type.setValue(paymentTypesList[0].value);
					setTimeout(() => {
						this.isReady$.next(true);
						setTimeout(() => {
							if (this.intlTelObject) {
								let countryCode = this.orderFormControl.country.value.code;
								if (countryCode) {
									this.intlTelObject.setCountry(countryCode);

									let {dialCode} = this.intlTelObject.getSelectedCountryData();
									this.orderFormControl.phone.setValue(`+${dialCode}`);
								}
							}
						}, 100)
					}, 300);
				}
				else if (!val.requestState.isFetch) {
					this.store.dispatch(new CheckoutEntities());
				}
			})
		)
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
		window.location.hash = ''
	}
}