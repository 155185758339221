import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


declare let require: any;
const SVG_ICON = {
	'visibility': require('!!raw-loader?!./icon/visibility.svg'),
	'visibility_line': require('!!raw-loader?!./icon/visibility_line.svg'),
	'facebook': require('!!raw-loader?!./icon/facebook.svg'),
	'facebook_base': require('!!raw-loader?!./icon/facebook_base.svg'),
	'gmail': require('!!raw-loader?!./icon/gmail.svg'),
	'linkedin': require('!!raw-loader?!./icon/linkedin.svg'),
	'linkedin_base': require('!!raw-loader?!./icon/linkedin_base.svg'),
	'other': require('!!raw-loader?!./icon/other.svg'),
	'other_base': require('!!raw-loader?!./icon/other_base.svg'),
	'twitter': require('!!raw-loader?!./icon/twitter.svg'),
	'twitter_base': require('!!raw-loader?!./icon/twitter_base.svg'),
	'website': require('!!raw-loader?!./icon/website.svg'),
	'medium': require('!!raw-loader?!./icon/medium.svg'),
	'website_base': require('!!raw-loader?!./icon/website_base.svg'),
	'check': require('!!raw-loader?!./icon/check.svg'),
	'close': require('!!raw-loader?!./icon/close.svg'),
	'arrow_back_small': require('!!raw-loader?!./icon/arrow_back_small.svg'),
	'arrow_next_small': require('!!raw-loader?!./icon/arrow_next_small.svg'),
	'arrow_back_big': require('!!raw-loader?!./icon/arrow_back_big.svg'),
	'arrow_next_big': require('!!raw-loader?!./icon/arrow_next_big.svg'),
	'chevron_down': require('!!raw-loader?!./icon/chevron_down.svg'),
	'chevron_up': require('!!raw-loader?!./icon/chevron_up.svg'),
	'chevron_left': require('!!raw-loader?!./icon/chevron_left.svg'),
	'chevron_right': require('!!raw-loader?!./icon/chevron_right.svg'),
	'search': require('!!raw-loader?!./icon/search.svg'),
	'mark': require('!!raw-loader?!./icon/mark.svg'),
	'share': require('!!raw-loader?!./icon/share.svg'),
	'download': require('!!raw-loader?!./icon/download.svg'),
	'info': require('!!raw-loader?!./icon/info.svg'),
	'increment': require('!!raw-loader?!./icon/increment.svg'),
	'decrement': require('!!raw-loader?!./icon/decrement.svg'),
	'link': require('!!raw-loader?!./icon/link.svg'),
	'select_arrow': require('!!raw-loader?!./icon/select_arrow.svg')
};


@Component({
  selector: 'uikit-icon',
  templateUrl: './uikit-icon.component.html',
  styleUrls: ['./uikit-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiKitIconComponent {

	@Input() public uikitSvgKey: string;
	@Input() public uikitClass: string;

	constructor(private domSanitizer: DomSanitizer) {
		// init state
		this.uikitSvgKey = '';
		this.uikitClass = '';
	}

	get svgHtml(): SafeHtml {
		if (this.uikitSvgKey && SVG_ICON[this.uikitSvgKey]) {
			return this.domSanitizer.bypassSecurityTrustHtml(SVG_ICON[this.uikitSvgKey]);
		}
		else {
			return 'EMPTY_KEY'
		}
	}
}
