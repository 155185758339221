import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ProductItem, BannerItem } from '@app/helper/model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProductsListForProductItem, Filter, ProductItemFromApi } from '@app/store/company-page.model';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { 	FetchNewInCategoryProducts,
			getNewInCategoryProductsData,
			getAllProductsInCategoryData,
			FetchPopularProducts,
			getPopularProductsData,
			FetchAllProductsInCategory,
			FetchAllProductsInCategoryError
		} from '@app/store/company/company.state';
import { Router } from '@angular/router';
import { DomainHelperService } from '@app/service/domain-helper.service.';
import { AppService } from '@app/service/app-service.service';
import { BANNER_TEMP_ITEM } from '@app/helper/constant';
import { wrapperScrollTop } from "@app/helper/common";

@Component({
	selector: 'inside-category',
	templateUrl: './inside-category.component.html',
	styleUrls: ['./inside-category.component.scss']
})

export class InsideCategory implements OnInit, AfterViewInit, OnDestroy {

	// categoryId: number;
	public slug: string;

	public oneBannerData: BannerItem;
	public newInProductsData$: Observable<ProductsListForProductItem>;
	public allProductsData$: Observable<ProductsListForProductItem>;
	public allProductsForPagiantion$: BehaviorSubject<ProductsListForProductItem>;
	public recommendetProductsData$: Observable<ProductItem[]>;
	public newInFilter: Filter;
	public allProductsfilter: Filter;
	private pageSubscription: Subscription;
	private isSorting: boolean;
	public isLoadingAllProducts$: BehaviorSubject<boolean>;
	public emptyBlock$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private router: Router,
		public appService: AppService,
		private domainHelperService: DomainHelperService ) {
		this.pageSubscription = new Subscription();
		this.newInProductsData$ = this.store.select(getNewInCategoryProductsData);
		this.allProductsData$ = this.store.select(getAllProductsInCategoryData);
		this.allProductsForPagiantion$ = new BehaviorSubject(null);
		this.recommendetProductsData$ = this.store.select(getPopularProductsData);

		this.isLoadingAllProducts$ = new BehaviorSubject(false);
		this.newInFilter  = {
			order_by: 'created_date',
			direction: 'desc',
			per_page: 15,
			page: 1
		}
		this.allProductsfilter  = {
			order_by: 'name',
			direction: 'asc',
			per_page: 11,
			page: 1
		}

		this.oneBannerData = BANNER_TEMP_ITEM;

		const urlChunks = this.router.url.split('/');
		this.slug = urlChunks[urlChunks.length - 1];
		this.isSorting = false;
	}

	ngOnInit(): void {


		this.store.dispatch( new FetchAllProductsInCategoryError(null) ); //костыль, а может и нет))
		this.pageSubscription.add(
			this.domainHelperService.getDomain().subscribe( data => {
				this.store.dispatch( new FetchNewInCategoryProducts({slug: this.slug, newInFilter: this.newInFilter}) );
				this.store.dispatch( new FetchPopularProducts({limit: 8}) );
				this.store.dispatch( new FetchAllProductsInCategory({slug: this.slug, newInFilter: this.allProductsfilter}) );
			})
		);


		this.pageSubscription.add(
			this.allProductsData$.subscribe( data => {
				if( data ) {
					if( this.isSorting ) {
						this.allProductsForPagiantion$.next(null);
						this.isSorting = false;
					}
					if( this.allProductsForPagiantion$.value ) {
						let newProductsList: ProductItem[] = [...this.allProductsForPagiantion$.value.data, ...data.data];
						data.data = newProductsList;
					}
					this.allProductsForPagiantion$.next(data);
					this.isLoadingAllProducts$.next(false);

					if(data && !data.data.length) {
						this.emptyBlock$.next(true);
					}

				}
			})
		)
	}

	ngAfterViewInit() {
		wrapperScrollTop();
	}

	ngOnDestroy() {
		this.pageSubscription.unsubscribe();
	}

	getAllProductsList() {
		this.store.dispatch( new FetchAllProductsInCategory({slug: this.slug, newInFilter: this.allProductsfilter}) );
	}

	mappedProduct(array: ProductItemFromApi[] ) {
		return array.map( product => {

			let image = product.images.find( image => image.is_main) && product.images.find( image => image.is_main).url || product.images[0] && product.images[0].url || null;

			return {
				imgSrc: image,
				brand: product.category,
				title: product.name,
				oldPrice: null,
				currentPrice: product.price,
				currency: product.currency,
				slug: product.slug
			}
		});
	}

	loadMoreGoods() {
		this.isLoadingAllProducts$.next(true);
		this.allProductsfilter.page = this.allProductsfilter.page + 1;
		this.getAllProductsList();
	}

	sortGoods(sortItem: {label: string; value: "created_date" | "name"}) {
		this.isLoadingAllProducts$.next(true);
		this.isSorting = true;
		this.allProductsfilter.page = 1;
		this.allProductsfilter.order_by = sortItem.value;
		this.getAllProductsList();
	}

	get linkQuery() {
		if (this.appService.getIsPreviewValue()) {
			return { '__preview': 'true', 'companyDomain': this.appService.getCompanyPreviewDomainValue() }
		}
		return {};
	}

}
