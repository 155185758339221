import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { BehaviorSubject } from 'rxjs';
import { SliderWithBannersData } from '@app/store/company-page.model';

const BASE_CAROUSEL_CONFIG: NguCarouselConfig = {
	grid: {
		xs: 1,
		sm: 1,
		md: 1,
		lg: 1,
		all: 0
	},
	speed: 300,
	slide: 1,
	point: {
		visible: false
	},
	touch: false,
	loop: false,
	animation: 'lazy'
};

@Component({
	selector: 'slider-with-banners',
	templateUrl: './slider-with-banners.component.html',
	styleUrls: ['./slider-with-banners.component.scss']
})

export class SliderWithBanners implements OnInit {

	// props
	@Input() public title: string;
	@Input() public productList: SliderWithBannersData[];
	@Input() public carouselConfig: NguCarouselConfig;

	// base config
	@ViewChild('myCarousel', { static: false }) private _carousel: NguCarousel<any>;
	public _carouselInputs$: BehaviorSubject<NguCarouselConfig>;


	constructor() {

		// init state
		this._carouselInputs$ = new BehaviorSubject(BASE_CAROUSEL_CONFIG);
		this.productList = [];
		this.title = 'Slider';
	}

	get isLastSlide(): boolean {
		return (this._carousel) ? this._carousel.isLast : false;
	}

	get isFirstSlide(): boolean {
		return (this._carousel) ? this._carousel.isFirst : false;
	}

	get hasControls(): boolean {
		return (this._carousel) ? this.productList.length > this._carousel.items : false;
	}

	get currentSlide(): number {
		return (this._carousel) ? this._carousel.currentSlide : 0;
	}

	prevSlide(): void {
		let slideStep = this._carousel.slideItems;
		let nextSlide = this.currentSlide - slideStep;
		nextSlide = (nextSlide > 0) ? nextSlide : 0;
		this._carousel.moveTo(nextSlide, false);
	}

	nextSlide(): void {
		let slideStep = this._carousel.slideItems;
		let nextSlide = this.currentSlide + slideStep;
		this._carousel.moveTo(nextSlide, false);
	}

	ngOnInit(): void {
		if (this.carouselConfig !== undefined) {
			try {
				let carouselInputs: NguCarouselConfig = {
					...BASE_CAROUSEL_CONFIG,
					...this.carouselConfig
				}
				this._carouselInputs$.next(carouselInputs);
			}
			catch (error) {
				console.warn("Warning --> PromoProductSliderComponent --> ngOnInit", error);
			}
		}
	}

}
