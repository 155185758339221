
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@api/api.service';
import { of } from 'rxjs';
import {
	FetchOrderActions, FetchOrderListDataSuccess, GetOrderListDataSuccess
} from "./fetch-order.state";
import { getCurrentBulkPrice } from "@store/order/order.state";
import { saveDataLocalStorage, isMainImage } from '@app/helper/common';


@Injectable()
export class FetchOrderEffects {

	constructor(
		private actions$: Actions,
		private apiService: ApiService
	) { }


	public fetchOrderListData$ = createEffect(() => this.actions$.pipe(
		ofType(FetchOrderActions.fetchOrderListData),
		mergeMap((data: any) => this.apiService.fetchOrderData(data.payload)
			.pipe(
				map((data: any) => {
					try {
						return new FetchOrderListDataSuccess(data);
					}
					catch(error) {
						console.error("Error --> confirmUserOrder", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(error);
				})
			)))
	);


	public getOrderListData$ = createEffect(() => this.actions$.pipe(
		ofType(FetchOrderActions.getOrderListData),
		mergeMap((data: any) => this.apiService.getOrderData()
			.pipe(
				map((data: any) => {
					try {
						let orderListData = data.map(item => {
							return {
								"company_id": item.company.id,
								"currency": item.currency,
								"products": item.items.map(product => {
									return {
										"id": product.id,
										"product_id": product.product_id,
										"variation_id": product.variation_id,
										"quantity": product.quantity,
										"available_quantity": product.item.available_quantity,
										"price": product.item.price,
										"bulk_prices": product.item.bulk_prices,
										"show_bulk_pricing": product.item.show_bulk_pricing,
										"current_bulk_price": getCurrentBulkPrice({
											quantity: product.quantity,
											data: {
												show_bulk_pricing: product.item.show_bulk_pricing,
												bulk_prices: product.item.bulk_prices,
												price: product.item.price
											}
										})
									}
								})
							}
						});

						let orderListOption = data.map(item => {
							return {
								"order_company": {
									"id": item.company.id,
									"name": item.company.name,
									"img": item.company.logo,
									"location": "USA, Las Vegas",
									"time_zone": "America/New_York"
								},
								"order_currency": item.currency,
								"order_products": item.items.map(product => {
									return {
										"id": product.id,
										"product_id": product.product_id,
										"name": product.item.name,
										"image": isMainImage(product.item.images),
										"quantity_in_one_package": product.item.quantity_in_one_package,
										"available_quantity": product.item.available_quantity,
										"minimum_order": product.item.minimum_order,
										"max_quantity": product.item.available_quantity || 9999,
										"min_quantity": product.item.minimum_order || 1,
										"unit": product.item.unit,
										"features": product.item.features,
										"slug": product.item.slug,
										"category": 'product.item.slug'
									}
								})
							}
						})

						saveDataLocalStorage('orderListData', orderListData);
						saveDataLocalStorage('orderListOption', orderListOption);

						return new GetOrderListDataSuccess(data);
					}
					catch(error) {
						console.error("Error --> confirmUserOrder", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(error);
				})
			)))
	);


}