import { SharedModule } from "./shared.module";
import { SvgInlineModule } from "./svg-inline/svg-inline.module";
import { PromoProductModule } from "./promo-product/promo-product.module";
import { PromoBannerModule } from "./promo-banner/promo-banner.module";
import { CertificateItemModule } from "./certificate-item/certificate-item.module";
import { SocialSharedModule } from "./social-share/social-share.module";
import { AppMapModule } from "./app-map/app-map.module";
import { OrderListModule } from "./order-list/order-list.module";
import { ModalModule } from "./modals/modal.module";


export {
	SharedModule,
	SvgInlineModule,
	PromoProductModule,
	PromoBannerModule,
	CertificateItemModule,
	SocialSharedModule,
	AppMapModule,
	OrderListModule,
	ModalModule
};