import { Component, Input, ViewChild, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ProductItem } from "@app/helper/model";
import { UiKitSliderComponent } from "@sub/tradalaxy_uikit/src/app/uikit/uikit-slider/uikit-slider.component";


@Component({
	selector: 'promo-product-slider',
	templateUrl: './promo-product-slider.component.html',
	styleUrls: ['./promo-product-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class PromoProductSliderComponent implements AfterViewInit {

	// props
	@Input() public productList: Array<ProductItem>;

	// base config
	@ViewChild('uikitslider', {static: false}) private _uikitslider: UiKitSliderComponent;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		// init state
		this.productList = [];
	}

	get isLastSlide(): boolean {
		return (this._uikitslider) ? this._uikitslider.isLast : false;
	}

	get isFirstSlide(): boolean {
		return (this._uikitslider) ? this._uikitslider.isFirst : false;
	}

	get hasControls(): boolean {
		return (this._uikitslider) ? this._uikitslider.isHasControls : false;
	}

	prevSlide(): void {
		this._uikitslider.prevSlide();
	}

	nextSlide(): void {
		this._uikitslider.nextSlide();
	}

	ngAfterViewInit(): void {
		try {
			setTimeout(() => {
				if ( this._uikitslider ) {
					this._uikitslider.renderSlider();
				}
			}, 0);
			this.changeDetectorRef.detectChanges();
		}
		catch(error) {
			console.warn("Warning --> PromoProductSliderComponent --> ngAfterViewInit", error)
		}
	}
}
