import { Component, Output, ChangeDetectionStrategy, EventEmitter, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { commonValidator } from "@app/helper/validator";
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { ForgotPassword, UserActions } from "@store/user/user.state";
import { BehaviorSubject, Subscription } from 'rxjs';


@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ForgotPasswordComponent implements OnDestroy {

	@Output() public close: EventEmitter<void>;

	private pageSubscription: Subscription;
	public preloader$: BehaviorSubject<boolean>;
	public popupStep$: BehaviorSubject<number>;
	public forgotPasswordForm: FormGroup;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private actionsSubject: ActionsSubject) {

		// init state
		this.close = new EventEmitter();
		this.pageSubscription = new Subscription();
		this.preloader$ = new BehaviorSubject(false);
		this.popupStep$ = new BehaviorSubject(1);

		this.forgotPasswordForm = new FormGroup({
			email: new FormControl('', commonValidator.email)
		});

		this.pageSubscription.add(
			this.actionsSubject.subscribe(z => {
				if (z.type === UserActions.forgotPasswordSuccess) {
					this.popupStep$.next(2);
					this.preloader$.next(false);
				}
				else if (z.type === UserActions.forgotPasswordError) {
					this.forgotPasswordForm.setErrors({
						submitError: 'forgot_password.server_error'
					});
					this.preloader$.next(false);
				}
			})
		);
	}

	get forgotPasswordControl(): {[key: string]: AbstractControl} {
		return this.forgotPasswordForm.controls;
	}

	closeForgotPassword(): void {
		this.close.emit();
	}

	forgotPasswordFormSubmit(): void {
		this.forgotPasswordForm.markAllAsTouched();
		if (!this.forgotPasswordForm.invalid) {
			this.preloader$.next(true);
			let email = this.forgotPasswordControl.email.value;
			this.store.dispatch(new ForgotPassword(email));
		}
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}
}