import { Component, ChangeDetectionStrategy } from "@angular/core";


@Component({
	selector: 'social-share',
	templateUrl: './social-share.component.html',
	styleUrls: ['./social-share.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SocialShareComponent {

	constructor() { }

	openShare(provider: string): void {
		const width = 600, height = 600;
		const left = (window.innerWidth / 2) - (width / 2);
		const top = (window.innerHeight / 2) - (height / 2);

		let url: string;
		let currentUrl = location.href;

		switch (provider) {
			case 'li': {
				url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + currentUrl;
				break;
			}

			case 'fb': {
				url = 'http://www.facebook.com/sharer.php?u=' + currentUrl;
				break;
			}
			case 'tw': {
				url = 'https://twitter.com/share?url=' + currentUrl;
				break;
			}

			default: {
				console.log("Invalid privider");
				break;
			}
		}

		if (url) {
			window.open(url, '',
				`toolbar=no, location=no, directories=no, status=no, menubar=no,
					scrollbars=no, resizable=no, copyhistory=no, width=${width},
					height=${height}, top=${top}, left=${left}`
			);
		}
	}
}
