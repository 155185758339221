import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestState, initRequestState } from '@store/store.shared';


// Interface
export interface PutWarehouseData {
    country_id: number,
    city_id: number,
    port_id: number,
    terminal_id: number,
    company_id: number
}

export interface CompanyWarehouseItem {
	city: string,
	country: string,
	port: string,
	terminal: string,
	value: {
		id: number
	}
}

export interface DeliveryTypes {
	id: number,
	slug: string,
	name: string
}

export interface Incoterms {
	id: number,
	code: string,
	slug: string,
	name: string,
	delivery_types: Array<DeliveryTypes>
}

export interface PaymentTypes {
	id: number,
	slug: string,
	name: string
}

export interface CityItem {
	label: string,
	name: string,
	secondary_text: string,
	value: {
		id: number
	}
}

export interface PortItem {
	label: string,
	name: string,
	country: string,
	distance: string,
	value: {
		id: number
	}
}

export interface TerminalItem {
	label: string,
	name: string,
	country: string,
	distance: string,
	value: {
		id: number
	}
}


// State
export interface CheckoutState {
	companyWarehouse: {
		requestState: RequestState,
		data: Array<CompanyWarehouseItem>
	},
	checkoutEntities: {
		requestState: RequestState,
		incoterms: Array<Incoterms>,
		paymentTypes: Array<PaymentTypes>
	},
	cityList: {
		requestState: RequestState,
		data: Array<CityItem>
	},
	portList: {
		requestState: RequestState,
		data: Array<PortItem>
	},
	terminalList: {
		requestState: RequestState,
		data: Array<TerminalItem>
	}
};

const INITIAL_STATE: CheckoutState = {
	companyWarehouse: {
		requestState: initRequestState(),
		data: []
	},
	checkoutEntities: {
		requestState: initRequestState(),
		incoterms: [],
		paymentTypes: []
	},
	cityList: {
		requestState: initRequestState(),
		data: []
	},
	portList: {
		requestState: initRequestState(),
		data: []
	},
	terminalList: {
		requestState: initRequestState(),
		data: []
	}
};


// Action
export const CheckoutActions = {
	putWarehouse: '[Checkout] Put_Warehouse',
	putWarehouseSuccess: '[Checkout] Put_Warehouse_Success',
	putWarehouseError: '[Checkout] Put_Warehouse_Error',

	companyWarehouse: '[Checkout] Company_Warehouse',
	companyWarehouseSuccess: '[Checkout] Company_Warehouse_Success',
	companyWarehouseError: '[Checkout] Company_Warehouse_Error',

	checkoutEntities: '[Checkout] Checkout_Entities',
	checkoutEntitiesSuccess: '[Checkout] Checkout_Entities_Success',
	checkoutEntitiesError: '[Checkout] Checkout_Entities_Error',

	cityList: '[Checkout] City_List_Entities',
	cityListSuccess: '[Checkout] City_List_Success',
	cityListError: '[Checkout] City_List_Error',

	portList: '[Checkout] Port_List_Entities',
	portListSuccess: '[Checkout] Port_List_Success',
	portListError: '[Checkout] Port_List_Error',

	terminalList: '[Checkout] Terminal_List_Entities',
	terminalListSuccess: '[Checkout] Terminal_List_Success',
	terminalListError: '[Checkout] Terminal_List_Error'
};

export class PutWarehouse implements Action {
	readonly type = CheckoutActions.putWarehouse;
	constructor(public payload: PutWarehouseData) { }
};

export class PutWarehouseSuccess implements Action {
	readonly type = CheckoutActions.putWarehouseSuccess;
	constructor() { }
};

export class PutWarehouseError implements Action {
	readonly type = CheckoutActions.putWarehouseError;
	constructor(public payload: any) { }
};

export class CompanyWarehouse implements Action {
	readonly type = CheckoutActions.companyWarehouse;
	constructor(public payload: { companyId: number }) { }
};

export class CompanyWarehouseSuccess implements Action {
	readonly type = CheckoutActions.companyWarehouseSuccess;
	constructor(public payload: Array<CompanyWarehouseItem>) { }
};

export class CompanyWarehouseError implements Action {
	readonly type = CheckoutActions.companyWarehouseError;
	constructor(public payload: any) { }
};

export class CheckoutEntities implements Action {
	readonly type = CheckoutActions.checkoutEntities;
	constructor() { }
};

export class CheckoutEntitiesSuccess implements Action {
	readonly type = CheckoutActions.checkoutEntitiesSuccess;
	constructor(public payload: {incoterms: Array<Incoterms>, paymentTypes: Array<PaymentTypes>}) { }
};

export class CheckoutEntitiesError implements Action {
	readonly type = CheckoutActions.checkoutEntitiesError;
	constructor(public payload: any) { }
};

export class CityList implements Action {
	readonly type = CheckoutActions.cityList;
	constructor(public payload: {countryId: number, query: string}) { }
};

export class CityListSuccess implements Action {
	readonly type = CheckoutActions.cityListSuccess;
	constructor(public payload: Array<CityItem>) { }
};

export class CityListError implements Action {
	readonly type = CheckoutActions.cityListError;
	constructor(public payload: any) { }
};

export class PortList implements Action {
	readonly type = CheckoutActions.portList;
	constructor(public payload: {countryId: number, cityId: number}) { }
};

export class PortListSuccess implements Action {
	readonly type = CheckoutActions.portListSuccess;
	constructor(public payload: Array<PortItem>) { }
};

export class PortListError implements Action {
	readonly type = CheckoutActions.portListError;
	constructor(public payload: any) { }
};

export class TerminalList implements Action {
	readonly type = CheckoutActions.terminalList;
	constructor(public payload: {countryId: number, cityId: number}) { }
};

export class TerminalListSuccess implements Action {
	readonly type = CheckoutActions.terminalListSuccess;
	constructor(public payload: Array<TerminalItem>) { }
};

export class TerminalListError implements Action {
	readonly type = CheckoutActions.terminalListError;
	constructor(public payload: any) { }
};


// Reducer
export function CheckoutReducer(state = INITIAL_STATE, action: Action) {
	switch (action.type) {

		case CheckoutActions.companyWarehouse: {
			return {
				...state,
				companyWarehouse: {
					...state.companyWarehouse,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CheckoutActions.companyWarehouseSuccess: {
			let payload = (action as CompanyWarehouseSuccess).payload;
			return {
				...state,
				companyWarehouse: {
					...state.companyWarehouse,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CheckoutActions.companyWarehouseError: {
			let payload = (action as CompanyWarehouseError).payload;
			return {
				...state,
				companyWarehouse: {
					...state.companyWarehouse,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		case CheckoutActions.checkoutEntities: {
			return {
				...state,
				checkoutEntities: {
					...state.checkoutEntities,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					}
				}
			}
		}; break;
		case CheckoutActions.checkoutEntitiesSuccess: {
			let payload = (action as CheckoutEntitiesSuccess).payload;
			return {
				...state,
				checkoutEntities: {
					...state.checkoutEntities,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					incoterms: payload.incoterms,
					paymentTypes: payload.paymentTypes
				}
			}
		}; break;
		case CheckoutActions.checkoutEntitiesError: {
			let payload = (action as CheckoutEntitiesError).payload;
			return {
				...state,
				checkoutEntities: {
					...state.checkoutEntities,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					}
				}
			}
		}; break;

		case CheckoutActions.cityList: {
			return {
				...state,
				cityList: {
					...state.cityList,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					}
				}
			}
		}; break;
		case CheckoutActions.cityListSuccess: {
			let payload = (action as CityListSuccess).payload;
			return {
				...state,
				cityList: {
					...state.cityList,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CheckoutActions.cityListError: {
			let payload = (action as CityListError).payload;
			return {
				...state,
				cityList: {
					...state.cityList,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: []
				}
			}
		}; break;

		case CheckoutActions.portList: {
			return {
				...state,
				portList: {
					...state.portList,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					}
				}
			}
		}; break;
		case CheckoutActions.portListSuccess: {
			let payload = (action as PortListSuccess).payload;
			return {
				...state,
				portList: {
					...state.portList,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CheckoutActions.portListError: {
			let payload = (action as PortListError).payload;
			return {
				...state,
				portList: {
					...state.portList,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: []
				}
			}
		}; break;

		case CheckoutActions.terminalList: {
			return {
				...state,
				terminalList: {
					...state.terminalList,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					}
				}
			}
		}; break;
		case CheckoutActions.terminalListSuccess: {
			let payload = (action as TerminalListSuccess).payload;
			return {
				...state,
				terminalList: {
					...state.terminalList,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CheckoutActions.terminalListError: {
			let payload = (action as TerminalListError).payload;
			return {
				...state,
				terminalList: {
					...state.terminalList,
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: []
				}
			}
		}; break;

		default: {
			return state;
		}
	}
};


// Getter
export const getCheckoutState = createFeatureSelector<CheckoutState>('checkoutState');

export const getCompanyWarehouseData = createSelector(
	getCheckoutState,
	(state: CheckoutState) => state.companyWarehouse.data
);

export const getCheckoutEntities = createSelector(
	getCheckoutState,
	(state: CheckoutState) => state.checkoutEntities
);

export const getCheckoutIncoterms = createSelector(
	getCheckoutState,
	(state: CheckoutState) => state.checkoutEntities.incoterms
);

export const getCityListData = createSelector(
	getCheckoutState,
	(state: CheckoutState) => state.cityList.data
);

export const getPortListData = createSelector(
	getCheckoutState,
	(state: CheckoutState) => state.portList.data
);

export const getTerminalListData = createSelector(
	getCheckoutState,
	(state: CheckoutState) => state.terminalList.data
);