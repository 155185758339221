
import { Component, ChangeDetectionStrategy, Input, OnInit} from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { trigger, style, animate, transition, state } from '@angular/animations';

@Component({
	selector: 'mobile-accordion',
	templateUrl: './mobile-accordion.component.html',
	styleUrls: ['./mobile-accordion.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expandDetails', [
			state('true', style({ height: "*" })),
			state('false', style({ height: 0 })),
			transition('true <=> false', animate('0.3s'))
		])
	]
})

export class AppMobileAccordion implements OnInit  {

	public isOpen$: BehaviorSubject<boolean>;

	@Input() public isMobile: boolean;

	constructor() {
		this.isOpen$ = new BehaviorSubject(false);
	}

	toggleOpen(): void {
		let nextState = !this.isOpen$.getValue();
		this.isOpen$.next(nextState);
	}

	ngOnInit(): void {
		if(this.isMobile) {
			this.isOpen$ = new BehaviorSubject(false);
		} else {
			this.isOpen$ = new BehaviorSubject(true);
		}
	}


}