import { BannerItem } from './model';

export const MAX_QUANTITY = 999999;

export const TYPE_COMPANY = [
	{
		value: {
			id: 11
		},
		label: 'type_company.manufacturer'
	},
	{
		value: {
			id: 12
		},
		label: 'type_company.wholesaler'
	},
	{
		value: {
			id: 13
		},
		label: 'type_company.services_provider'
	}
];

export const NUMBER_OF_EMPLOYEES_ARRAY = [
	{
		label: '< 10',
		value: 'small'
	},
	{
		label: '10 - 50',
		value: '10_50'
	},
	{
		label: '50 - 100',
		value: '50_100'
	},
	{
		label: '100 - 250',
		value: '100_250'
	},
	{
		label: '250+',
		value: 'more'
	}
];

export const ANNUAL_TURNOVER_ARRAY = [
	{
		label: "< USD 500,000",
		value: "small"
	},
	{
		label: "USD 500,000-1,000,000",
		value: "500000_1000000"
	},
	{
		label: "USD 1,000,000 - 5,000,000",
		value: "1000000_5000000"
	},
	{
		label: "> USD 5,000,000",
		value: "more"
	},
	{
		label: "Confidential",
		value: "confidential"
	}
];


export const BANNER_TEMP_ITEM: BannerItem = {
	img: '/assets/content/banner_bg.png',
	text: 'test123',
	bage: {
		type: 'best',
		text: 't12'
	}
};