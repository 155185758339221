import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MetaModule } from '@ngx-meta/core';
import { SharedModule } from "@app/shared/shared";

// api
import { AppHttpInterceptor } from '@api/app-http.Interceptor';

// store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppStoreReducers } from '@store/app.store';
import { AppEffects } from "@store/app.effects";

// custom modules
import { AppRoutingModule } from './app-routing.module';
import { UiKitModule } from "@sub/tradalaxy_uikit/src/app/uikit/ui-kit.module";
import { SvgInlineModule } from "./shared/shared";
import { CompanyPageModule } from "./company-page/company-page.module";
import { ProductPageModule } from "./product-page/product-page.module";
import { OrderPageModule } from "./order-page/order-page.module";
import { ServicePagesdModule } from './service-page/service-pages.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// component
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-component/app-header/app-header.component';
import { HeaderCurrencyComponent } from './app-component/app-header/header-currency/header-currency.component';

import { AppFooterComponent } from './app-component/app-footer/app-footer.component';
import { AppOrderComponent } from "./app-component/app-order/app-order.component";
import { AppErrorsComponent } from './app-component/app-errors/app-errors.component';

// service
import { AppService } from './service/app-service.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '@app/service/user-service.service';
import { ErrorService } from "@app/service/error-service.service";
import { UserTranslateService } from '@app/service/user-translate-service.service';


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		AppHeaderComponent,
		AppFooterComponent,
		AppOrderComponent,
		AppErrorsComponent,
		HeaderCurrencyComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
		BrowserAnimationsModule,
		AppRoutingModule,
		MetaModule.forRoot(),
		StoreModule.forRoot(AppStoreReducers),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'routerState',
		}),
		EffectsModule.forRoot(AppEffects),
        StoreDevtoolsModule.instrument({
            maxAge: 10,
            serialize: true
        }),
		UiKitModule,
		SvgInlineModule,
		CompanyPageModule,
		ProductPageModule,
		OrderPageModule,
		ServicePagesdModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpInterceptor,
			multi: true
		},
		CookieService,
		UserService,
		ErrorService,
		UserTranslateService,
		AppService
	],
	exports: [
		TranslateModule
	],
	bootstrap: [AppComponent]
})

export class AppModule { }