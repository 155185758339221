import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// custom modules
import { UiKitIconModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";

// component
import { CertificateItemComponent } from './certificate-item.component';


@NgModule({
	imports: [
		CommonModule,
		UiKitIconModule,
		TranslateModule
	],
	declarations: [
		CertificateItemComponent
	],
	exports: [
		CertificateItemComponent
	]
})

export class CertificateItemModule { }
