import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestState, initRequestState } from '@store/store.shared';
import { ProductItem, BannerItem } from '@app/helper/model';
import { BaseData, CategoriesList, BaseCompanyTabData, CategoryForDropdown, ProductsListForProductItem, Filter, TradeInfoData } from '../company-page.model';

// State
export interface CompanyState {
	baseData: {
		requestState: RequestState,
		data: BaseData
	},
	recomendedProducts: {
		requestState: RequestState,
		data: Array<ProductItem>
	},
    popularProducts: {
        requestState: RequestState,
		data: Array<ProductItem>
	},
	categoriesList: {
        requestState: RequestState,
		data: CategoriesList
	},
	companyTabBaseData: {
        requestState: RequestState,
		data: BaseCompanyTabData
	},
	bestsellersProductsList: {
        requestState: RequestState,
		data: Array<ProductItem>
	},
	categoryListForDropdown: {
		requestState: RequestState,
		data: Array<CategoryForDropdown>
	},
	productListOfCategory: {
		requestState: RequestState,
		data: ProductsListForProductItem
	},
	newInCategoryProducts: {
		requestState: RequestState,
		data: ProductsListForProductItem
	},
	allProductsInCategory: {
		requestState: RequestState,
		data: ProductsListForProductItem
	},
	tradeInfoData: {
		requestState: RequestState,
		data: TradeInfoData
	}
};

const INITIAL_STATE: CompanyState = {
	baseData: {
		requestState: initRequestState(),
		data: undefined
	},
	recomendedProducts: {
		requestState: initRequestState(),
		data: undefined
	},
	popularProducts: {
		requestState: initRequestState(),
		data: undefined
	},
	categoriesList: {
		requestState: initRequestState(),
		data: undefined
	},
	companyTabBaseData: {
		requestState: initRequestState(),
		data: undefined
	},
	bestsellersProductsList: {
		requestState: initRequestState(),
		data: undefined
	},
	categoryListForDropdown: {
		requestState: initRequestState(),
		data: undefined
	},
	productListOfCategory: {
		requestState: initRequestState(),
		data: undefined
	},
	newInCategoryProducts: {
		requestState: initRequestState(),
		data: undefined
	},
	allProductsInCategory: {
		requestState: initRequestState(),
		data: undefined
	},
	tradeInfoData: {
		requestState: initRequestState(),
		data: undefined
	}
};

// Action
export const CompanyActions = {
	fetchCompanyBase: '[Company] Fetch_Company_Base',
    fetchCompanyBaseSuccess: '[Company] Fetch_Company_Base_Success',
	fetchCompanyBaseError: '[Company] Fetch_Company_Base_Error',

	fetchRecomendedProducts: '[Company] Fetch_Recomended_Products',
    fetchRecomendedProductsSuccess: '[Company] Fetch_Recomended_Products_Success',
	fetchRecomendedProductsError: '[Company] Fetch_Recomended_Products_Error',

    fetchPopularProducts: '[Company] Fetch_Popular_Products',
    fetchpoPularProductsSuccess: '[Company] Fetch_Popular_Products_Success',
	fetchPopularProductsError: '[Company] Fetch_Popular_Products_Error',
	fetchCategoriesList: '[Company] Fetch_Categories_List',
    fetchCategoriesListSuccess: '[Company] Fetch_Categories_List_Success',
	fetchCategoriesListError: '[Company] Fetch_Categories_List_Error',
	fetchCompanyTabBaseData: '[Company] Fetch_Company_Tab_Base_Data',
    fetchCompanyTabBaseDataSuccess: '[Company] Fetch_Company_Tab_Base_Data_Success',
	fetchCompanyTabBaseDataError: '[Company] Fetch_Company_Tab_Base_Data_Error',
	fetchBestsellersProductsList: '[Company] Fetch_Bestsellers_Products_List',
    fetchBestsellersProductsListSuccess: '[Company] Fetch_Bestsellers_Products_List_Success',
	fetchBestsellersProductsListError: '[Company] Fetch_Bestsellers_Products_List_Error',
	fetchCategoryListForDropdown: '[Company] Fetch_Category_List_For_Dropdown',
    fetchCategoryListForDropdownSuccess: '[Company] Fetch_Category_List_For_Dropdown_Success',
	fetchCategoryListForDropdownError: '[Company] Fetch_Category_List_For_Dropdown_Error',
	fetchProductListOfCategory: '[Company] Fetch_Product_List_Of_Category',
    fetchProductListOfCategorySuccess: '[Company] Fetch_Product_List_Of_Category_Success',
	fetchProductListOfCategoryError: '[Company] Fetch_Product_List_Of_Category_Error',
	fetchNewInCategoryProducts: '[Company] Fetch_New_in_Category_Products',
    fetchNewInCategoryProductsSuccess: '[Company] Fetch_New_in_Category_Products_Success',
	fetchNewInCategoryProductsError: '[Company] Fetch_New_in_Category_Products_Error',
	fetchAllProductsInCategory: '[Company] Fetch_All_Products_In_Category',
    fetchAllProductsInCategorySuccess: '[Company] Fetch_All_Products_In_Category_Success',
	fetchAllProductsInCategoryError: '[Company] Fetch_All_Products_In_Category_Error',
	fetchTradeInfoData: '[Company] Fetch_Trade_Info_Data',
    fetchTradeInfoDataSuccess: '[Company] Fetch_Fetch_Trade_Info_Data_Success',
	fetchTradeInfoDataError: '[Company] Fetch_Fetch_Trade_Info_Data_Error',
};

// base data
export class FetchCompanyBase implements Action {
    readonly type = CompanyActions.fetchCompanyBase;
    constructor(public payload: any) { }
};

export class FetchCompanyBaseSuccess implements Action {
	readonly type = CompanyActions.fetchCompanyBaseSuccess;
	constructor(public payload: any) { }
};

export class FetchCompanyBaseError implements Action {
	readonly type = CompanyActions.fetchCompanyBaseError;
	constructor(public payload: any) { }
};

export class FetchRecomendedProducts implements Action {
	readonly type = CompanyActions.fetchRecomendedProducts;
	constructor(public payload: {limit: number}) { }
};

export class FetchRecomendedProductsSuccess implements Action {
	readonly type = CompanyActions.fetchRecomendedProductsSuccess;
	constructor(public payload: Array<ProductItem>) { }
};

export class FetchRecomendedProductsError implements Action {
	readonly type = CompanyActions.fetchRecomendedProductsError;
	constructor(public payload: any) { }
};

// popular products
export class FetchPopularProducts implements Action {
	readonly type = CompanyActions.fetchPopularProducts;
	constructor(public payload: {limit: number}) { }
};

export class FetchPopularProductsSuccess implements Action {
	readonly type = CompanyActions.fetchpoPularProductsSuccess;
	constructor(public payload: Array<ProductItem>) { }
};

export class FetchPopularProductsError implements Action {
	readonly type = CompanyActions.fetchPopularProductsError;
	constructor(public payload: any) { }
};

// // catefories list
export class FetchCategoriesList implements Action {
	readonly type = CompanyActions.fetchCategoriesList;
	constructor(public payload: any) { }
};

export class FetchCategoriesListSuccess implements Action {
	readonly type = CompanyActions.fetchCategoriesListSuccess;
	constructor(public payload: CategoriesList) { }
};

export class FetchCategoriesListError implements Action {
	readonly type = CompanyActions.fetchCategoriesListError;
	constructor(public payload: any) { }
};

// // company tab base data
export class FetchCompanyTabBaseData implements Action {
	readonly type = CompanyActions.fetchCompanyTabBaseData;
	constructor(public payload: any) { }
};

export class FetchCompanyTabBaseDataSuccess implements Action {
	readonly type = CompanyActions.fetchCompanyTabBaseDataSuccess;
	constructor(public payload: BaseCompanyTabData) { }
};

export class FetchCompanyTabBaseDataError implements Action {
	readonly type = CompanyActions.fetchCompanyTabBaseDataError;
	constructor(public payload: any) { }
};

// // bestsellers products list
export class FetchBestsellersProductsList implements Action {
	readonly type = CompanyActions.fetchBestsellersProductsList;
	constructor(public payload: {limit: number}) { }
};

export class FetchBestsellersProductsListSuccess implements Action {
	readonly type = CompanyActions.fetchBestsellersProductsListSuccess;
	constructor(public payload: BaseCompanyTabData) { }
};

export class FetchBestsellersProductsListError implements Action {
	readonly type = CompanyActions.fetchBestsellersProductsListError;
	constructor(public payload: any) { }
};

// // category list for dropdown
export class FetchCategoryListForDropdown implements Action {
	readonly type = CompanyActions.fetchCategoryListForDropdown;
	constructor(public payload: any) { }
};

export class FetchCategoryListForDropdownSuccess implements Action {
	readonly type = CompanyActions.fetchCategoryListForDropdownSuccess;
	constructor(public payload: Array<CategoryForDropdown>) { }
};

export class FetchCategoryListForDropdownError implements Action {
	readonly type = CompanyActions.fetchCategoryListForDropdownError;
	constructor(public payload: any) { }
};

export class FetchProductsListOfCategory implements Action {
	readonly type = CompanyActions.fetchProductListOfCategory;
	constructor(public payload: {categoryId: number, filter: Filter}) { }
};

export class FetchProductsListOfCategorySuccess implements Action {
	readonly type = CompanyActions.fetchProductListOfCategorySuccess;
	constructor(public payload: ProductsListForProductItem) { }
};

export class FetchProductsListOfCategoryError implements Action {
	readonly type = CompanyActions.fetchProductListOfCategoryError;
	constructor(public payload: any) { }
};

// // new in category products
export class FetchNewInCategoryProducts implements Action {
	readonly type = CompanyActions.fetchNewInCategoryProducts;
	constructor(public payload: {slug: string, newInFilter: Filter}) { }
};

export class FetchNewInCategoryProductsSuccess implements Action {
	readonly type = CompanyActions.fetchNewInCategoryProductsSuccess;
	constructor(public payload: ProductsListForProductItem) { }
};

export class FetchNewInCategoryProductsError implements Action {
	readonly type = CompanyActions.fetchNewInCategoryProductsError;
	constructor(public payload: any) { }
};

// // all products in category
export class FetchAllProductsInCategory implements Action {
	readonly type = CompanyActions.fetchAllProductsInCategory;
	constructor(public payload: {slug: string, newInFilter: Filter}) { }
};

export class FetchAllProductsInCategorySuccess implements Action {
	readonly type = CompanyActions.fetchAllProductsInCategorySuccess;
	constructor(public payload: ProductsListForProductItem) { }
};

export class FetchAllProductsInCategoryError implements Action {
	readonly type = CompanyActions.fetchAllProductsInCategoryError;
	constructor(public payload: any) { }
};

// trade info data
export class FetchTradeInfoData implements Action {
	readonly type = CompanyActions.fetchTradeInfoData;
	constructor(public payload: any) { }
};

export class FetchTradeInfoDataSuccess implements Action {
	readonly type = CompanyActions.fetchTradeInfoDataSuccess;
	constructor(public payload: TradeInfoData) { }
};

export class FetchTradeInfoDataError implements Action {
	readonly type = CompanyActions.fetchTradeInfoDataError;
	constructor(public payload: any) { }
};



type CompanyActionsType = 	FetchCompanyBase | FetchCompanyBaseSuccess | FetchCompanyBaseError |
							FetchPopularProducts | FetchPopularProductsSuccess | FetchPopularProductsError |
							FetchCategoriesList | FetchCategoriesListSuccess | FetchCategoriesListError |
							FetchCompanyTabBaseData | FetchCompanyTabBaseDataSuccess | FetchCompanyTabBaseDataError |
							FetchBestsellersProductsList | FetchBestsellersProductsListSuccess | FetchBestsellersProductsListError |
							FetchCategoryListForDropdown | FetchCategoryListForDropdownSuccess | FetchCategoryListForDropdownError |
							FetchProductsListOfCategory | FetchProductsListOfCategorySuccess | FetchProductsListOfCategoryError |
							FetchNewInCategoryProducts | FetchNewInCategoryProductsSuccess | FetchNewInCategoryProductsError |
							FetchAllProductsInCategory | FetchAllProductsInCategorySuccess | FetchAllProductsInCategoryError |
							FetchTradeInfoData | FetchTradeInfoDataSuccess | FetchTradeInfoDataError |
							FetchRecomendedProducts | FetchRecomendedProductsSuccess | FetchRecomendedProductsError;

// Reducer
export function CompanyReducer(state = INITIAL_STATE, action: CompanyActionsType) {

	switch (action.type) {

        // base data
		case CompanyActions.fetchCompanyBase: {
			return {
				...state,
				baseData: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchCompanyBaseSuccess: {
			return {
				...state,
				baseData: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchCompanyBaseError: {
			return {
				...state,
				baseData: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
        }; break;

		case CompanyActions.fetchRecomendedProducts: {
			return {
				...state,
				recomendedProducts: {
					...state.recomendedProducts,
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchRecomendedProductsSuccess: {
			return {
				...state,
				recomendedProducts: {
					...state.recomendedProducts,
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchRecomendedProductsError: {
			return {
				...state,
				recomendedProducts: {
					...state.recomendedProducts,
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					}
				}
			}
		}; break;

        // popular products
		case CompanyActions.fetchPopularProducts: {
			return {
				...state,
				popularProducts: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchpoPularProductsSuccess: {
			return {
				...state,
				popularProducts: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchPopularProductsError: {
			return {
				...state,
				popularProducts: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// categories list
		case CompanyActions.fetchCategoriesList: {
			return {
				...state,
				categoriesList: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchCategoriesListSuccess: {
			return {
				...state,
				categoriesList: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchCategoriesListError: {
			return {
				...state,
				categoriesList: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// company tab base data
		case CompanyActions.fetchCompanyTabBaseData: {
			return {
				...state,
				companyTabBaseData: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchCompanyTabBaseDataSuccess: {
			return {
				...state,
				companyTabBaseData: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchCompanyTabBaseDataError: {
			return {
				...state,
				companyTabBaseData: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// bestsellers products list
		case CompanyActions.fetchBestsellersProductsList: {
			return {
				...state,
				bestsellersProductsList: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchBestsellersProductsListSuccess: {
			return {
				...state,
				bestsellersProductsList: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchBestsellersProductsListError: {
			return {
				...state,
				bestsellersProductsList: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// category list for dropdown
		case CompanyActions.fetchCategoryListForDropdown: {
			return {
				...state,
				categoryListForDropdown: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchCategoryListForDropdownSuccess: {
			return {
				...state,
				categoryListForDropdown: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchCategoryListForDropdownError: {
			return {
				...state,
				categoryListForDropdown: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// products list of category
		case CompanyActions.fetchProductListOfCategory: {
			return {
				...state,
				productListOfCategory: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: state.productListOfCategory.data ? state.productListOfCategory.data : undefined
				}
			}
		}; break;
		case CompanyActions.fetchProductListOfCategorySuccess: {
			return {
				...state,
				productListOfCategory: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchProductListOfCategoryError: {
			return {
				...state,
				productListOfCategory: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// new in category products
		case CompanyActions.fetchNewInCategoryProducts: {
			return {
				...state,
				newInCategoryProducts: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchNewInCategoryProductsSuccess: {
			return {
				...state,
				newInCategoryProducts: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchNewInCategoryProductsError: {
			return {
				...state,
				newInCategoryProducts: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// all products in category
		case CompanyActions.fetchAllProductsInCategory: {
			return {
				...state,
				allProductsInCategory: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: state.allProductsInCategory.data ? state.allProductsInCategory.data : undefined
				}
			}
		}; break;
		case CompanyActions.fetchAllProductsInCategorySuccess: {
			return {
				...state,
				allProductsInCategory: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchAllProductsInCategoryError: {
			return {
				...state,
				allProductsInCategory: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		// trade info data
		case CompanyActions.fetchTradeInfoData: {
			return {
				...state,
				tradeInfoData: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CompanyActions.fetchTradeInfoDataSuccess: {
			return {
				...state,
				tradeInfoData: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: action.payload
				}
			}
		}; break;
		case CompanyActions.fetchTradeInfoDataError: {
			return {
				...state,
				tradeInfoData: {
					requestState: {
						isFetch: false,
						isError: true,
						error: action.payload
					},
					data: undefined
				}
			}
		}; break;

		default: {
			return state;
		}
	}
};

// Getters
export const getCompanyState = createFeatureSelector<CompanyState>('companyState');

export const getCompanyBaseData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.baseData.data
);

export const getCompanyBaseState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.baseData.requestState
);

export const getRecomendedProductsData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.recomendedProducts.data
);

export const getPopularProductsData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.popularProducts.data
);

export const getPopularProductsState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.popularProducts.requestState
);

export const getCategoriesListData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.categoriesList.data
);

export const getCategoriesListState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.categoriesList.requestState
);

export const getCompanyTabBaseData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.companyTabBaseData.data
);

export const getCompanyTabBaseState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.companyTabBaseData.requestState
);

export const getBestsellersProductsListData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.bestsellersProductsList.data
);

export const getBestsellersProductsListState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.bestsellersProductsList.requestState
);

export const getCategoryListForDropdownData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.categoryListForDropdown.data
);

export const getCategoryListForDropdownState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.categoryListForDropdown.requestState
);

export const getProductsListOfCategoryData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.productListOfCategory.data
);

export const getProductsListOfCategoryState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.productListOfCategory.requestState
);

export const getNewInCategoryProductsData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.newInCategoryProducts.data
);

export const getNewInCategoryProductsState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.newInCategoryProducts.requestState
);

export const getAllProductsInCategoryData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.allProductsInCategory.data
);

export const getAllProductsInCategoryState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.allProductsInCategory.requestState
);

export const getTradeInfoData = createSelector(
	getCompanyState,
	(state: CompanyState) => state.tradeInfoData.data
);

export const getTradeInfoDataState = createSelector(
	getCompanyState,
	(state: CompanyState) => state.tradeInfoData.requestState
);