import { Component, Input, ChangeDetectionStrategy } from '@angular/core';


@Component({
	selector: 'uikit-checkbox',
	templateUrl: './uikit-checkbox.component.html',
	styleUrls: ['./uikit-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiKitCheckboxComponent {

	@Input() public uiActive: boolean;
	@Input() public uiError: string;
	@Input() public uiErrorVisible: boolean;

	constructor() {
		// init state
		this.uiActive = false;
		this.uiError = '';
		this.uiErrorVisible = false;
	}
}