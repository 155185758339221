import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ServicePageComponent } from "./service-page/service-page.component";
import { CompanyPageComponent } from "./company-page/company-page.component";
import { ProductPageComponent } from "./product-page/product-page.component";

import { ProductTab } from "./company-page/product-tab/product-tab.component";
import { CompanyTab } from './company-page/company-tab/company-tab.component';
import { InsideCategory } from './company-page/inside-category/inside-category.component';
import { TradeInfoTab } from './company-page/trade-info-tab/trade-info-tab.component';
import { ContactsTab } from './company-page/contacts-tab/contacts-tab.component';

import { OrderPageComponent } from "./order-page/order-page.component";
import { OrderSuccessPageComponent } from "./order-page/order-success-page/order-success-page.component";
import { CanDeactivateOrderGuard } from "./order-page/order-page.guards";

const routes: Routes = [
	{
		path: '',
		component: CompanyPageComponent,
		children: [
			{
				path: "",
				component: CompanyTab
			},
			{
				path: "products",
				component: ProductTab
			},
			{
				path: "products/:slug",
				component: InsideCategory
			},
			{
				path: "tradeInfo",
				component: TradeInfoTab
			},
			{
				path: "contacts",
				component: ContactsTab
			},
		]
	},
	{
		path: 'product/:slug',
		component: ProductPageComponent,
	},
	{
		path: '404',
		component: ServicePageComponent,
		data: { pageStatus: '404' }
	},
	{
		path: '500',
		component: ServicePageComponent,
		data: { pageStatus: '500' }
	},
	{
		path: 'expired',
		component: ServicePageComponent,
		data: { pageStatus: 'expired' }
	},
	{
		path: 'order',
		component: OrderPageComponent,
		canDeactivate: [CanDeactivateOrderGuard]
	},
	{
		path: "order-success",
		component: OrderSuccessPageComponent
	},
	{
		path: '**',
		redirectTo: '404'
	}
];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }