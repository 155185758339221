import { UiKitModule } from "./ui-kit.module";
import { UiKitIconModule } from "./uikit-icon/uikit-icon.module";
import { UiKitInputModule } from "./uikit-input/uikit-input.module";
import { UiKitTabModule } from "./uikit-tab/uikit-tab.module";
import { UiKitSliderModule } from "./uikit-slider/uikit-slider.module";

export {
	UiKitModule,
	UiKitIconModule,
	UiKitInputModule,
	UiKitTabModule,
	UiKitSliderModule
}