import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'uikit-tab',
  templateUrl: './uikit-tab.component.html',
  styleUrls: ['./uikit-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiKitTabComponent {

	@Input() public tabTitle: string;
	@Input() public active$: BehaviorSubject<boolean>;

	constructor() {
		this.active$ = new BehaviorSubject(false);
	}
}
