import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// custom modules
import { UiKitIconModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";

// component
import { BreadcrumbsComponent } from "./breadcrumbs.component";

@NgModule({
	imports: [
		CommonModule,
		UiKitIconModule,
		RouterModule,
		TranslateModule
	],
	declarations: [
		BreadcrumbsComponent
	],
	exports: [
		BreadcrumbsComponent
	]
})

export class BreadcrumbsComponentModule { }
