import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from "@angular/core";


@Component({
	selector: 'app-modal-success',
	templateUrl: './app-modal-success.component.html',
	styleUrls: ['./app-modal-success.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppModalSuccessComponent {

	@Input() public showCloseBtn: boolean;
	@Input() public overflowClose: boolean;
	@Input() public showUnconfirm: boolean;
	@Input() public confirmTxt: string;
	@Input() public unConfirmTxt: string;

	@Output() public modalClose: EventEmitter<void>;
	@Output() public modalConfirm: EventEmitter<void>;
	@Output() public modalUnconfirm: EventEmitter<void>;

	constructor() {
		// init state
		this.showCloseBtn = true;
		this.overflowClose = true;
		this.showUnconfirm = true;
		this.confirmTxt = '';
		this.unConfirmTxt = '';

		this.modalClose = new EventEmitter();
		this.modalConfirm = new EventEmitter();
		this.modalUnconfirm = new EventEmitter();
	}

	closeBtn(): void {
		this.modalClose.emit();
	}

	closeOverflow(): void {
		if (this.overflowClose) {
			this.modalClose.emit();
		}
	}

	confirm(): void {
		this.modalConfirm.emit();
	}

	unConfirm(): void {
		this.modalUnconfirm.emit();
	}
}