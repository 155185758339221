import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { BannerItem, SelectItem } from "@app/helper/model";
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { RequestState } from '@app/store/store.shared';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/service/app-service.service';
import { CurrencySymbol, getWindowOption } from '@store/common/common.state';
import { BreadcrumbsItem } from '@app/helper/model';
import { MetaService } from '@ngx-meta/core';
import { Title } from '@angular/platform-browser';
import {
	getProductActiveState,
	getProductActiveData,
	ProductActive,
	FetchProductActive,
	FetchProductCompany,
	getProductCompanyData,
	ProductCompany,
	FetchProductLogistic,
	FetchProductSliderSeller,
	getProductSliderSellerData,
	ProductSlider,
	FetchProductSliderViewed,
	getProductSliderViewedData
} from '@store/product/product.state';
import { map } from 'rxjs/operators';
import { wrapperScrollTop } from '@app/helper/common';


type ActiveTabType = 'product' | 'logistic' | 'company';


@Component({
	selector: 'product-page',
	templateUrl: './product-page.component.html',
	styleUrls: ['./product-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductPageComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('productTab', {static: false}) public productTab: ElementRef<any>;

	private pageSubscription: Subscription;
	private slug: string;
	public productData$: Observable<ProductActive>;
	public productDataState$: Observable<RequestState>;
	public companyData$: Observable<ProductCompany>;
	public productListSeller$: Observable<Array<ProductSlider>>;
	public productListViewer$: Observable<Array<ProductSlider>>;
	public bannerList$: BehaviorSubject<Array<BannerItem>>;
	public companyTime$: BehaviorSubject<string>;
	public breadcrumbsList$: BehaviorSubject<Array<BreadcrumbsItem>>;

	public activeTab$: BehaviorSubject<ActiveTabType>;
	public stateSupplierPopup$: BehaviorSubject<boolean>;
	public officeAddress$: Observable<Array<SelectItem<{country_id: number}>>>;
	public linkQueryPreview$: BehaviorSubject<{[key: string]: string}>;
	public isPreview$: Observable<boolean>;
	public isMobile$: BehaviorSubject<boolean>;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private appService: AppService,
		private titleService: Title,
		private metaService: MetaService ) {

		// init state
		this.activeTab$ = new BehaviorSubject('product');
		this.breadcrumbsList$ = new BehaviorSubject([]);
		this.companyTime$ = new BehaviorSubject(null)
		this.linkQueryPreview$ = new BehaviorSubject({});
		this.stateSupplierPopup$ = new BehaviorSubject(false);
		this.isMobile$ = new BehaviorSubject(false);
		this.isPreview$ = this.appService.getIsPreview();

		// @todo temp
		let bannerList = new Array(5).fill('').map((z, i) => {
			let bannerItem: BannerItem;
			if (i % 2) {
				bannerItem = {
					img: '/assets/content/banner_bg.png',
					text: '50% discount on <br> dfvdfvdf <br> British Steel Pipes',
					bage: {
						type: 'sell',
						text: 'sell'
					}
				}
			}
			else {
				bannerItem = {
					img: '/assets/content/banner_bg.png',
					text: '50% discount on <br> British Steel Pipes',
					bage: {
						type: 'best',
						text: 'best'
					}
				}
			}
			return bannerItem;
		});
		this.bannerList$ = new BehaviorSubject(bannerList);
		// --- //

		this.productData$ = this.store.select(getProductActiveData);
		this.productDataState$ = this.store.select(getProductActiveState);
		this.companyData$ = this.store.select(getProductCompanyData);

		this.productListViewer$ = this.store.select(getProductSliderViewedData);

		this.productListSeller$ = combineLatest(
			this.store.select(getProductSliderSellerData),
			this.store.select(getWindowOption)
		).pipe(map(data => {
			let sliderData = data[0];
			let windowOption = data[1];
			if (sliderData && windowOption) {
				if (windowOption.type === 'mobile') {
					return sliderData.slice(0, 4);
				}
				else {
					return sliderData;
				}
			}
			else {
				return [];
			}
		}));

		this.officeAddress$ = this.companyData$.pipe(map(data => {
			let filteredAddress: Array<SelectItem<{country_id: number}>> = [];
			if (data && data.office_address) {
				data.office_address.filter(adress => {
					return adress.contacts.some(contact => contact.type === 'email' && contact.value);
				})
				.forEach(address => {
					let hasAddress = filteredAddress.find(z => z.value.country_id === address.country.id);
					if (!hasAddress) {
						filteredAddress.push({
							label:  address.country ? address.country.name : 'County name is undefined',
							value: {
								country_id: address.country.id
							}
						})
					}
				})
			}
			return filteredAddress;
		}));
	}

	addSuscription(): void {
		this.pageSubscription = new Subscription();

		this.pageSubscription.add(
			this.productDataState$.subscribe(res => {
				if (res && res.error) {
					let redirectLink = res.error['error'].redirect;
					if (redirectLink) {
						this.router.navigate(['product/' + res.error['error'].redirect]);
					}
				}
			})
		);

		this.pageSubscription.add(
			this.activatedRoute.params.subscribe(params => {
				this.activeTab$.next('product');
				this.slug = params['slug'];
				this.store.dispatch(new FetchProductActive({ productSlug: this.slug }));
				this.store.dispatch(new FetchProductCompany({ productSlug: this.slug }));
				this.store.dispatch(new FetchProductLogistic({ productSlug: this.slug }));
			})
		);

		this.pageSubscription.add(
			this.productData$.subscribe(data => {
				if (data && !this.appService.getIsPreviewValue()) {
					this.store.dispatch(new FetchProductSliderSeller({ id: data.product_id }));
					this.store.dispatch(new FetchProductSliderViewed({ id: data.product_id }));
					this.generateBreadcrumbsList(data);
				}

				// add metatag
				// if (data) {
				// 	let image = data.images.find(z => z.is_main);
				// 	let title = data.name;
				// 	let description = data.description;
				// 	this.metaService.setTag('og:title', title);
				// 	this.metaService.setTag('og:image', (image) ? image.url : '');
				// 	this.metaService.setTag('og:description', description);

				// 	this.metaService.setTag('twitter:title', title);
				// 	this.metaService.setTag('twitter:image', (image) ? image.url : '');
				// 	this.metaService.setTag('twitter:description', description);
				// }
			})
		);

		this.pageSubscription.add(
			this.companyData$.subscribe(company => {
				if (company) {
					this.titleService.setTitle(company.name);
					this.linkQuery(company);
					this.getTime(company);
				}
			})
		);

		this.pageSubscription.add(
			combineLatest(
				this.activeTab$,
				this.store.select(getWindowOption)
			).subscribe(data => {
				let activeTab = data[0];
				let screenWidth = data[1];

				if (activeTab === 'product' && this.productTab && this.productTab.nativeElement.classList.contains('fixed-show')) {
					this.productTab.nativeElement.classList.remove('fixed-show');
				}

				if (screenWidth.type === 'mobile') {
					document.querySelector('.app-wrapper').addEventListener('scroll', this.onScroll.bind(this));
					this.isMobile$.next(true);
				}
				else {
					this.isMobile$.next(false);
				}
			})
		);
	}

	// addMetaTag(): void {
	// 	let url = window.location.href;
	// 	this.metaService.setTag('og:url', url);
	// 	this.metaService.setTag('twitter:card', 'summary_large_image');
	// }

	changeActiveTab(val: ActiveTabType): void {
		let currentActiveTab = this.activeTab$.getValue();
		if (currentActiveTab !== val) {
			this.activeTab$.next(val);
		}
	}

	generateBreadcrumbsList(data: ProductActive): void {
		let list = [
			{
				url: '/',
				label: 'breadcrumbs.marketplace'
			},
			{
				url: '/products',
				label: 'breadcrumbs.products'
			}
		];

		if (data.high_level_categories.length !== 0) {
			list.push({
				url: `products/${data.high_level_categories[0].slug}`,
				label: data.high_level_categories[0].name
			});
		}

		list.push({
			url: `product/${this.slug}`,
			label: data.name
		});

		this.breadcrumbsList$.next(list);
	}

	ngOnInit(): void {
		this.addSuscription();
		// this.addMetaTag();
		this.store.dispatch(new CurrencySymbol());
	}

	ngAfterViewInit(): void {
		wrapperScrollTop();
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
		document.querySelector('.app-wrapper').removeEventListener('scroll', this.onScroll.bind(this));
	}

	onScroll(event: any): void {
		if (this.activeTab$.getValue() === 'product' && this.isMobile$.getValue() && this.productTab) {
			let elementOffsetTop = this.productTab.nativeElement.offsetTop;
			if(event.target.scrollTop > elementOffsetTop + 100 ) {
				this.productTab.nativeElement.classList.add('fixed-show');
			}
			else {
				this.productTab.nativeElement.classList.remove('fixed-show');
			}
		}
	}

	linkQuery(company: ProductCompany): void {
		if (this.appService.getIsPreview()) {
			this.linkQueryPreview$.next({ '__preview': 'true', 'companyDomain': company.sub_domain });
		}
		else {
			this.linkQueryPreview$.next({});
		}
	}

	visibleSupplierPopup(): void {
		this.stateSupplierPopup$.next(true);
	}

	closeSupplierPopup(): void {
		this.stateSupplierPopup$.next(false);
	}

	getTime(company: ProductCompany): void {
		let timeZone = company.time_zone;
		let dateTime = new Date();
		let dateTimeOptions = {
			hour12: false,
			hour: 'numeric',
			minute: 'numeric',
			timeZone: timeZone
		};
		let companyTime = `${timeZone} ${dateTime.toLocaleString('nu', dateTimeOptions)}`;
		this.companyTime$.next(companyTime);
	}
}