import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ApiService } from '@api/api.service';
import { of } from 'rxjs';
import {
	CheckoutActions,
	PutWarehouse,
	PutWarehouseSuccess,
	PutWarehouseError,
	CompanyWarehouseItem,
	CompanyWarehouse,
	CompanyWarehouseSuccess,
	CompanyWarehouseError,
	Incoterms,
	DeliveryTypes,
	PaymentTypes,
	CheckoutEntitiesSuccess,
	CheckoutEntitiesError,
	CityItem,
	CityList,
	CityListSuccess,
	CityListError,
	PortItem,
	PortList,
	PortListSuccess,
	PortListError,
	TerminalItem,
	TerminalList,
	TerminalListSuccess,
	TerminalListError
} from './checkout.state';


@Injectable()
export class CheckoutEffects {

	constructor(
		private actions$: Actions,
		private apiService: ApiService
	) { }

	public putWarehouse$ = createEffect(() => this.actions$.pipe(
		ofType(CheckoutActions.putWarehouse),
		mergeMap((actionData: PutWarehouse) => this.apiService.putWarehouse(actionData.payload)
			.pipe(
				switchMap(() => {
					return [
						new PutWarehouseSuccess(),
						new CompanyWarehouse({ companyId: actionData.payload.company_id })
					];
				}),
				catchError(error => {
					return of(new PutWarehouseError(error.errors || error))
				})
			)))
	);

	public companyWarehouse$ = createEffect(() => this.actions$.pipe(
		ofType(CheckoutActions.companyWarehouse),
		mergeMap((actionData: CompanyWarehouse) => this.apiService.fetchCompanyWarehouse(actionData.payload.companyId)
			.pipe(
				map((data: any) => {
					try {
						let companyWarehouseList: Array<CompanyWarehouseItem> = data.map((z: any) => {
							let item: CompanyWarehouseItem = {
								value: {
									id: z.id
								},
								city: z.city.name,
								country: z.country.name,
								port: z.port.name,
								terminal: z.terminal.name
							}
							return item;
						})
						return new CompanyWarehouseSuccess(companyWarehouseList)
					}
					catch(error) {
						console.error("Error --> companyWarehouse", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new CompanyWarehouseError(error.errors || error))
				})
			)))
	);

	public checkoutEntities$ = createEffect(() => this.actions$.pipe(
		ofType(CheckoutActions.checkoutEntities),
		mergeMap(() => this.apiService.fetchCheckoutEntities()
			.pipe(
				map((data: any) => {
					try {
						let incoterms: Array<Incoterms> = data.incoterms.map((z: any) => {
							let delivery_types: Array<DeliveryTypes> = (!z.delivery_types) ? [] : z.delivery_types.map((x: any) => {
								let item: DeliveryTypes = {
									id: x.id,
									slug: x.slug,
									name: x.name
								}
								return item;
							})
							let item: Incoterms = {
								id: z.id,
								code: z.code,
								slug: z.slug,
								name: z.name,
								delivery_types: delivery_types
							}
							return item;
						});
						let paymentTypes: Array<PaymentTypes> = data.payment_types.map((z: any) => {
							let item: PaymentTypes = {
								id: z.id,
								slug: z.slug,
								name: z.name
							}
							return item;
						});
						return new CheckoutEntitiesSuccess({incoterms: incoterms, paymentTypes: paymentTypes})
					}
					catch(error) {
						console.error("Error --> checkoutEntities", error);
						throw error;
					}
				}),
				catchError((error) => {
					return of(new CheckoutEntitiesError(error.errors || error))
				})
			)))
	);

	public cityList$ = createEffect(() => this.actions$.pipe(
		ofType(CheckoutActions.cityList),
		mergeMap((actionData: CityList) => this.apiService.fetchCityList(actionData.payload.countryId, actionData.payload.query)
			.pipe(
				map((data: any) => {
					try {
						let cityList: Array<CityItem> = data.map((z: any) => {
							let item: CityItem = {
								value: {
									id: z.id
								},
								name: z.name,
								secondary_text: z.secondary_text,
								label: `${z.name}, ${z.secondary_text}`
							}
							return item;
						})
						return new CityListSuccess(cityList)
					}
					catch(error) {
						console.error("Error --> cityList", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new CityListError(error.errors || error))
				})
			)))
	);

	public portList$ = createEffect(() => this.actions$.pipe(
		ofType(CheckoutActions.portList),
		mergeMap((actionData: PortList) => this.apiService.fetchPortList(actionData.payload.countryId, actionData.payload.cityId)
			.pipe(
				map((data: any) => {
					try {
						let portList: Array<PortItem> = data.map((z: any) => {
							let item: PortItem = {
								value: {
									id: z.id
								},
								name: z.name,
								country: (z.country) ? z.country.name : '',
								distance: z.distance,
								label: `${z.name},${(z.country) ? ` ${z.country.name}` : ''} (${parseFloat(z.distance).toFixed(0)}km)`
							}
							return item;
						})
						return new PortListSuccess(portList)
					}
					catch(error) {
						console.error("Error --> portList", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new PortListError(error.errors || error))
				})
			)))
	);

	public terminalList$ = createEffect(() => this.actions$.pipe(
		ofType(CheckoutActions.terminalList),
		mergeMap((actionData: TerminalList) => this.apiService.fetchTerminalList(actionData.payload.countryId, actionData.payload.cityId)
			.pipe(
				map((data: any) => {
					try {
						let terminalList: Array<TerminalItem> = data.map((z: any) => {
							let item: TerminalItem = {
								value: {
									id: z.id
								},
								name: z.name,
								country: (z.country) ? z.country.name : '',
								distance: z.distance,
								label: `${z.name},${(z.country) ? ` ${z.country.name}` : ''} (${parseFloat(z.distance).toFixed(0)}km)`
							}
							return item;
						})
						return new TerminalListSuccess(terminalList)
					}
					catch(error) {
						console.error("Error --> terminalList", error);
						throw error;
					}
				}),
				catchError(error => {
					return of(new TerminalListError(error.errors || error))
				})
			)))
	);
}