import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@api/api.service';
import { of } from 'rxjs';
import { toNumber } from "@app/helper/common";
import { MAX_QUANTITY } from "@app/helper/constant";
import {
	ProductActions,
	ProductActive,
	FetchProductActive,
	FetchProductActiveSuccess,
	FetchProductActiveError,
	ProductLogistic,
	FetchProductLogistic,
	FetchProductLogisticSuccess,
	FetchProductLogisticError,
	ProductCompany,
	FetchProductCompany,
	FetchProductCompanySuccess,
	FetchProductCompanyError,
	ProductSlider,
	FetchProductSliderSeller,
	FetchProductSliderSellerSuccess,
	FetchProductSliderSellerError,
	FetchProductSliderViewed,
	FetchProductSliderViewedSuccess,
	FetchProductSliderViewedError
} from "./product.state";


// Helper
const getSliderData = (data: any): Array<ProductSlider> => {
	const sliderData: Array<ProductSlider> = data.map((z: any) => {
		let isMainImg = z.images.find((image: any) => image.is_main);
		let image = (isMainImg && isMainImg.url) ? isMainImg.url : (z.images[0] && z.images[0].url) ? z.images[0].url : null;
		let item: ProductSlider = {
			imgSrc: image,
			brand: z.category,
			title: z.name,
			oldPrice: '',
			currentPrice: z.price,
			currency: z.currency,
			slug: z.slug
		}
		return item;
	});
	return sliderData;
};


@Injectable()
export class ProductEffects {

	constructor(
		private actions$: Actions,
		private apiService: ApiService
	) { }

	public fetchProductActive$ = createEffect(() => this.actions$.pipe(
		ofType(ProductActions.fetchProductActive),
		mergeMap((actionData: FetchProductActive) => this.apiService.fetchProductActive(actionData.payload.productSlug)
		.pipe(
			map((data: any) => {

				try {
					let commonData: any;
					if (data.variations && data.variations.length !== 0) {
						commonData = data.variations.find((z: any) => z.slug === actionData.payload.productSlug) || data;
					}
					else {
						commonData = data;
					}

					let commonProductData = {
						id: commonData.id,
						price: toNumber(commonData.price),
						available_quantity: toNumber(commonData.available_quantity),
						minimum_order: toNumber(commonData.minimum_order),
						availability: commonData.availability,
						availability_date: commonData.availability_date,
						availability_days: toNumber(commonData.availability_days),
						max_quantity: (toNumber(commonData.available_quantity) !== null && toNumber(commonData.available_quantity) < MAX_QUANTITY) ? toNumber(commonData.available_quantity) : MAX_QUANTITY,
						min_quantity: (toNumber(commonData.minimum_order) !== null && toNumber(commonData.minimum_order) > 0) ? toNumber(commonData.minimum_order) : 1,
						images: commonData.images.map((z: any) => {
							return {
								url: z.url,
								is_main: z.is_main
							}
						}),
						bulk_prices: commonData.bulk_prices.map((z: any) => {
							return {
								price: toNumber(z.price),
								quantity: toNumber(z.quantity)
							}
						})
					};

					let productData: ProductActive = {
						...commonProductData,
						slug: actionData.payload.productSlug,
						name: data.name,
						product_id: data.id,
						description: data.description,
						currency: data.currency,
						unit: data.unit,
						unit_in_package: data.unit_in_package,
						quantity_in_one_package: toNumber(data.quantity_in_one_package),
						has_sample: data.has_sample,
						sample_price: toNumber(data.sample_price),
						show_bulk_pricing: data.show_bulk_pricing,
						trademark: !data.trademark ? null : {
							id: data.trademark.id,
							brand_logo: data.trademark.brand_logo,
							brand_name: data.trademark.brand_name,
							is_verified: data.trademark.is_verified
						},
						certifications: data.certifications.map((z: any) => {
							return {
								id: z.id,
								certificate_id: z.certificate_id,
								site_url: z.site_url,
								is_verified: z.is_verified,
								document_verification: z.document_verification,
								created_at: z.created_at,
								certificate: !z.certificate ? null : {
									id: z.certificate.id,
									name: z.certificate.name,
									website: z.certificate.website,
									type: z.certificate.type,
									description: z.certificate.description,
									logo: z.certificate.logo
								}
							}
						}),
						features: data.features.map((z: any) => {
							return {
								attribute_id: z.attribute_id,
								name: z.name,
								options_list: z.options_list.map((x: any) => {
									return {
										id: x.id,
										attribute_id: x.attribute_id,
										name: x.name
									}
								})
							}
						}),
						categories: data.categories.map((z: any) => {
							return {
								id: z.id,
								full_name: z.full_name,
								level: z.level,
								slug: z.slug
							}
						}),
						high_level_categories: data.high_level_categories.map((z: any) => {
							return {
								id: z.id,
								name: z.name,
								slug: z.slug
							}
						}),
						variations: data.variations.map((z: any) => {
							return {
								id: z.id,
								product_id: z.product_id,
								availability: z.availability,
								availability_date: z.availability_date,
								availability_days: toNumber(z.availability_days),
								available_quantity: toNumber(z.available_quantity),
								bulk_prices: z.bulk_prices.map((x: any) => {
									return {
										price: toNumber(x.price),
										quantity: toNumber(x.quantity)
									}
								}),
								created_date: z.created_date,
								edited_date: z.edited_date,
								features: z.features.map((x: any) => {
									return {
										attribute_id: x.attribute_id,
										attribute_name: x.attribute_name,
										attribute_option_id: x.attribute_option_id,
										attribute_option_name: x.attribute_option_name
									}
								}),
								images: z.images.map((x: any) => {
									return {
										url: x.url,
										is_main: x.is_main
									}
								}),
								minimum_order: toNumber(z.minimum_order),
								name: z.name,
								price: toNumber(z.price),
								sku: z.sku,
								slug: z.slug
							}
						})
					}
					return new FetchProductActiveSuccess(productData);
				}
				catch(error) {
					console.error("Error --> fetchProductActive", error);
					throw error;
				}
			}),
			catchError(error => {
				return of(new FetchProductActiveError(error.errors || error))
			})
		)))
	);

	public fetchProductLogistic$ = createEffect(() => this.actions$.pipe(
		ofType(ProductActions.fetchProductLogistic),
		mergeMap((actionData: FetchProductLogistic) => this.apiService.fetchProductLogistic(actionData.payload.productSlug)
		.pipe(
			map((data: any) => {
				try {
					let productLogistic: ProductLogistic = {
						id: data.id,
						sku: data.sku,
						hs_classifier: data.hs_classifier,
						place_of_origin: data.place_of_origin,
						warehouse_locations: data.warehouse_locations.map((z: any) => {
							return {
								country: z.country,
								city: z.city,
								port: z.port,
								terminal: z.terminal
							}
						}),
						freight_size: (!data.freight_size) ? null : {
							width: data.freight_size.width,
							height: data.freight_size.height,
							length: data.freight_size.length,
							length_unit: data.freight_size.length_unit,
							weight: data.freight_size.weight,
							weight_unit: data.freight_size.weight_unit
						},
						sample_size: (!data.sample_size) ? null : {
							width: data.sample_size.width,
							height: data.sample_size.height,
							length: data.sample_size.length,
							length_unit: data.sample_size.length_unit,
							weight: data.sample_size.weight,
							weight_unit: data.sample_size.weight_unit
						}
					}
					return new FetchProductLogisticSuccess(productLogistic);
				}
				catch(error) {
					console.error("Error --> fetchProductLogistic", error);
					throw error;
				}
			}),
			catchError(error => {
				return of(new FetchProductLogisticError(error.errors || error))
			})
		)))
	);

	public fetchProductCompany$ = createEffect(() => this.actions$.pipe(
		ofType(ProductActions.fetchProductCompany),
		mergeMap((actionData: FetchProductCompany) => this.apiService.fetchProductCompany(actionData.payload.productSlug)
		.pipe(
			map((data: any) =>{
				try {
					let productCompany: ProductCompany = {
						id: data.id,
						name: data.name,
						description: data.description,
						is_verified: data.is_verified,
						logo: data.logo,
						sub_domain: data.sub_domain,
						business_type: (!data.business_type) ? null : {
							id: data.business_type.id,
							type: data.business_type.type,
							slug: data.business_type.slug,
							name: data.business_type.name
						},
						registration_number: data.registration_number,
						annual_revenue: data.annual_revenue,
						year_established: data.year_established,
						number_of_employees: data.number_of_employees,
						trademarks: data.trademarks.map((z: any) => {
							return {
								id: z.id,
								company_id: z.company_id,
								brand_logo: z.brand_logo,
								brand_name: z.brand_name,
								verification_link: z.verification_link,
								is_verified: z.is_verified,
								created_at: z.created_at,
								updated_at: z.updated_at,
								verification_documents: z.verification_documents
							}
						}),
						time_zone: data.time_zone,
						certificates: data.certificates.map((z: any) => {
							return {
								id: z.id,
								certificate_id: z.certificate_id,
								site_url: z.site_url,
								is_verified: z.is_verified,
								document_verification: z.document_verification,
								created_at: z.created_at,
								certificate: (!z.certificate) ? null : {
									id: z.certificate.id,
									name: z.certificate.name,
									website: z.certificate.website,
									type: z.certificate.type,
									description: z.certificate.description,
									logo: z.certificate.logo
								}
							}
						}),
						economic_empowerment: {
							women_owned_is_verified: data.economic_empowerment ? data.economic_empowerment.women_owned_is_verified : false,
							refugee_owned_is_verified: data.economic_empowerment ? data.economic_empowerment.refugee_owned_is_verified : false,
							small_business_is_verified: data.economic_empowerment ? data.economic_empowerment.small_business_is_verified : false
						},
						office_address: data.office_address.map((z: any) => {
							return {
								address: z.address,
								city: (!z.city) ? null : {
									id: z.city.id,
									name: z.city.name
								},
								contacts: z.contacts.map((x: any) => {
									return {
										id: x.id,
										is_verified: x.is_verified,
										type: x.type,
										value: x.value
									}
								}),
								country: (!z.country) ? null : {
									id: z.country.id,
									name: z.country.name
								},
								id: z.id,
								zip_code: z.zip_code
							}
						})
					}
					return new FetchProductCompanySuccess(productCompany);
				}
				catch(error) {
					console.error("Error --> fetchProductCompany", error);
					throw error;
				}
			}),
			catchError(error => {
				return of(new FetchProductCompanyError(error.errors || error))
			})
		)))
	);

	public fetchProductSliderSeller$ = createEffect(() => this.actions$.pipe(
		ofType(ProductActions.fetchProductSliderSeller),
		mergeMap((actionData: FetchProductSliderSeller) => this.apiService.fetchProductSliderSeller(actionData.payload.id)
		.pipe(
			map((data: any) => {
				try {
					const sliderData = getSliderData(data);
					return new FetchProductSliderSellerSuccess(sliderData);
				}
				catch(error) {
					console.error("Error --> fetchProductSliderSeller", error);
					throw error;
				}
			}),
			catchError(error => {
				return of(new FetchProductSliderSellerError(error.errors || error))
			})
		)))
	);

	public fetchProductSliderViewed$ = createEffect(() => this.actions$.pipe(
		ofType(ProductActions.fetchProductSliderViewed),
		mergeMap((actionData: FetchProductSliderViewed) => this.apiService.fetchProductSliderViewed(actionData.payload.id)
		.pipe(
			map((data: any) => {
				try {
					const sliderData = getSliderData(data);
					return new FetchProductSliderViewedSuccess(sliderData);
				}
				catch(error) {
					console.error("Error --> fetchProductSliderSeller", error);
					throw error;
				}
			}),
			catchError(error => {
				return of(new FetchProductSliderViewedError(error.errors || error))
			})
		)))
	);
}