import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UserService } from '@app/service/user-service.service'
import { IEnv, environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getNotifications, NotificationsItem, getWindowOption } from '@app/store/common/common.state';
import * as moment from 'moment';
import { utc } from 'moment-timezone'
import { Observable, BehaviorSubject } from 'rxjs';
import { AppService } from '@app/service/app-service.service';
import { getOrderListCount, ToggleModalVisible } from "@app/store/order/order.state";
import {
	getUserIsLogged,
	UserData,
	getUserData
} from "@store/user/user.state";
import { getOrderPage } from "@store/router.state";
import { ActivatedRoute } from '@angular/router';

export interface NotificationCategory {
	id: number,
	bg_color: string,
	name: string,
	slug: string
}

@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppHeaderComponent implements OnDestroy {

	public env: IEnv;
	public isLoggedIn$: Observable<boolean>;
	public userData$: Observable<UserData>;
	public notificationData$: Observable<Array<NotificationsItem>>;
	public orderListCount$: Observable<number>;
	public isOrderPage$: Observable<boolean>;
	public isReadNotification$: BehaviorSubject<boolean>;
	public isPreview$: Observable<boolean>;

	public isMobile$: BehaviorSubject<boolean>;
	public mobileMenuState$: BehaviorSubject<boolean>;
	public mobileNotificationState$: BehaviorSubject<boolean>;
	public mobileUnregisterMenuState$: BehaviorSubject<boolean>;
	public mobileSubMenuState$: BehaviorSubject<boolean>;

	constructor(
		private userService: UserService,
		private appService: AppService,
		private activatedRoute: ActivatedRoute,
		private store: Store<AppStore.AppStoreState>) {

		this.env = environment;
		this.notificationData$ = this.store.select(getNotifications);
		this.orderListCount$ = this.store.select(getOrderListCount);
		this.isLoggedIn$ = this.store.select(getUserIsLogged);
		this.userData$ = this.store.select(getUserData);
		this.isOrderPage$ = this.store.select(getOrderPage);
		this.isReadNotification$ = new BehaviorSubject(false);
		this.isPreview$ = this.appService.getIsPreview();

		this.isMobile$ = new BehaviorSubject(false);
		this.mobileNotificationState$ = new BehaviorSubject(false);
		this.mobileMenuState$ = new BehaviorSubject(false);
		this.mobileUnregisterMenuState$ = new BehaviorSubject(false);
		this.mobileSubMenuState$ = new BehaviorSubject(false);


		this.store.select(getWindowOption).subscribe(val => {
			if (val && val.type === 'mobile') {
				this.isMobile$.next(true);
			}
			else {
				this.isMobile$.next(false);
			}
		}).unsubscribe();
	}

	openOrderModal(): void {
		this.store.dispatch(new ToggleModalVisible(true));
	}

	addDisabledWrapper() {
		if(!document.querySelector('.app-wrapper').classList.contains('disabled-body')) {
			document.querySelector('.app-wrapper').classList.add('disabled-body');
		}
	}

	removeDisabledWrapper() {
		if(document.querySelector('.app-wrapper').classList.contains('disabled-body')) {
			document.querySelector('.app-wrapper').classList.remove('disabled-body');
		}
	}

	navLink(link: string, envKey?: string): void {
		event.preventDefault();
		if (envKey !== undefined) {
			if (link === 'company') {
				this.store.select(getUserData).subscribe(val => {
					if (val && val.companies && val.companies.length) {
						window.open(this.env.tradalaxyUrl + '/companies/' + val.companies[0].id + '/companyInfo', '_blank');
					}
					else {
						window.open(this.env.tradalaxyUrl, '_blank');
					}
				}).unsubscribe();
			}
			else if (link === 'product') {
				this.store.select(getUserData).subscribe(val => {
					if (val && val.companies && val.companies.length) {
						window.open(this.env.tradalaxyUrl + '/companies/' + val.companies[0].id + '/products', '_blank');
					}
					else {
						window.open(this.env.tradalaxyUrl, '_blank');
					}
				}).unsubscribe();
			}
			else {
				window.open(this.env[envKey] + link, '_blank');
			}
			return;
		}
	}

	userLogout(): void {
		this.userService.logout();

		this.mobileNotificationState$.next(false);
		this.mobileMenuState$.next(false);
		this.mobileUnregisterMenuState$.next(false);
		this.mobileSubMenuState$.next(false);
		this.removeDisabledWrapper();
	}

	dateFormat(date: string): string {
		return moment(utc(date, "YYYY-MM-DD hh:mm:ss").toDate()).calendar();
	}

	toggleMobileNotification() {
		this.mobileNotificationState$.next(!this.mobileNotificationState$.getValue());

		if(this.mobileNotificationState$.getValue()) {
			this.addDisabledWrapper();
		} else {
			this.removeDisabledWrapper();
		}
	}

	toggleMobileMenu() {
		this.mobileMenuState$.next(!this.mobileMenuState$.getValue());

		if(this.mobileMenuState$.getValue()) {
			this.addDisabledWrapper();
		} else {
			this.removeDisabledWrapper();
		}
	}

	toggleMobileUnregisterMenu() {
		this.mobileUnregisterMenuState$.next(!this.mobileUnregisterMenuState$.getValue());
		this.mobileSubMenuState$.next(false);

		if(this.mobileUnregisterMenuState$.getValue()) {
			this.addDisabledWrapper();
		} else {
			this.removeDisabledWrapper();
		}
	}

	toggleMobileSubMenu() {
		this.mobileSubMenuState$.next(!this.mobileSubMenuState$.getValue())
	}

	getCategoriesOfNotification(notificationCategories: Array<NotificationCategory>): string {
		return notificationCategories.map(item => item.slug).join(',');
	}

	goToNotification(item) {
		let queryParams = `?category=${this.getCategoriesOfNotification(item.notification_categories)}`
		window.open(this.env.tradalaxyUrl + '/notifications' + queryParams, '_self');
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.removeDisabledWrapper();
	}
}
