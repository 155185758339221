import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { BannerItem } from "@app/helper/model";


@Component({
  selector: 'promo-banner-item',
  templateUrl: './promo-banner-item.component.html',
  styleUrls: ['./promo-banner-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PromoBannerItemComponent {

	@Input() public bannerCustomTemplate: boolean;
	@Input() public bannerOption: BannerItem;
	@Input() public bannerActionText: string;

	@Output() public bannerAction: EventEmitter<void>;

  	constructor() {
		// init state
		this.bannerCustomTemplate = false;
		this.bannerOption = {
			img: '',
			text: '',
			bage: null
		}
		this.bannerActionText = '';

		this.bannerAction = new EventEmitter();
	}

	bannerActionHandler(): void {
		this.bannerAction.emit();
	}
}
