import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestState, initRequestState } from '@store/store.shared';
import { TYPE_COMPANY } from "@app/helper/constant";


// Interface
export interface WindowOption {
	type: 'mobile' | 'tablet' | 'desktop',
	width: number
}

export interface EmailSubscription {
	success: boolean
}

export interface BlogSubscription {
	success: boolean
}

export interface SendSupplierForm {
	success: boolean
}

export interface SendSupplierFormData {
	first_name: string,
	last_name: string,
	company_name: string,
	country_id: number,
	email: string,
	phone: string,
	text: string
}

export interface NotificationsItem {
	id: number,
	title: string,
	notification_categories: Array<{
		id: number,
		bg_color: string,
		name: string,
		slug: string
	}>,
	created_at: string
}

export interface CurrencySymbolItem {
	id: number,
	code: string,
	symbol: string,
	name: string
}

export interface TypeCompanyItem {
	value: {
		id: number,
	},
	label: string
}

export interface IndustryItem {
	value: {
		id: number,
	},
	label: string
}

export interface CountryItem {
	value: {
		id: number;
    	code: string;
	},
	label: string
}


// State
export interface CommonState {
	windowOption: WindowOption,
	emailSubscription: {
		requestState: RequestState,
		data: EmailSubscription
	},
	blogSubscription: {
		requestState: RequestState,
		data: BlogSubscription
	},
	sendSupplierForm: {
		requestState: RequestState,
		data: SendSupplierForm
	},
	notifications: {
		requestState: RequestState,
		data: Array<NotificationsItem>
	},
	currencySymbol: {
		requestState: RequestState,
		data: Array<CurrencySymbolItem>
	},
	typeCompany: {
		requestState: RequestState,
		data: Array<TypeCompanyItem>
	},
	industry: {
		requestState: RequestState,
		data: Array<IndustryItem>
	},
	country: {
		requestState: RequestState,
		data: Array<CountryItem>
	}
};

const INITIAL_STATE: CommonState = {
	windowOption: undefined,
	emailSubscription: {
		requestState: initRequestState(),
		data: undefined
	},
	blogSubscription: {
		requestState: initRequestState(),
		data: undefined
	},
	sendSupplierForm: {
		requestState: initRequestState(),
		data: undefined
	},
	notifications: {
		requestState: initRequestState(),
		data: undefined
	},
	currencySymbol: {
		requestState: initRequestState(),
		data: undefined
	},
	typeCompany: {
		requestState: initRequestState(),
		data: TYPE_COMPANY
	},
	industry: {
		requestState: initRequestState(),
		data: undefined
	},
	country: {
		requestState: initRequestState(),
		data: undefined
	}
};


// Action
export const CommonActions = {
	changeWindowOption: '[Common] Change_Window_Option',

	emailSubscriptionFetch: '[Common] Email_Subscription_Fetch',
	emailSubscriptionSuccess: '[Common] Email_Subscription_Success',
	emailSubscriptionError: '[Common] Email_Subscription_Error',

	blogSubscriptionFetch: '[Common] Blog_Subscription_Fetch',
	blogSubscriptionSuccess: '[Common] Blog_Subscription_Success',
	blogSubscriptionError: '[Common] Blog_Subscription_Error',

	sendSupplierFormFetch: '[Common] Send_Supplier_Form_Fetch',
	sendSupplierFormSuccess: '[Common] Send_Supplier_Form_Success',
	sendSupplierFormError: '[Common] Send_Supplier_Form_Error',

	notifications: '[Common] Notifications',
	notificationsSuccess: '[Common] Notifications_Success',
	notificationsError: '[Common] Notifications_Error',

	currencySymbol: '[Common] Currency_Symbol',
	currencySymbolSuccess: '[Common] Currency_Symbol_Success',
	currencySymbolError: '[Common] Currency_Symbol_Error',

	industry: '[Common] Industry',
	industrySuccess: '[Common] Industry_Success',
	industryError: '[Common] Industry_Error',

	country: '[Common] Country',
	countrySuccess: '[Common] Country_Success',
	countryError: '[Common] Country_Error'
};

export class ChangeWindowOption implements Action {
	readonly type = CommonActions.changeWindowOption;
	constructor(public payload: WindowOption) { }
};

export class EmailSubscriptionFetch implements Action {
	readonly type = CommonActions.emailSubscriptionFetch;
	constructor(public payload: { productId: string, email: string }) { }
};

export class EmailSubscriptionSuccess implements Action {
	readonly type = CommonActions.emailSubscriptionSuccess;
	constructor(public payload: EmailSubscription) { }
};

export class EmailSubscriptionError implements Action {
	readonly type = CommonActions.emailSubscriptionError;
	constructor(public payload: any) { }
};

export class BlogSubscriptionFetch implements Action {
	readonly type = CommonActions.blogSubscriptionFetch;
	constructor(public payload: { email: string }) { }
};

export class BlogSubscriptionSuccess implements Action {
	readonly type = CommonActions.blogSubscriptionSuccess;
	constructor(public payload: BlogSubscription) { }
};

export class BlogSubscriptionError implements Action {
	readonly type = CommonActions.blogSubscriptionError;
	constructor(public payload: any) { }
};

export class SendSupplierFormFetch implements Action {
	readonly type = CommonActions.sendSupplierFormFetch;
	constructor(public payload: { companyId: number, data: SendSupplierFormData }) { }
};

export class SendSupplierFormSuccess implements Action {
	readonly type = CommonActions.sendSupplierFormSuccess;
	constructor(public payload: SendSupplierForm) { }
};

export class SendSupplierFormError implements Action {
	readonly type = CommonActions.sendSupplierFormError;
	constructor(public payload: any) { }
};

export class Notifications implements Action {
	readonly type = CommonActions.notifications;
	constructor(public payload: { user_id: any}) { }
};

export class NotificationsSuccess implements Action {
	readonly type = CommonActions.notificationsSuccess;
	constructor(public payload: Array<NotificationsItem>) { }
};

export class NotificationsError implements Action {
	readonly type = CommonActions.notificationsError;
	constructor(public payload: any) { }
};

export class CurrencySymbol implements Action {
	readonly type = CommonActions.currencySymbol;
	constructor() { }
};

export class CurrencySymbolSuccess implements Action {
	readonly type = CommonActions.currencySymbolSuccess;
	constructor(public payload: Array<CurrencySymbolItem>) { }
};

export class CurrencySymbolError implements Action {
	readonly type = CommonActions.currencySymbolError;
	constructor(public payload: any) { }
};

export class Industry implements Action {
	readonly type = CommonActions.industry;
	constructor() { }
};

export class IndustrySuccess implements Action {
	readonly type = CommonActions.industrySuccess;
	constructor(public payload: Array<IndustryItem>) { }
};

export class IndustryError implements Action {
	readonly type = CommonActions.industryError;
	constructor(public payload: any) { }
};

export class Country implements Action {
	readonly type = CommonActions.country;
	constructor() { }
};

export class CountrySuccess implements Action {
	readonly type = CommonActions.countrySuccess;
	constructor(public payload: Array<CountryItem>) { }
};

export class CountryError implements Action {
	readonly type = CommonActions.countryError;
	constructor(public payload: any) { }
};


// Reducer
export function CommonReducer(state = INITIAL_STATE, action: Action) {
	switch (action.type) {

		case CommonActions.changeWindowOption: {
			let payload = (action as ChangeWindowOption).payload;
			return {
				...state,
				windowOption: payload
			}
		}; break;

		case CommonActions.emailSubscriptionFetch: {
			return {
				...state,
				emailSubscription: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.emailSubscriptionSuccess: {
			let payload = (action as EmailSubscriptionSuccess).payload;
			return {
				...state,
				emailSubscription: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.emailSubscriptionError: {
			let payload = (action as EmailSubscriptionError).payload;
			return {
				...state,
				emailSubscription: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		case CommonActions.blogSubscriptionFetch: {
			return {
				...state,
				blogSubscription: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.blogSubscriptionSuccess: {
			let payload = (action as BlogSubscriptionSuccess).payload;
			return {
				...state,
				blogSubscription: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.blogSubscriptionError: {
			let payload = (action as BlogSubscriptionError).payload;
			return {
				...state,
				blogSubscription: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		case CommonActions.sendSupplierFormFetch: {
			return {
				...state,
				sendSupplierForm: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.sendSupplierFormSuccess: {
			let payload = (action as SendSupplierFormSuccess).payload;
			return {
				...state,
				sendSupplierForm: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.sendSupplierFormError: {
			let payload = (action as SendSupplierFormError).payload;
			return {
				...state,
				sendSupplierForm: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		case CommonActions.notifications: {
			return {
				...state,
				notifications: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.notificationsSuccess: {
			let payload = (action as NotificationsSuccess).payload;
			return {
				...state,
				notifications: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.notificationsError: {
			let payload = (action as NotificationsError).payload;
			return {
				...state,
				notifications: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		case CommonActions.currencySymbol: {
			return {
				...state,
				currencySymbol: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.currencySymbolSuccess: {
			let payload = (action as CurrencySymbolSuccess).payload;
			return {
				...state,
				currencySymbol: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.currencySymbolError: {
			let payload = (action as CurrencySymbolError).payload;
			return {
				...state,
				currencySymbol: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		case CommonActions.industry: {
			return {
				...state,
				industry: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.industrySuccess: {
			let payload = (action as IndustrySuccess).payload
			return {
				...state,
				industry: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.industryError: {
			let payload = (action as IndustryError).payload;
			return {
				...state,
				industry: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		case CommonActions.country: {
			return {
				...state,
				country: {
					requestState: {
						isFetch: true,
						isError: false,
						error: ''
					},
					data: undefined
				}
			}
		}; break;
		case CommonActions.countrySuccess: {
			let payload = (action as CountrySuccess).payload
			return {
				...state,
				country: {
					requestState: {
						isFetch: false,
						isError: false,
						error: ''
					},
					data: payload
				}
			}
		}; break;
		case CommonActions.countryError: {
			let payload = (action as CountryError).payload;
			return {
				...state,
				country: {
					requestState: {
						isFetch: false,
						isError: true,
						error: payload
					},
					data: undefined
				}
			}
		}; break;

		default: {
			return state;
		}
	}
};


// Getter
export const getCommonState = createFeatureSelector<CommonState>('commonState');

export const getWindowOption = createSelector(
	getCommonState,
	(state: CommonState) => state.windowOption
);

export const getNotifications = createSelector(
	getCommonState,
	(state: CommonState) => state.notifications.data
);

export const getCurrencySymbol = createSelector(
	getCommonState,
	(state: CommonState) => state.currencySymbol.data
);

export const getCurrencySymbolState = createSelector(
	getCommonState,
	(state: CommonState) => state.currencySymbol.requestState
);

export const getTypeCompany = createSelector(
	getCommonState,
	(state: CommonState) => state.typeCompany.data
);

export const getIndustry = createSelector(
	getCommonState,
	(state: CommonState) => state.industry.data
);

export const getIndustryState = createSelector(
	getCommonState,
	(state: CommonState) => state.industry
);

export const getCountry = createSelector(
	getCommonState,
	(state: CommonState) => state.country.data
);

export const getCountryState = createSelector(
	getCommonState,
	(state: CommonState) => state.country
);