import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Component
import { UiKitIconComponent } from "./uikit-icon.component";


@NgModule({
	imports: [
		CommonModule
	],
    declarations: [
		UiKitIconComponent,
	],
	exports: [
		UiKitIconComponent
	]
})

export class UiKitIconModule { }
