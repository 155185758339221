import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


export class ErrorItem {
	message: Array<string>
}


@Injectable()
export class ErrorService {

	private errorList: BehaviorSubject<Array<ErrorItem>>;
	private errorTimer: any;

	constructor() {
		this.errorList = new BehaviorSubject([]);
	}

	pushError(message: Array<string>): void {
		let errorList = this.errorList.getValue();
		let errorItem = {
			message: message
		};

		errorList.push(errorItem);
		this.errorList.next(errorList);

		clearTimeout(this.errorTimer);
		this.errorTimer = setTimeout(() => {
			this.errorList.next([]);
		}, 5000);
	}

	getErrors(): Observable<Array<ErrorItem>> {
		return this.errorList.asObservable();
	}
}
