import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// custom modules
import { UiKitIconModule, UiKitInputModule, UiKitSliderModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";
import { SharedModule } from "@app/shared/shared";
import { NguCarouselModule } from '@ngu/carousel';
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';

// component
import { CompanyPageComponent } from "./company-page.component";
import { CompanyTab } from './company-tab/company-tab.component';
import { ProductTab } from './product-tab/product-tab.component';
import { SupplierForm } from './supplier-form/supplier-form.component';
// import { BigProduct } from './big-product/big-product.component';
import { TradeInfoTab } from './trade-info-tab/trade-info-tab.component';
import { PromoProductsList } from './promo-products-list/promo-products-list.component';
import { InsideCategory } from './inside-category/inside-category.component';
import { SliderWithBanners } from './slider-with-banners/slider-with-banners.component';
import { ContactsTab } from './contacts-tab/contacts-tab.component';
import { CompanySummaryComponent } from "./company-summary/company-summary.component";

// directives
import { ClickOutsideDirective } from '@app/directives/click-outside.directive';

// pipe
import { PipeModule } from "@app/pipe/pipe.module";

@NgModule({
	declarations: [
		CompanyPageComponent,
		CompanyTab,
		ProductTab,
		TradeInfoTab,
		ContactsTab,
		// BigProduct,
		PromoProductsList,
		InsideCategory,
		SliderWithBanners,
		ClickOutsideDirective,
		SupplierForm,
		CompanySummaryComponent
	],
	imports: [
		CommonModule,
		UiKitIconModule,
		UiKitInputModule,
		UiKitSliderModule,
		SharedModule,
		NguCarouselModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		Ng2TelInputModule,
		PipeModule
	],
	providers: []
})

export class CompanyPageModule { }
