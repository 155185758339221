import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { ProductActive, getProductActiveData, ProductCompany, getProductCompanyData, UpdateProductActiveQuantity, getProductActiveQuantity } from '@app/store/product/product.state';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from "rxjs/operators";
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { EmailSubscriptionFetch, CommonActions } from '@app/store/common/common.state';
import { AppService } from '@app/service/app-service.service';
import { AddOrderListItem, ToggleModalVisible } from '@store/order/order.state';
import { ActivatedRoute } from '@angular/router';
import { getOrderListOption } from "@store/order/order.state";
import { commonValidator } from "@app/helper/validator";
import { getCurrentBulkPrice } from "@store/order/order.state";
import { FetchOrderListData, getFetchOrderData } from '@app/store/order/fetch-order.state';


@Component({
	selector: 'product-widget',
	templateUrl: './product-widget.component.html',
	styleUrls: ['./product-widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductWidgetComponent implements OnInit, OnDestroy {

	// state
	private pageSubscription: Subscription;
	public stateEmailPopup$: BehaviorSubject<boolean>;

	// data
	private productSlug: string;
	public productData$: Observable<ProductActive>;
	public companyData$: Observable<ProductCompany>;
	public hasOrderProduct$: Observable<boolean>;
	public mainImage$: BehaviorSubject<string | null>;
	public availabilityDay$: BehaviorSubject<string>;
	public productPrice$: BehaviorSubject<{currentPrice: number, oldPrice: number}>;

	// form
	public emailForm: FormControl;
	public quantityControl: FormControl;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		public appService: AppService,
		private actionsSubject: ActionsSubject,
		public activatedRoute: ActivatedRoute) {

		this.pageSubscription = new Subscription();
		this.stateEmailPopup$ = new BehaviorSubject(false);

		this.productData$ = this.store.select(getProductActiveData);
		this.companyData$ = this.store.select(getProductCompanyData);
		this.mainImage$ = new BehaviorSubject(null);
		this.availabilityDay$ = new BehaviorSubject('');
		this.productPrice$ = new BehaviorSubject({
			currentPrice: 0,
			oldPrice: null
		});

		this.hasOrderProduct$ = combineLatest(
			this.productData$,
			this.store.select(getOrderListOption)
		).pipe(map(list => {
			let productData = list[0];
			let orderListOption = list[1];
			if (productData && orderListOption) {
				return orderListOption.some(z => {
					return z.order_products.some(x => x.id === productData.id);
				})
			}
			else {
				return false;
			}
		}));

		this.quantityControl = new FormControl(1);
		this.emailForm = new FormControl('',  commonValidator.email);
	}

	ngOnInit(): void {

		// init quantityControl value
		combineLatest(
			this.store.select(getProductActiveQuantity),
			this.productData$
		).subscribe(val => {
			let quantity = val[0];
			let data = val[1];
			if (quantity) {
				this.quantityControl.setValue(quantity);
				this.setProductPrice(data, quantity);
			}
			else {
				this.quantityControl.setValue(data.min_quantity);
				this.setProductPrice(data, data.min_quantity);
			}
		}).unsubscribe();

		this.pageSubscription.add(
			this.activatedRoute.params.subscribe(res => {
				this.productSlug = res.slug;
			})
		);

		this.pageSubscription.add(
			combineLatest(
				this.productData$,
				this.quantityControl.valueChanges
			).subscribe(data => {
				let productData = data[0];
				let quantity = data[1];
				this.setProductPrice(productData, Number(quantity));
			})
		);

		this.pageSubscription.add(
			this.quantityControl.valueChanges.subscribe(res => {
				let quantity = Number(res);
				this.store.dispatch(new UpdateProductActiveQuantity(quantity));
			})
		);

		this.pageSubscription.add(
			this.productData$.subscribe(data => {
				if (data) {
					let image = data.images.find(image => image.is_main) && data.images.find(image => image.is_main).url || data.images[0] && data.images[0].url || null;
					this.mainImage$.next(image);
					this.getAilabilityDay(data);
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}

	setProductPrice(productData: ProductActive, quantity: number): void {
		if (productData && Number(quantity) <= productData.max_quantity && Number(quantity) >= productData.min_quantity) {
			let productPrice = getCurrentBulkPrice({
				quantity: Number(quantity),
				data: {
					show_bulk_pricing: productData.show_bulk_pricing,
					bulk_prices: productData.bulk_prices,
					price: productData.price
				}
			});
			this.productPrice$.next(productPrice);
		}
	}

	visibleOrderPopupTemp(): void {

		if (!this.appService.getIsPreviewValue()) {
			this.productData$.subscribe(data => {

				let option = {};
				if(data.variations.length !== 0) {
					option = {
						"product_id": data.product_id,
						"variation_id": data.id,
						"quantity": this.quantityControl.value
					}
				} else {
					option = {
						"product_id": data.product_id,
						"quantity": this.quantityControl.value
					}
				}

				// add new item
				this.store.dispatch(new FetchOrderListData(option as any));
				this.pageSubscription.add(
					this.store.select(getFetchOrderData).subscribe(res => {
						if(res) {
							this.store.dispatch(new AddOrderListItem(this.store));
							this.store.dispatch(new ToggleModalVisible(true));
						}
					})
				)
			}).unsubscribe();
		}
	}

	visibleOrderPopup() {
		if (!this.appService.getIsPreviewValue()) {
			this.store.dispatch(new AddOrderListItem(this.store));
			this.store.dispatch(new ToggleModalVisible(true));
		}
	}

	visibleEmailPopup(): void {
		if (!this.appService.getIsPreviewValue()) {
			this.stateEmailPopup$.next(true);
		}
	}

	closeEmailPopup(): void {
		this.emailForm.setValue('');
		this.emailForm.setErrors(null);
		this.stateEmailPopup$.next(false);
	}

	getAilabilityDay(data: ProductActive): void {
		if (data && data.availability_date) {
			this.availabilityDay$.next(moment(data.availability_date).format('MMMM Do YYYY'));
			return
		} else if (data && data.availability_days) {
			const day = moment().add(data.availability_days, 'days').calendar();
			this.availabilityDay$.next(moment(day, "MM-DD-YYYY").format('MMMM Do YYYY'));
			return
		} else {
			this.availabilityDay$.next('');
			return
		}
	}

	submitEmail(): void {
		if (this.emailForm.valid && this.emailForm.value !== '') {
			this.store.dispatch(new EmailSubscriptionFetch({ productId: this.productSlug, email: this.emailForm.value }));
			let sub = this.actionsSubject.subscribe(z => {
				if (z.type ===  CommonActions.emailSubscriptionSuccess) {
					this.visibleEmailPopup();
					try {
						if (sub) {
							sub.unsubscribe();
						}
					}
					catch(error) {
						console.error("ERROR --> emailSubscriptionSuccess subscribe", error);
					}
				}
			})
		}
		else {
			this.emailForm.markAsTouched();
		}
	}
}
