import { Component, ChangeDetectionStrategy, ViewChild, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getUserIsLogged, getUserIsGuest, UserActions, UserIsGuest } from '@store/user/user.state';
import { BreadcrumbsItem } from '@app/helper/model';
import { Country, getCountryState } from "@store/common/common.state";
import { GuestOrderComponent } from "./guest-order/guest-order.component";
import { AuthorizationOrderComponent } from "./authorization-order/authorization-order.component";
import {
	OrderListOption,
	getActiveOrderListOption,
	getActiveOrderListDataItem,
	geOrderSubtotal, OrderSubtotal,
	OrderActions,
	ActualOrderProduct
} from "@store/order/order.state";


@Component({
	selector: 'order-page',
	templateUrl: './order-page.component.html',
	styleUrls: ['./order-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderPageComponent implements OnInit, OnDestroy {
	@ViewChild(GuestOrderComponent, { static: false }) public guestOrder: GuestOrderComponent;
	@ViewChild(AuthorizationOrderComponent, { static: false }) public authorizationOrder: AuthorizationOrderComponent;

	private pageSubscription: Subscription;
	public isReady$: BehaviorSubject<boolean>;
	public preloader$: BehaviorSubject<boolean>;
	public signUpModal$: BehaviorSubject<boolean>;
	public signInModal$: BehaviorSubject<boolean>;
	public leaveModal$: BehaviorSubject<boolean>;
	public leavePage$: BehaviorSubject<boolean>;
	public breadcrumbsList$: BehaviorSubject<Array<BreadcrumbsItem>>;
	public orderListOption$: Observable<Array<OrderListOption>>;
	public orderSubtotal$: Observable<OrderSubtotal>;
	public userIsLogged$: Observable<boolean>;
	public userIsGuestd$: Observable<boolean>;

	public statusMobileOrderList$: BehaviorSubject<boolean>;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private actionsSubject: ActionsSubject,
		private router: Router,
		private ngZone: NgZone) {

		// init state
		this.pageSubscription = new Subscription();
		this.isReady$ = new BehaviorSubject(false);
		this.statusMobileOrderList$ = new BehaviorSubject(false);
		this.preloader$ = new BehaviorSubject(false);
		this.signUpModal$ = new BehaviorSubject(false);
		this.signInModal$ = new BehaviorSubject(false);
		this.leaveModal$ = new BehaviorSubject(false);
		this.leavePage$ = new BehaviorSubject(null);
		this.breadcrumbsList$ = new BehaviorSubject([]);
		this.orderListOption$ = this.store.select(getActiveOrderListOption);
		this.orderSubtotal$ = this.store.select(getActiveOrderListDataItem).pipe(map(data => {
			return geOrderSubtotal(data);
		}));
		this.userIsLogged$ = this.store.select(getUserIsLogged);
		this.userIsGuestd$ = this.store.select(getUserIsGuest);


		this.pageSubscription.add(
			this.actionsSubject.subscribe(z => {
				if (z.type === OrderActions.confirmGuestOrder || z.type === OrderActions.confirmUserOrder) {
					this.preloader$.next(true);
				}
				else if (z.type === OrderActions.confirmOrderError) {
					this.preloader$.next(false);
				}
				else if (z.type === OrderActions.confirmOrderSuccess) {
					this.preloader$.next(false);
					this.ngZone.run(() => {
						this.router.navigate(['/order-success']);
					});
				}
				else if (z.type === OrderActions.actualOrderProductSuccess || z.type === OrderActions.actualOrderProductError) {
					this.isReady$.next(true);
					this.dataLayerCheckout();
				}

				// modal
				else if (z.type === UserActions.signUpSuccess) {
					this.signUpModal$.next(true);
				}
				else if (z.type === UserActions.signInSuccess) {
					this.signInModal$.next(true);
				}
			})
		);

		this.pageSubscription.add(
			this.orderListOption$.subscribe(val => {
				if (!val || val.length === 0) {
					this.leavePage$.next(true);
					this.router.navigate(['products']);
				}
			})
		)
	}

	get userAuthorization$(): Observable<boolean> {
		return combineLatest(
			this.userIsLogged$,
			this.userIsGuestd$,
			(userIsLogged, userIsGuestd) => {
				return !userIsLogged && !userIsGuestd;
			}
		);
	}

	generateBreadcrumbsList(): void {
		this.breadcrumbsList$.next([
			{
				url: '/',
				label: 'breadcrumbs.marketplace'
			},
			{
				url: '/products',
				label: 'breadcrumbs.products'
			},
			{
				url: '/order',
				label: 'breadcrumbs.order'
			}
		]);
	}

	confirmOrder(): void {
		let userIsLogged: boolean;
		let userIsGuestd: boolean;
		this.userIsLogged$.subscribe(val => {
			userIsLogged = val;
		}).unsubscribe();
		this.userIsGuestd$.subscribe(val => {
			userIsGuestd = val;
		}).unsubscribe();

		this.leavePage$.next(true);
		if (userIsLogged) {
			this.authorizationOrder.orderFormSubmit();
		}
		else if (userIsGuestd) {
			this.guestOrder.orderFormSubmit();
		}
	}

	modalClose(modalKey: string): void {
		switch (modalKey) {
			case 'signUpModal': {
				this.signUpModal$.next(false);
			}; break;
			case 'signInModal': {
				this.signInModal$.next(false);
			}; break;
			case 'leaveModal': {
				this.leaveModal$.next(false);
				this.leavePage$.next(false);
				setTimeout(() => {
					this.leavePage$.next(null);
				}, 100)
			}; break;
		}
	}

	leavePage(): void {
		this.leavePage$.next(true);
	}

	canDeactivate(): Promise<boolean> | boolean {
		if (this.leavePage$.getValue()) {
			return true;
		}

		this.leaveModal$.next(true);
		return new Promise((res, rej) => {
			let sub = this.leavePage$.subscribe(val => {
				if (val === false || val === true) {
					if (sub) {
						sub.unsubscribe();
					}
					res(val);
				}
			})
		});
	}

	ngOnInit(): void {
		this.generateBreadcrumbsList();

		this.store.select(getCountryState).subscribe(val => {
			if (val.data === undefined && !val.requestState.isFetch) {
				this.store.dispatch(new Country());
			}
		}).unsubscribe();

		this.store.select(getActiveOrderListDataItem).subscribe(val => {
			let items: Array<{product_id: number, variation_id: number}> = [];
			if (val) {
				val.products.forEach(z => {
					items.push({
						product_id: z.product_id,
						variation_id: z.variation_id
					})
				});
				this.store.dispatch(new ActualOrderProduct({items: items}));
			}
			else {
				this.isReady$.next(true);
			}
		}).unsubscribe();
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
		this.store.dispatch(new UserIsGuest(false));
	}

	dataLayerCheckout(): void {
		combineLatest(
			this.store.select(getActiveOrderListDataItem),
			this.store.select(getActiveOrderListOption)
		).subscribe(val => {
			let orderListDataItem = val[0];
			let orderListOption: Array<OrderListOption> = val[1];
			if (orderListOption && orderListOption.length) {
				let orderListOptionCompany = orderListOption[0].order_company;
				let orderListOptionProduct = orderListOption[0].order_products;

				let productArray = [];
				orderListDataItem.products.forEach(z => {
					let productOption = orderListOptionProduct.find(x => x.id === z.id);
					productArray.push({
						'name': productOption.name,
						'id': productOption.id,
						'price': z.price,
						'brand': orderListOptionCompany.name,
						'category': productOption.category,
						'quantity': z.quantity
					})
				});

				window['dataLayer'] = window['dataLayer'] || [];
				window['dataLayer'].push({
					'event': 'autoEvent',
					'eventCategory': 'eec',
					'eventAction': 'checkout',
					'ecommerce': {
						'checkout': {
							'actionField': {'step': 1},
							'products': productArray
						}
					}
				});
			}
		}).unsubscribe();
	}

	closeMobileOrderList() {
		this.statusMobileOrderList$.next(false);
	}

	openMobileOrderList() {
		this.statusMobileOrderList$.next(true);
	}
}