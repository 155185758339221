import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class AppService {

	public isPreview$: BehaviorSubject<boolean>;
	public companyPreviewDomain$: BehaviorSubject<string>;
	public isProductFooter$: BehaviorSubject<boolean>;

	constructor() {
		this.isPreview$ = new BehaviorSubject(false);
		this.companyPreviewDomain$ = new BehaviorSubject('');
		this.isProductFooter$ = new BehaviorSubject(false);
	}

	getIsPreview(): Observable<boolean> {
		return this.isPreview$.asObservable();
	}

	getIsProductFooter(): boolean {
		return this.isProductFooter$.getValue();
	}

	getIsPreviewValue(): boolean {
		return this.isPreview$.getValue();
	}

	getCompanyPreviewDomain(): Observable<string> {
		return this.companyPreviewDomain$.asObservable();
	}

	getCompanyPreviewDomainValue(): string {
		return this.companyPreviewDomain$.getValue();
	}

	setIsPreview(val: boolean) {
		this.isPreview$.next(val);
	}

	setCompanyPreviewDomain(val: string) {
		this.companyPreviewDomain$.next(val);
	}
}
