
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// component
import { AppMobileAccordion } from "./mobile-accordion.component";
import { UiKitIconModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		UiKitIconModule,
		BrowserAnimationsModule
	],
	declarations: [
		AppMobileAccordion
	],
	exports: [
		AppMobileAccordion
	]
})

export class AppMobileAccordionModule { }
