import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserService } from '@app/service/user-service.service';
import { AppService } from '@app/service/app-service.service';
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { getOrderModalVisible } from '@store/order/order.state';
import { UserActions, UserCountryFetch } from "@store/user/user.state";
import { ChangeWindowOption, WindowOption } from "@store/common/common.state";
import { UserTranslateService } from '@app/service/user-translate-service.service';
import { Router, NavigationEnd } from '@angular/router';
import { GetOrderListData, FetchOrderActions } from './store/order/fetch-order.state';
import { environment } from '@env/environment';
import 'autotrack/lib/plugins/event-tracker';
import 'autotrack/lib/plugins/outbound-link-tracker';
import 'autotrack/lib/plugins/url-change-tracker';
declare let ga: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('rootAnimation', [
			transition(':enter', [
				style({ opacity: 0.3 }),
				animate('0.3s ease-out', style({ opacity: 1 })),
			])
		])
	]
})

export class AppComponent {

	public orderModalVisible$: Observable<boolean>;
	public isLoad$: BehaviorSubject<boolean>;

	@HostListener('window:resize', ['$event.target'])
	public onResize(windowEvent: Window) {
		let width = windowEvent.innerWidth;
		let windowOption: WindowOption;
		if (width <= 1279 && width >= 768) {
			// Tablet
			windowOption = {
				type: 'tablet',
				width: width
			}
		}
		else if (width < 768) {
			// Mobile
			windowOption = {
				type: 'mobile',
				width: width
			}
		}
		else {
			// Desktop
			windowOption = {
				type: 'desktop',
				width: width
			}
		}
		this.store.dispatch( new ChangeWindowOption(windowOption) );
	}

	constructor(
		public userService: UserService,
		private appService: AppService,
		private router: Router,
		private userTranslateService: UserTranslateService,
		private store: Store<AppStore.AppStoreState>,
		private actionsSubject: ActionsSubject) {

		this.isLoad$ = new BehaviorSubject(false);
		this.orderModalVisible$ = this.store.select(getOrderModalVisible);

		// add subscription
		this.actionsSubject.subscribe(z => {
			if (z.type === UserActions.userDataSuccess || z.type === UserActions.userDataError) {
				this.appLoading();
			}
		})

		if (window.location.hostname === environment.marketplaceUrl) {
			this.appService.setIsPreview(true);
		}

		this.userService.checkUser();
		this.store.dispatch(new UserCountryFetch());
		// this.store.dispatch(new GetOrderListData());

		this.router.events.subscribe( event => {
			if ( event instanceof NavigationEnd ) {

				// google analitics
				if(environment.production) {
					 this.getAutotrackCode(event.urlAfterRedirects);
				}
				// end

				let companyDomain = event.url.split('companyDomain=').pop();
				if(companyDomain) {
					this.appService.setCompanyPreviewDomain(companyDomain);
				}
			}
		});

		this.onResize(window);
	}

	getAutotrackCode(url) {
		ga('create', 'UA-138222633-1', 'auto');
		ga('set', 'page', url);
		// Only require the plugins you've imported above.
		ga('require', 'eventTracker');
		ga('require', 'outboundLinkTracker');
		ga('require', 'urlChangeTracker');
		ga('send', 'pageview');
	  }

	appLoading(): void {
		setTimeout(() => {
			this.isLoad$.next(true);
			let preloader = document.getElementById('appPreloader');
			if (preloader) {
				preloader.parentNode.removeChild(preloader);
			}
		}, 300)
	}
}