import { ProductEffects } from "./product/product.effects";
import { CompanyEffects } from './company/company.effects';
import { CommonEffects } from "./common/common.effects";
import { CheckoutEffects } from './checkout/checkout.effects';
import { UserEffects } from "./user/user.effects";
import { OrderEffects } from "./order/order.effects";
import { FetchOrderEffects } from "./order/fetch-order.effects";


export const AppEffects = [
	ProductEffects,
	CompanyEffects,
	CommonEffects,
	CheckoutEffects,
	UserEffects,
	OrderEffects,
	FetchOrderEffects
];