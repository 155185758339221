import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// custom modules
import { UiKitIconModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";
import { UiKitInputModule } from "@sub/tradalaxy_uikit/src/app/uikit/uikit";
import { SvgInlineModule } from "@app/shared/svg-inline/svg-inline.module";

// component
import { OrderListComponent } from './order-list/order-list.component';
import { OrderListItemComponent } from "./order-list-item/order-list-item.component";

// pipe
import { PipeModule } from "@app/pipe/pipe.module";

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		UiKitIconModule,
		UiKitInputModule,
		SvgInlineModule,
		PipeModule
	],
	declarations: [
		OrderListComponent,
		OrderListItemComponent
	],
	exports: [
		OrderListComponent
	]
})

export class OrderListModule { }