import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from "@angular/core";


@Component({
	selector: 'app-modal-layout',
	templateUrl: './app-modal-layout.component.html',
	styleUrls: ['./app-modal-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppModalLayoutComponent {

	@Input() public showCloseBtn: boolean;
	@Input() public overflowClose: boolean;
	@Output() public closeModal: EventEmitter<void>;

	constructor() {
		// init state
		this.showCloseBtn = true;
		this.overflowClose = true;
		this.closeModal = new EventEmitter();
	}

	closeBtn(): void {
		this.closeModal.emit();
	}

	closeOverflow(): void {
		if (this.overflowClose) {
			this.closeModal.emit();
		}
	}
}