import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class UserTranslateService {
    constructor( private translateService: TranslateService ) {
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');
    }
}