import { Component, ViewChild, Input, ChangeDetectionStrategy, forwardRef, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

interface OptionItem {
	label: string,
	value: any,
	[key: string]: any
}

@Component({
	selector: 'uikit-double-input',
	templateUrl: './uikit-double-input.component.html',
	styleUrls: ['./uikit-double-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UiKitDoubleInputComponent),
    		multi: true
		}
	],
	animations: [
		trigger('openClose', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.1s', style({ opacity: 1 })),
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('0.1s', style({ opacity: 0 })),
			])
		])
	]
})

export class UiKitDoubleInputComponent implements ControlValueAccessor {

	@ViewChild('dropdownSelect', {static: false}) private dropdownSelectElem: ElementRef;
	@ViewChild('dropdownList', {static: false}) private dropdownListElem: ElementRef;

	@Input() public uiClass: string;
	@Input() public uiTitle: string;
	@Input() public uiError: string;
	@Input() public uiTitleVisible: boolean;
	@Input() public uiErrorVisible: boolean;
	@Input() public uiDisabled: boolean;
	@Input() public uiRequired: boolean;
	@Input() public uiTitleHideLabel: boolean;

	// option
	@Input() public options: Array<OptionItem>;
	@Input() public optionLabel: string;

	// state
	public selectedOption: OptionItem;
	public isOpen$: BehaviorSubject<boolean>;

	// controlValueAccessor
	public onChange: Function;
	public onTouched: Function;

	// outside click
	public outsideClick: () => any;

	constructor() {
		// init state
		this.uiClass = '';
		this.uiTitle = '';
		this.uiError = '';
		this.uiTitleVisible = true;
		this.uiErrorVisible = false;
		this.uiDisabled = false;
		this.uiRequired = false;
		this.uiTitleHideLabel = false;

		// options
		this.options = [];
		this.optionLabel = 'label';

		// state
		this.selectedOption = null;
		this.isOpen$ = new BehaviorSubject(false);

		// controlValueAccessor
		this.onChange = () => {};
		this.onTouched = () => {};

		// ouside click
		this.outsideClick = ((event: MouseEvent) => {
			if ( !this.dropdownSelectElem.nativeElement.contains(event.target) && !this.dropdownListElem.nativeElement.contains(event.target) ) {
				this.toggleDropdown(false);
			}
		}).bind(this);
	}

	get selectedItem(): string {
		return (this.selectedOption) ? this.selectedOption[this.optionLabel] : '';
	}

	_outsideClickListener(addListener: boolean): void {
		if (addListener) {
			document.body.addEventListener('click', this.outsideClick);
		}
		else {
			document.body.removeEventListener('click', this.outsideClick);
		}
	}

	toggleDropdown(val?: boolean): void {
		let nextState = (val !== undefined) ? val : !this.isOpen$.getValue();
		this.isOpen$.next(nextState);
		this._outsideClickListener(nextState);
	}

	selectItem(item: OptionItem): void {
		this.writeValue(item.value);
		this.onTouched();
		this.toggleDropdown(false);
	}

	writeValue(value: any): void {
		if (!value) {
			this.selectedOption = null;
			return;
		}
		try {
			let selectedOption = this.options.find(z => {
				if (typeof z.value === 'object' && typeof value === 'object') {
					let sameKey = true;
					sameKeyCycle: for (let key in z.value) {
						if (sameKey) {
							sameKey = z.value[key] === value[key];
						}
						else {
							break sameKeyCycle;
						}
					}
					return sameKey;
				}
				else {
					return z.value === value;
				}
			});
			if (selectedOption) {
				this.selectedOption = selectedOption;
				this.onChange(selectedOption.value);
			}
		}
		catch(error) {
			console.warn('Warning --> UiInputDropdownComponent writeValue', error);
			return;
		}
	}

	registerOnChange(cb: Function): void {
		this.onChange = cb;
	}

	registerOnTouched(cb: Function): void {
		this.onTouched = cb;
	}
}
