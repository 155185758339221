import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { SelectItem } from "@app/helper/model";
import { Subscription, BehaviorSubject } from 'rxjs';
import { Store, ActionsSubject } from '@ngrx/store';
import * as AppStore from '@store/app.store';
import { SendSupplierFormFetch, SendSupplierFormData, CommonActions } from '@app/store/common/common.state';
import { commonValidator, supplierValidator } from "@app/helper/validator";
import { getUserCountry } from "@store/user/user.state";


@Component({
    selector: 'supplier-form',
    templateUrl: './supplier-form.component.html',
    styleUrls: ['./supplier-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SupplierForm implements OnInit, OnDestroy {

	// props
	@Input() public companyTitle: string;
	@Input() public adressOption: Array<SelectItem<{country_id: number}>>;
	@Input() public companyId: number;

	// state
	private pageSubscription: Subscription;
	public isLoad$: BehaviorSubject<boolean>;
	public isSubmit$: BehaviorSubject<boolean>;
	public intlTelObject: any;
	public initVisibleAddress: boolean;
	public supplierForm: FormGroup;

	constructor(
		private store: Store<AppStore.AppStoreState>,
		private actionsSubject: ActionsSubject) {
		// init state
		this.pageSubscription = new Subscription();
		this.isLoad$ = new BehaviorSubject(false);
		this.isSubmit$ = new BehaviorSubject(false);
		this.companyTitle = '';
		this.adressOption = [];
		this.initVisibleAddress = false;

		this.initSupplierForm();
	}

	get supplierFormControl(): {[key: string]: AbstractControl} {
		return this.supplierForm.controls;
	}

	get adressOptionVisible(): boolean {
		return this.initVisibleAddress || (this.adressOption && this.adressOption.length > 1);
	}

	closeSupplierModal(): void {
		this.isSubmit$.next(false);
	}

	initSupplierForm(): void {
		this.supplierForm = new FormGroup({
			first_name: new FormControl('', supplierValidator.name),
			last_name: new FormControl('', supplierValidator.name),
			company_name: new FormControl('', supplierValidator.companyName),
			email: new FormControl('', commonValidator.email),
			phone: new FormControl('', Validators.required),
			country: new FormControl(null, Validators.required),
			comment: new FormControl('', supplierValidator.comment)
		});

		this.pageSubscription.add(
			this.supplierFormControl.phone.valueChanges.subscribe(val => {
				if (!val.length) {
					this.supplierFormControl.phone.setErrors({ errorName: 'validations.telephone_required' });
				}
				else {
					this.validPhone();
				}
			})
		);
	}

	setIntlTelObject(data: any): void {
		this.intlTelObject = data;
	}

	setIntlCountryChange(): void {
		this.validPhone();
	}

	validPhone(): void {
		if (this.intlTelObject) {
			let isValid = this.intlTelObject.isValidNumber();
			if (!isValid) {
				this.supplierFormControl.phone.setErrors({ errorName: 'validations.telephone_error' });
			}
			else {
				this.supplierFormControl.phone.setErrors(null);
			}
		}
	}

	submit(): void {
		this.supplierForm.markAllAsTouched();
		if (this.supplierForm.valid) {

			this.isLoad$.next(true);

			let sendSupplierFormData: SendSupplierFormData = {
				first_name: this.supplierFormControl.first_name.value,
				last_name: this.supplierFormControl.last_name.value,
				company_name: this.supplierFormControl.company_name.value,
				email: this.supplierFormControl.email.value,
				phone: this.supplierFormControl.phone.value,
				country_id: this.supplierFormControl.country.value.country_id,
				text: this.supplierFormControl.comment.value
			}
			this.store.dispatch(new SendSupplierFormFetch({companyId: this.companyId, data: sendSupplierFormData}));

			let sub = this.actionsSubject.subscribe(z => {
				let unsub = () => {
					try {
						if (sub) {
							sub.unsubscribe();
						}
					}
					catch(error) {
						console.error("ERROR --> sendSupplierForm subscribe", error);
					}
				}

				if (z.type === CommonActions.sendSupplierFormError) {
					this.isLoad$.next(false);
					unsub();
				}
				else if (z.type ===  CommonActions.sendSupplierFormSuccess) {
					this.isSubmit$.next(true);
					this.isLoad$.next(false);
					unsub();
				}
			});
		}
	}

	ngOnInit(): void {
		if (this.adressOption && this.adressOption.length) {
			this.supplierFormControl.country.setValue(this.adressOption[0].value);
		}
		else {
			this.initVisibleAddress = true;
		}

		setTimeout(() => {
			this.store.select(getUserCountry).subscribe(userCountry => {
				if (this.intlTelObject && userCountry) {
					if (userCountry.code) {
						this.intlTelObject.setCountry(userCountry.code);

						let {dialCode} = this.intlTelObject.getSelectedCountryData();
						this.supplierFormControl.phone.setValue(`+${dialCode}`);
					}
				}
			}).unsubscribe();
		}, 300)
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}
}